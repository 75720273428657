import { ConnectedPosition } from '@angular/cdk/overlay'

export type BuiOverlayPosition =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'right'
export type BuiOverlayPositionX = 'start' | 'end' | 'center'
export type BuiOverlayPositionY = 'top' | 'bottom' | 'center'

export function setConnectedPosition(
  position: BuiOverlayPosition
): ConnectedPosition {
  const setPosition = {
    originX: setOriginX(position),
    originY: setOriginY(position),
    overlayX: setOverlayX(position),
    overlayY: setOverlayY(position),
    offsetX: setOffsetX(position),
    offsetY: setOffsetY(position),
  }
  return setPosition
}

function setOriginX(position: BuiOverlayPosition): BuiOverlayPositionX {
  switch (position) {
    case 'left':
    case 'top-left':
    case 'bottom-left':
      return 'start'

    case 'right':
    case 'top-right':
    case 'bottom-right':
      return 'end'

    default:
      return 'center'
  }
}

function setOriginY(position: BuiOverlayPosition): BuiOverlayPositionY {
  switch (position) {
    case 'top':
    case 'top-right':
    case 'top-left':
      return 'top'

    case 'bottom':
    case 'bottom-right':
    case 'bottom-left':
      return 'bottom'

    default:
      return 'center'
  }
}

function setOverlayX(position: BuiOverlayPosition): BuiOverlayPositionX {
  switch (position) {
    case 'left':
    case 'top-left':
    case 'bottom-left':
      return 'end'

    case 'right':
    case 'top-right':
    case 'bottom-right':
      return 'start'

    default:
      return 'center'
  }
}

function setOverlayY(position: BuiOverlayPosition): BuiOverlayPositionY {
  switch (position) {
    case 'top':
    case 'top-right':
    case 'top-left':
      return 'bottom'

    case 'bottom':
    case 'bottom-right':
    case 'bottom-left':
      return 'top'

    default:
      return 'center'
  }
}

function setOffsetX(position: BuiOverlayPosition): number {
  switch (position) {
    case 'left':
      return -10
    case 'top-left':
    case 'bottom-left':
      return 50

    case 'right':
      return 10

    case 'top-right':
    case 'bottom-right':
      return -50

    default:
      return 0
  }
}

function setOffsetY(position: BuiOverlayPosition): number {
  switch (position) {
    case 'top':
    case 'top-right':
    case 'top-left':
      return -10

    case 'bottom':
    case 'bottom-right':
    case 'bottom-left':
      return 10

    default:
      return 0
  }
}
