import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { A11yModule } from '@angular/cdk/a11y'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { _BuiModalContainerComponent } from './bui-modal-container/bui-modal-container.component'
import {
  _BuiModalActionsDirective,
  _BuiModalContentDirective,
  _BuiModalHeaderDirective,
  _BuiModalLayoutDirective,
} from './bui-modal-directives'

@NgModule({
  declarations: [
    _BuiModalContainerComponent,
    _BuiModalLayoutDirective,
    _BuiModalHeaderDirective,
    _BuiModalContentDirective,
    _BuiModalActionsDirective,
  ],
  imports: [CommonModule, A11yModule, OverlayModule, PortalModule],
  exports: [
    _BuiModalContainerComponent,
    _BuiModalLayoutDirective,
    _BuiModalHeaderDirective,
    _BuiModalContentDirective,
    _BuiModalActionsDirective,
  ],
})
export class BuiModalModule {}
