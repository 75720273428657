import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class BuiQueryParamsService {
  private queryParamsSubject$ = new BehaviorSubject<Params>({})
  queryParams$ = this.queryParamsSubject$.asObservable()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.queryParamsSubject$.next({
        ...queryParams,
        ...this.queryParamsSubject$.value,
      })
    })
  }

  get(queryParamName: string): string {
    return this.queryParamsSubject$.value[queryParamName] || null
  }

  add(queryParams: Params): void {
    this._set({ ...this.queryParamsSubject$.value, ...queryParams })
  }

  remove(queryParamName: string): void {
    const queryParams: Params =
      this.get(queryParamName) === null
        ? { ...this.queryParamsSubject$.value }
        : { ...this.queryParamsSubject$.value, [queryParamName]: null }

    this._set(queryParams)
  }

  private _set(queryParams: Params): void {
    this.queryParamsSubject$.next(queryParams)

    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams,
    })

    // IMPORTANT: This does not update ActivatedRoute.queryParams
    // & ActivatedRoute.queryParamMap observables
    this.location.replaceState(urlTree.toString())
  }
}
