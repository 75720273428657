import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core'
import { BuiSize } from '../bui-size'

export const BuiChipVariant = ['pill', 'status'] as const
export type BuiChipVariant = (typeof BuiChipVariant)[number]

export const BuiChipColor = [
  'green',
  'orange',
  'red',
  'light',
  'black',
  'purple',
] as const
export type BuiChipColor = (typeof BuiChipColor)[number]
export type BuiChipSize = Exclude<BuiSize, 'small'>

const BASE_CSS_CLASS = 'bui-chip'

@Component({
  selector: 'bui-chip',
  templateUrl: './bui-chip.component.html',
  styleUrls: ['./bui-chip.component.scss'],
})
export class _BuiChipComponent {
  @Input() variant: BuiChipVariant = 'pill'
  @Input() color: BuiChipColor
  @Input() size: BuiChipSize = 'regular'

  @Input({ transform: booleanAttribute }) removable = false
  @Input() removeButtonTooltip: string

  @Output() remove = new EventEmitter<void>()

  @HostBinding('class') get classes() {
    let classes = BASE_CSS_CLASS
    classes += ` ${BASE_CSS_CLASS}--variant-${this.variant}`
    classes += ` ${BASE_CSS_CLASS}--size-${this.size}`

    if (this.color) {
      classes += ` ${BASE_CSS_CLASS}--color-${this.color}`
    }

    if (this.removable) {
      classes += ` ${BASE_CSS_CLASS}--removable`
    }
    return classes
  }

  onRemoveClick(): void {
    this.remove.emit()
  }
}
