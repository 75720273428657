import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core'

const BASE_CSS_CLASS = 'bui-dropdown-item'

@Component({
  selector: 'bui-dropdown-item',
  templateUrl: 'bui-dropdown-item.component.html',
  styleUrls: ['bui-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDropdownItemComponent {
  @Input() addPadding = true

  @Output() readonly itemClicked = new EventEmitter<void>()

  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.addPadding) {
      classes.push(`${BASE_CSS_CLASS}--has-padding`)
    }

    return classes
  }

  @HostListener('click') private onItemClick() {
    this.itemClicked.emit()
  }
}
