export const BuiDatepickerType = ['range', 'single-date', 'date-time'] as const
export type BuiDatepickerType = (typeof BuiDatepickerType)[number]

export interface _BuiDatepickerDay {
  date: Date
  dayOfMonth: number
}

export interface _BuiDatepickerWeek {
  sunday: _BuiDatepickerDay
  monday: _BuiDatepickerDay
  tuesday: _BuiDatepickerDay
  wednesday: _BuiDatepickerDay
  thursday: _BuiDatepickerDay
  friday: _BuiDatepickerDay
  saturday: _BuiDatepickerDay
}

export const _BuiDatepickerDaysMap: (keyof _BuiDatepickerWeek)[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export const _BuiDatepickerMonthPanelType = [
  'panelStart',
  'panelEnd',
  'panelSingle',
] as const
export type _BuiDatepickerMonthPanelType =
  (typeof _BuiDatepickerMonthPanelType)[number]

export interface BuiDatepickerRange {
  from: string | null
  to: string | null
}

export class _BuiDatepickerRangeActiveSelection {
  fromDate: Date
  toDate: Date

  constructor(fromDate: Date, toDate: Date) {
    this.fromDate = fromDate
    this.toDate = toDate
  }
}
