<div
  class="bui-datepicker-input__trigger"
  #origin="cdkOverlayOrigin"
  cdkOverlayOrigin
  (click)="onToggle($event)"
>
  <div class="bui-datepicker-input__value">
    {{ label }}
  </div>
  <span
    *ngIf="allowClear && !isEmpty && !disabled"
    class="bui-datepicker-input__reset-icon"
    (click)="onClear($event)"
  >
    <bui-icon name="close-fill"></bui-icon>
  </span>
  <span
    *ngIf="isEmpty || !allowClear"
    class="bui-datepicker-input__icon"
    (click)="onToggle($event)"
  >
    <bui-icon [name]="iconName"></bui-icon>
  </span>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isPanelOpen"
  [cdkConnectedOverlayPositions]="getPositions()"
  (backdropClick)="onApply()"
  (detach)="onCancel()"
  (overlayKeydown)="onOverlayKeydown($event)"
>
  <div class="bui-datepicker-input__panel-wrap" [@transformPanelWrap]>
    <div class="bui-datepicker-input__panel" [@transformPanel]>
      <div class="bui-datepicker-input__body">
        <bui-datepicker-month
          *ngFor="let panelType of panelsToRender"
          [panelType]="panelType"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></bui-datepicker-month>
        <bui-datepicker-time
          *ngIf="datepickerType === 'date-time'"
          [disabled]="isEmpty"
        ></bui-datepicker-time>
      </div>
      <div *ngIf="showFooterButtons" class="bui-datepicker-input__footer">
        <bui-button
          size="small"
          variant="text-brand"
          (click)="onCancel($event)"
        >
          Cancel
        </bui-button>
        <bui-button variant="primary" size="small" (click)="onApply($event)">
          Apply
        </bui-button>
      </div>
    </div>
  </div>
</ng-template>
