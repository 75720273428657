import {
  Component,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterViewInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core'
import { buiDropdownAnimations } from './bui-dropdown.animations'
import { _BuiDropdownItemComponent } from './bui-dropdown-item/bui-dropdown-item.component'
import { takeUntil } from 'rxjs'
import { injectDestroy } from '../util'

@Component({
  selector: 'bui-dropdown',
  templateUrl: 'bui-dropdown.component.html',
  styleUrls: ['bui-dropdown.component.scss'],
  animations: [buiDropdownAnimations.transformPanel],
  providers: [{ provide: 'BUI_DROPDOWN', useExisting: _BuiDropdownComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDropdownComponent implements AfterViewInit {
  private destroy$ = injectDestroy()

  @ViewChild(TemplateRef) templateRef: TemplateRef<unknown>

  @ContentChildren(_BuiDropdownItemComponent)
  private _children: QueryList<_BuiDropdownItemComponent>

  @Input() closeOnItemClick = true

  @Input() panelClass = ''

  @Output() readonly closed = new EventEmitter<void>()

  ngAfterViewInit(): void {
    this._children?.forEach((child) =>
      child.itemClicked.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.closeOnItemClick) {
          this.closed.emit()
        }
      })
    )
  }
}
