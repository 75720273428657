import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'
import { BuiBreadcrumbHistory } from './bui-breadcrumbs.models'

const BASE_CSS_CLASS = 'bui-breadcrumbs'

@Component({
  selector: 'bui-breadcrumbs',
  templateUrl: './bui-breadcrumbs.component.html',
  styleUrls: ['./bui-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiBreadcrumbsComponent {
  @Input() history: BuiBreadcrumbHistory[] | null

  @HostBinding('class') get classes(): string[] {
    return [BASE_CSS_CLASS]
  }
}
