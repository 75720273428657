import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiIconComponent } from './bui-icon.component'

@NgModule({
  declarations: [_BuiIconComponent],
  imports: [CommonModule],
  exports: [_BuiIconComponent],
})
export class BuiIconModule {}
