import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { OverlayModule } from '@angular/cdk/overlay'
import { BuiButtonModule } from '../bui-button'
import { _BuiToastComponent } from './bui-toast/bui-toast.component'
import {
  defaultToastConfig,
  BUI_TOAST_CONFIG_TOKEN,
  BuiToastConfig,
} from './bui-toast.models'
import { _BuiToastContainerComponent } from './bui-toast-container/bui-toast-container.component'
import { _BuiToastInternalService } from './bui-toast-internal.service'

@NgModule({
  declarations: [_BuiToastContainerComponent, _BuiToastComponent],
  imports: [CommonModule, OverlayModule, BuiButtonModule],
  exports: [_BuiToastContainerComponent, _BuiToastComponent],
})
export class BuiToastModule {
  public static forRoot(
    globalConfig?: BuiToastConfig
  ): ModuleWithProviders<BuiToastModule> {
    return {
      ngModule: BuiToastModule,
      providers: [
        _BuiToastInternalService,
        {
          provide: BUI_TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...globalConfig },
        },
      ],
    }
  }

  constructor(@Optional() @SkipSelf() parentModule?: BuiToastModule) {
    if (parentModule) {
      throw new Error(
        'BuiToastModule is already loaded. Import it in the AppModule only'
      )
    }
  }
}
