import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  InjectionToken,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core'
import { merge, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BuiAccordionItemComponent } from './bui-accordion-item/bui-accordion-item.component'

export const BUI_ACCORDION = new InjectionToken<BuiAccordionComponent>(
  'BUI_ACCORDION'
)

@Component({
  selector: 'bui-accordion, [buiAccordion]',
  template: `
    @defer {
      <div class="bui-accordion">
        <ng-content></ng-content>
      </div>
    }
  `,
  exportAs: 'buiAccordion',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiAccordionComponent implements AfterViewInit, OnDestroy {
  public stateChanges: Subject<void> = new Subject()

  private readonly destroy$ = new Subject<void>()

  @Input() multi = false

  @Input() firstOpened = false

  @ContentChildren(BuiAccordionItemComponent)
  private _children: QueryList<BuiAccordionItemComponent>

  public get active() {
    return this._active
  }
  public set active(v: string[]) {
    this._active = v
  }
  private _active: string[] = []

  ngAfterViewInit(): void {
    if (this.firstOpened) {
      this.active = [this._children.first.id]
      this._propagateChange()
    }

    merge(...this._children.map((c) => c.openedChange))
      .pipe(takeUntil(this.destroy$))
      .subscribe((_id) => {
        if (this.active.includes(_id)) {
          this.active = this.active.filter((id) => id !== _id)
        } else {
          if (this.multi) {
            this.active = [_id]
          } else {
            this.active.push(_id)
          }
        }
        this._propagateChange()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private _propagateChange(): void {
    this._children.forEach((c) => {
      if (this.active.includes(c.id)) {
        c.animationState = 'expanded'
      } else {
        c.animationState = 'collapsed'
      }
    })
  }
}
