import { HostBinding, Directive, Input } from '@angular/core'
import { BuiSize } from '../bui-size'

// Use <bui-modal-layout /> to wrap modal's header/content/footer
@Directive({
  selector: 'bui-modal-layout',
})
export class _BuiModalLayoutDirective {
  @Input() size: BuiSize = 'regular'
  @Input() textAlign: 'center' | 'side' = 'center'

  @HostBinding('class') get classes(): string[] {
    const classes = ['bui-modal-layout']
    classes.push(`bui-modal-layout--size-${this.size}`)
    classes.push(`bui-modal-layout--align-${this.textAlign}`)
    return classes
  }
}

@Directive({
  selector: 'bui-modal-header',
})
export class _BuiModalHeaderDirective {
  @HostBinding('class.bui-modal-header') private headerClass = true
}

@Directive({
  selector: 'bui-modal-content',
})
export class _BuiModalContentDirective {
  @HostBinding('class.bui-modal-content') private contentClass = true
}

@Directive({
  selector: 'bui-modal-actions',
})
export class _BuiModalActionsDirective {
  @HostBinding('class.bui-modal-actions') private actionsClass = true
}
