import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiInfoItemComponent } from './bui-info-item.component'

@NgModule({
  declarations: [_BuiInfoItemComponent],
  imports: [CommonModule],
  exports: [_BuiInfoItemComponent],
})
export class BuiInfoItemModule {}
