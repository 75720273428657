import { BuiSize } from '../bui-size'

export class BuiAsideConfig<D = any> {
  disableBackdropClose?: boolean = false
  disableEscKeyClose?: boolean = false
  disableRouterNavigationClose?: boolean = false
  hasBackdrop?: boolean = true
  size?: BuiSize = 'regular'
  data?: D | null = null
}

export class _BuiAsideConfigInternal extends BuiAsideConfig {
  level: BuiAsideLevel
  backdropClass?: string = ''
}

export enum BuiAsideLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
}
