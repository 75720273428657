import { OverlayRef, GlobalPositionStrategy } from '@angular/cdk/overlay'
import { hasModifierKey } from '@angular/cdk/keycodes'
import { Subject } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { _BuiModalContainerComponent } from './bui-modal-container/bui-modal-container.component'
import { BuiModalPosition } from './bui-modal-config'

export enum BuiModalState {
  OPEN,
  CLOSING,
  CLOSED,
}

export class BuiModalRef<T, R = any> {
  public componentInstance: T
  public disableClose: boolean | undefined

  private readonly _afterClosed = new Subject<R | undefined>()
  private readonly _afterOpened = new Subject<void>()

  public afterOpened$ = this._afterOpened.asObservable()
  public afterClosed$ = this._afterClosed.asObservable()

  private result: R | undefined
  private closeFallbackTimeout: number

  private _modalState: BuiModalState = BuiModalState.OPEN
  public get modalState() {
    return this._modalState
  }

  constructor(
    private overlayRef: OverlayRef,
    public containerInstance: _BuiModalContainerComponent
  ) {
    this.disableClose = this.containerInstance.config.disableClose

    // Emit when opening animation completes
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'enter'
        ),
        take(1)
      )
      .subscribe(() => {
        this._afterOpened.next()
        this._afterOpened.complete()
      })

    // Dispose overlay when closing animation is complete
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'exit'
        ),
        take(1)
      )
      .subscribe(() => {
        clearTimeout(this.closeFallbackTimeout)
        this.overlayRef.dispose()
      })

    overlayRef.detachments().subscribe(() => {
      this._afterClosed.next(this.result)
      this._afterClosed.complete()
      this.componentInstance = null
      this.overlayRef.dispose()
    })

    overlayRef
      .keydownEvents()
      .pipe(
        filter(
          (event) =>
            event.key === 'Escape' &&
            !this.disableClose &&
            !hasModifierKey(event)
        )
      )
      .subscribe((event) => {
        event.preventDefault()
        this.close()
      })
  }

  public close(data?: R): void {
    this.result = data

    this.containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start'),
        take(1)
      )
      .subscribe((event) => {
        this._modalState = BuiModalState.CLOSED
        this.overlayRef.detachBackdrop()

        this.closeFallbackTimeout = window.setTimeout(() => {
          this.overlayRef.dispose()
        }, event.totalTime + 100)
      })

    this.containerInstance.startExitAnimation()
    this._modalState = BuiModalState.CLOSING
  }

  public updatePosition(position?: BuiModalPosition): this {
    const strategy = this.getPositionStrategy()

    if (position && (position.left || position.right)) {
      position.left
        ? strategy.left(position.left)
        : strategy.right(position.right)
    } else {
      strategy.centerHorizontally()
    }

    if (position && (position.top || position.bottom)) {
      position.top
        ? strategy.top(position.top)
        : strategy.bottom(position.bottom)
    } else {
      strategy.centerVertically()
    }

    this.overlayRef.updatePosition()

    return this
  }

  private getPositionStrategy(): GlobalPositionStrategy {
    return this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy
  }
}
