import { ConnectedPosition } from '@angular/cdk/overlay'

export type BuiPanelPosition = 'start' | 'center' | 'end'

export function getPanelPositions(
  panelPosition: BuiPanelPosition
): ConnectedPosition[] {
  switch (panelPosition) {
    case 'end':
      return [
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]

    case 'center':
      return [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
      ]

    case 'start':
    default:
      return [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]
  }
}
