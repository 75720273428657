import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  InjectionToken,
  Input,
} from '@angular/core'
import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'

const BUI_ACCORDION_ITEM = new InjectionToken('BUI_ACCORDION_ITEM')
const BUI_ACCORDION_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)'

export const buiAccordionAnimations: {
  readonly bodyExpansion: AnimationTriggerMetadata
  readonly paddingExpansion: AnimationTriggerMetadata
} = {
  /** Padding can't be animated so we use divs and animate their height. Otherwise animation is glitchy */
  paddingExpansion: trigger('paddingExpansion', [
    state('collapsed, void', style({ height: '0px' })),
    state('expanded', style({ height: '16px' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate(BUI_ACCORDION_ANIMATION_TIMING)
    ),
  ]),
  /** Animation that expands and collapses the panel content. */
  bodyExpansion: trigger('bodyExpansion', [
    state('collapsed, void', style({ opacity: 0, height: '0px' })),
    state('expanded', style({ opacity: 1, height: '*' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate(BUI_ACCORDION_ANIMATION_TIMING)
    ),
  ]),
}

export type AccordionState = 'expanded' | 'collapsed'
@Component({
  selector: 'bui-accordion-item, [buiAccordionItem]',
  templateUrl: 'bui-accordion-item.component.html',
  exportAs: 'buiAccordion',
  styleUrls: ['bui-accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    buiAccordionAnimations.paddingExpansion,
    buiAccordionAnimations.bodyExpansion,
  ],
  providers: [
    { provide: BUI_ACCORDION_ITEM, useExisting: BuiAccordionItemComponent },
  ],
})
export class BuiAccordionItemComponent {
  @Input() public id: string

  public readonly openedChange: EventEmitter<string> = new EventEmitter()

  public set animationState(v: AccordionState) {
    this._animationState = v
    this._changeDetectorRef.markForCheck()
  }
  public get animationState(): AccordionState {
    return this._animationState
  }
  private _animationState: AccordionState = 'collapsed'

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  public emitChange(): void {
    this.openedChange.emit(this.id)
  }
}
