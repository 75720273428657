import {
  animate,
  AnimationTriggerMetadata,
  query,
  state,
  style,
  transition,
  trigger,
  group,
} from '@angular/animations'

export const buiDropdownAnimations: {
  readonly transformPanel: AnimationTriggerMetadata
} = {
  transformPanel: trigger('transformPanel', [
    state('void', style({ opacity: 0 })),
    transition(
      'void => enter',
      group([
        query(
          '.bui-dropdown-content',
          animate('100ms linear', style({ opacity: 1 }))
        ),
        animate('120ms cubic-bezier(0, 0, 0.2, 1)'),
      ])
    ),
    transition(
      '* => void',
      animate('100ms 25ms linear', style({ opacity: 0 }))
    ),
  ]),
}
