import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CdkTableModule } from '@angular/cdk/table'
import { CdkScrollableModule } from '@angular/cdk/scrolling'
import { ObserversModule } from '@angular/cdk/observers'
import { _BuiTableFooterComponent } from './bui-table-footer/bui-table-footer.component'
import { _BuiTableSortDirective } from './bui-table-sort/bui-table-sort.directive'
import { _BuiTableSortControlComponent } from './bui-table-sort/bui-table-sort-control.component'
import {
  _BuiTableDirective,
  _BuiTableResponsiveLabelDirective,
} from './bui-table.directive'
import { BuiStickyTableComponent } from './bui-sticky-table/bui-sticky-table.component'

@NgModule({
  declarations: [
    _BuiTableSortControlComponent,
    _BuiTableSortDirective,
    _BuiTableDirective,
    _BuiTableResponsiveLabelDirective,
    _BuiTableFooterComponent,
    BuiStickyTableComponent,
  ],
  imports: [CommonModule, CdkTableModule, CdkScrollableModule, ObserversModule],
  exports: [
    CdkTableModule,
    _BuiTableSortControlComponent,
    _BuiTableSortDirective,
    _BuiTableDirective,
    _BuiTableResponsiveLabelDirective,
    _BuiTableFooterComponent,
    BuiStickyTableComponent,
  ],
})
export class BuiTableModule {}
