import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { BuiIconType, icons } from './icons'

export const BUI_ICON_KEYS = Object.keys(icons) as BuiIconType[]

@Component({
  selector: 'bui-icon',
  templateUrl: './bui-icon.component.html',
  styleUrls: ['./bui-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiIconComponent implements OnChanges {
  @Input() name: BuiIconType

  @HostBinding('class') get classes() {
    return `bui-icon bui-icon--${this.name}`
  }

  public svgIcon: SafeHtml

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    if (icons[this.name]) {
      this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(icons[this.name])
    } else {
      console.warn(`Icon '${this.name}' does not exist in BuiIconComponent`)
    }
  }
}
