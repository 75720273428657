import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiBreadcrumbsComponent } from './bui-breadcrumbs.component'
import { RouterModule } from '@angular/router'
import { BuiIconModule } from '../bui-icon/bui-icon.module'

@NgModule({
  declarations: [_BuiBreadcrumbsComponent],
  imports: [CommonModule, RouterModule, BuiIconModule],
  exports: [_BuiBreadcrumbsComponent],
})
export class BuiBreadcrumbsModule {}
