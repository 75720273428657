import { NgModule } from '@angular/core'
import { _BuiTypeaheadComponent } from './bui-typeahead.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { _BuiTypeaheadOptionComponent } from './bui-typeahead-option.component'
import { BUI_SELECT_SCROLL_STRATEGY_PROVIDER } from './bui-typeahead.tokens'
import { BuiInputModule } from '../bui-input'

@NgModule({
  declarations: [_BuiTypeaheadComponent, _BuiTypeaheadOptionComponent],
  imports: [CommonModule, ReactiveFormsModule, OverlayModule, BuiInputModule],
  providers: [BUI_SELECT_SCROLL_STRATEGY_PROVIDER],
  exports: [_BuiTypeaheadComponent, _BuiTypeaheadOptionComponent],
})
export class BuiTypeaheadModule {}
