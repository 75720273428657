import {
  Attribute,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self,
  booleanAttribute,
  inject,
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { hasModifierKey } from '@angular/cdk/keycodes'
import { ConnectedPosition } from '@angular/cdk/overlay'
import { BuiPanelPosition, getPanelPositions } from '../bui-panel-position'
import { _BuiDatepickerInternalService } from './bui-datepicker-internal.service'
import {
  BuiDatepickerType,
  _BuiDatepickerMonthPanelType,
} from './bui-datepicker.models'
import { takeUntil } from 'rxjs'
import { injectDestroy } from '../util'
import { BuiIconType } from '../bui-icon'
import { BuiSize } from '../bui-size'

const BASE_CSS_CLASS = 'bui-datepicker-input'

@Directive()
export abstract class _BuiDatepickerInputBase<T>
  implements ControlValueAccessor, OnDestroy
{
  protected readonly destroy$ = injectDestroy()
  readonly internalService = inject(_BuiDatepickerInternalService)
  private readonly hostElem: ElementRef<HTMLDivElement> = inject(ElementRef)
  private readonly changeDetectorRef = inject(ChangeDetectorRef)

  // Date or a string in this.dateFormat
  @Input() protected abstract minDate: Date | string
  @Input() protected abstract maxDate: Date | string

  @Input() size: BuiSize = 'regular'

  @Input() set dateFormat(value: string) {
    this.internalService.dateFormat = value
  }
  get dateFormat(): string {
    return this.internalService.dateFormat
  }

  @Input() set displayDateFormat(value: string) {
    this.internalService.dateDisplayFormat = value
  }
  get displayDateFormat(): string {
    return this.internalService.dateDisplayFormat
  }

  @Input() set disabledDates(value: string[]) {
    this.internalService.disabledDates = value
  }
  get disabledDates(): string[] {
    return this.internalService.disabledDates
  }

  @Input({ transform: booleanAttribute }) disabled = false
  @Input({ transform: booleanAttribute }) allowClear = false
  @Input({ transform: booleanAttribute }) showFooterButtons = true
  @Input({ transform: booleanAttribute }) muteStyles = false

  @Input() get tabIndex(): number {
    return this.disabled ? -1 : this._tabIndex
  }
  set tabIndex(value: number) {
    this._tabIndex = value ?? 0
  }
  private _tabIndex: number

  @Input() panelPosition: BuiPanelPosition = 'start'
  @Input() placeholder = 'Select a date'

  get panelsToRender(): _BuiDatepickerMonthPanelType[] {
    return this.dualPanels ? ['panelStart', 'panelEnd'] : ['panelSingle']
  }

  isPanelOpen = false

  dualPanels = false

  iconName: BuiIconType = 'calendar'

  get focused(): boolean {
    return this._focused || this.isPanelOpen
  }
  private _focused = false

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    classes.push(`${BASE_CSS_CLASS}--${this.size}`)

    if (this.isPanelOpen) {
      classes.push(`${BASE_CSS_CLASS}--active`)
    }
    if (this.disabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }
    if (this.muteStyles) {
      classes.push(`${BASE_CSS_CLASS}--mute-styles`)
    }

    return classes
  }

  @HostBinding('attr.tabIndex') get attrTabIndex() {
    return this.tabIndex
  }

  @HostListener('blur') onBlur() {
    this._onBlur()
  }

  @HostListener('focus') onFocus() {
    this._onFocus()
  }

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent): void {
    this._handleKeydown(event)
  }

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    @Attribute('tabIndex') tabIndex: string
  ) {
    if (!this.ngControl) {
      throw new Error(`
        BUI Datepicker: You cannot use this component without reactive
        or template driven forms. e.g. [formControl]="control", [(ngModel)]="value"
      `)
    }
    this.ngControl.valueAccessor = this
    this.tabIndex = parseInt(tabIndex) || 0

    this.setActiveDateSelection()

    this.internalService.datepickerType = this.getDatepickerType()
    this.internalService.dayClicked$
      .pipe(takeUntil(this.destroy$))
      .subscribe((date) => this.onDayClicked(date))

    this.internalService.timeChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((date) => this.onTimeChanged(date))
  }

  get datepickerType(): BuiDatepickerType {
    return this.getDatepickerType()
  }

  protected abstract get label(): string
  protected abstract get isEmpty(): boolean

  protected abstract getDatepickerType(): BuiDatepickerType
  protected abstract setActiveDateSelection(): void
  protected abstract onDayClicked(value: Date): void
  protected abstract updateValue(value: T): void
  protected abstract getValueToApply(): T
  protected abstract getEmptyValue(): T

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onTimeChanged(value: Date): void {}

  // View -> model callback called when value changes
  _onChange: (_: unknown) => void

  // View -> model callback called when select has been touched
  _onTouched: () => void

  ngOnDestroy(): void {
    this.internalService.destroy()
  }

  // Sets the datepicker's value. Part of the ControlValueAccessor interface
  // required to integrate with Angular's core forms API
  writeValue(value: T): void {
    this.updateValue(value)
    this.changeDetectorRef.markForCheck()
  }

  // Saves a callback function to be invoked when the select's value
  // changes from user input. Part of the ControlValueAccessor interface
  // required to integrate with Angular's core forms API.
  registerOnChange(fn: () => void): void {
    this._onChange = fn
  }

  // Saves a callback function to be invoked when the select is blurred
  // by the user. Part of the ControlValueAccessor interface required
  // to integrate with Angular's core forms API
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn
  }

  // Disables the select. Part of the ControlValueAccessor interface
  // required to integrate with Angular's core forms API.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  getPositions(): ConnectedPosition[] {
    return getPanelPositions(this.panelPosition)
  }

  focus(options?: FocusOptions): void {
    this.hostElem.nativeElement.focus(options)
  }

  onToggle(event: MouseEvent): void {
    event.stopPropagation()
    this.isPanelOpen ? this._close() : this._open()
    this.focus()
  }

  onApply(event?: MouseEvent): void {
    event?.stopPropagation()
    this._apply()
  }

  onCancel(event?: MouseEvent): void {
    event?.stopPropagation()
    this._close()
    this.updateValue(this.ngControl.control.value)
  }

  onOverlayKeydown(event: KeyboardEvent): void {
    if (this._isTabKeyPressed(event.key)) {
      this._apply()
    }
    if (this._isToggleKey(event)) {
      event.preventDefault()
      event.stopPropagation()
      this._apply()
    }
  }

  onClear(event: MouseEvent): void {
    event.stopPropagation()

    const empty = this.getEmptyValue()
    this.updateValue(empty)

    this.isPanelOpen
      ? this.internalService.triggerUIStateChange()
      : this._onChange && this._onChange(empty)
  }

  private _apply(): void {
    if (this.isPanelOpen) {
      this.isPanelOpen = false
      const valueToEmit = this.getValueToApply()
      this._onChange && this._onChange(valueToEmit)
      this._onTouched && this._onTouched()
      this.focus()
    }
  }

  private _open(): void {
    if (this.disabled) {
      return
    }
    this.internalService.untouchedSincePanelOpened = true
    this.updateValue(this.ngControl.control.value)
    this.isPanelOpen = true
    this.internalService.triggerUIStateChange()
  }

  private _close(): void {
    if (this.isPanelOpen) {
      this.isPanelOpen = false
      this.internalService.setActiveHoveredDay(null)
      this._onTouched && this._onTouched()
    }
  }

  private _onFocus(): void {
    if (!this.disabled) {
      this._focused = true
    }
  }

  private _onBlur(): void {
    this._focused = false

    if (!this.disabled && !this.isPanelOpen) {
      this._onTouched && this._onTouched()
    }
  }

  private _handleKeydown(event: KeyboardEvent): void {
    if (!this.disabled && !this.isPanelOpen && this._isToggleKey(event)) {
      event.preventDefault()
      event.stopPropagation()
      this._open()
    }
  }

  private _isTabKeyPressed(key: string): boolean {
    return key === 'Tab'
  }

  private _isSpaceKeyPressed(key: string): boolean {
    return key === ' '
  }

  private _isEnterKeyPressed(key: string): boolean {
    return key === 'Enter'
  }

  private _isToggleKey(event: KeyboardEvent): boolean {
    return (
      (this._isSpaceKeyPressed(event.key) ||
        this._isEnterKeyPressed(event.key)) &&
      !hasModifierKey(event)
    )
  }
}
