import {
  Component,
  Input,
  ChangeDetectionStrategy,
  HostBinding,
  Inject,
} from '@angular/core'
import { BuiIconType } from '../../bui-icon'
import { _BuiNavlistComponent } from '../bui-navlist.component'
import { BUI_NAVLIST, BuiNavlistVariant } from '../bui-navlist.models'

const BASE_CSS_CLASS = 'bui-navlist-item'

@Component({
  selector: 'bui-navlist-item',
  templateUrl: './bui-navlist-item.component.html',
  styleUrls: ['./bui-navlist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiNavlistItemComponent {
  @Input() icon: BuiIconType
  @Input() label: string
  @Input() link: string

  private get variant(): BuiNavlistVariant {
    return this.buiNavlist.variant || 'light'
  }

  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.icon) {
      classes.push(`${BASE_CSS_CLASS}--has-icon`)
    }

    classes.push(`${BASE_CSS_CLASS}--variant-${this.variant}`)

    return classes
  }

  constructor(@Inject(BUI_NAVLIST) private buiNavlist: _BuiNavlistComponent) {}
}
