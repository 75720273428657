import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core'
import { isValid, addMonths } from 'date-fns'
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { buiDatepickerAnimations } from '../bui-datepicker.animations'
import { _BuiDatepickerInputBase } from '../bui-datepicker-base.abstract'
import { _BuiDatepickerInternalService } from '../bui-datepicker-internal.service'
import {
  BuiDatepickerType,
  _BuiDatepickerMonthPanelType,
} from '../bui-datepicker.models'
import { BuiIconType } from '../../bui-icon'

@Component({
  selector: 'bui-date-time-input',
  templateUrl: '../bui-datepicker-base.component.html',
  styleUrls: ['../bui-datepicker-base.component.scss'],
  animations: [
    buiDatepickerAnimations.transformPanel,
    buiDatepickerAnimations.transformPanelWrap,
  ],
  providers: [
    _BuiDatepickerInternalService,
    {
      provide: 'BUI_DATE_TIME_INPUT',
      useExisting: _BuiDateTimeInputComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDateTimeInputComponent extends _BuiDatepickerInputBase<Date> {
  @Input({ required: true }) timezone: string

  @Input() minDate: Date | string
  @Input() maxDate: Date | string

  @Input() override placeholder = 'Select date & time'

  override iconName: BuiIconType = 'calendar-clock'

  get label(): string {
    return isValid(this.selectedDateUTC)
      ? formatInTimeZone(
          this.selectedDateUTC,
          this.timezone,
          `${this.displayDateFormat} ${this.internalService.timeDisplayFormat}`
        )
      : this.placeholder
  }

  get isEmpty(): boolean {
    return this.selectedDateUTC === null
  }

  // selectedDateUTC reflects active selection in the panel which can
  // differ from ngControl.value (user needs to apply the changes)
  set selectedDateUTC(value: Date) {
    this._selectedDateUTC = value
    this.setActiveDateSelection()
  }
  get selectedDateUTC(): Date {
    return this._selectedDateUTC
  }
  private _selectedDateUTC: Date = null

  getDatepickerType(): BuiDatepickerType {
    return 'date-time'
  }

  setActiveDateSelection(): void {
    this.internalService.activeDateSelection = this.selectedDateUTC
      ? utcToZonedTime(this.selectedDateUTC, this.timezone)
      : null
  }

  updateValue(value: Date): void {
    this.selectedDateUTC = isValid(value) ? new Date(value) : null
    this._setMonthPanels()
  }

  onDayClicked(value: Date): void {
    this.internalService.untouchedSincePanelOpened = false

    const year = value.getFullYear()
    const month = value.getMonth()
    const day = value.getDate()
    const { hours, minutes } = this._getTimeValues()

    this.selectedDateUTC = zonedTimeToUtc(
      new Date(year, month, day, hours, minutes),
      this.timezone
    )
    this.internalService.triggerUIStateChange()
  }

  override onTimeChanged(value: Date): void {
    if (!this.selectedDateUTC) {
      return
    }

    this.internalService.untouchedSincePanelOpened = false

    const zonedDate = utcToZonedTime(this.selectedDateUTC, this.timezone)
    zonedDate.setHours(value.getHours())
    zonedDate.setMinutes(value.getMinutes())

    this.selectedDateUTC = zonedTimeToUtc(zonedDate, this.timezone)
    this.internalService.triggerUIStateChange()
  }

  getValueToApply(): Date {
    return this.selectedDateUTC
  }

  getEmptyValue(): Date {
    return null
  }

  private _getTimeValues(): { hours: number; minutes: number } {
    if (!this.selectedDateUTC) {
      return { hours: 0, minutes: 0 }
    }

    const zonedDate = utcToZonedTime(this.selectedDateUTC, this.timezone)
    const hours = zonedDate.getHours()
    const minutes = zonedDate.getMinutes()
    return { hours, minutes }
  }

  private _setMonthPanels(): void {
    if (this.dualPanels) {
      this.selectedDateUTC
        ? this._setMonthPanelDate('panelStart', new Date(this.selectedDateUTC))
        : this._setMonthPanelDate('panelStart', new Date())

      this.selectedDateUTC
        ? this._setMonthPanelDate('panelEnd', new Date(this.selectedDateUTC))
        : this._setMonthPanelDate('panelEnd', addMonths(new Date(), 1))
    } else {
      this.selectedDateUTC
        ? this._setMonthPanelDate('panelSingle', new Date(this.selectedDateUTC))
        : this._setMonthPanelDate('panelSingle', new Date())
    }
  }

  private _setMonthPanelDate(
    panelType: _BuiDatepickerMonthPanelType,
    month: Date
  ) {
    return this.internalService.setMonthPanelDate(panelType, month)
  }
}
