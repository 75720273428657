import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiButtonToggleComponent } from './bui-button-toggle.component'
import { BuiButtonToggleGroupDirective } from './bui-button-toggle-group.directive'

@NgModule({
  declarations: [_BuiButtonToggleComponent, BuiButtonToggleGroupDirective],
  imports: [CommonModule],
  exports: [_BuiButtonToggleComponent, BuiButtonToggleGroupDirective],
})
export class BuiButtonToggleModule {}
