import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiSliderComponent } from './bui-slider.component'
import { _BuiSliderThumbComponent } from './bui-slider-thumb/bui-slider-thumb.component'
import {
  _BuiSliderRangeInputDirective,
  _BuiSliderInputDirective,
} from './bui-slider-input.directive'

@NgModule({
  declarations: [
    _BuiSliderComponent,
    _BuiSliderThumbComponent,
    _BuiSliderInputDirective,
    _BuiSliderRangeInputDirective,
  ],
  imports: [CommonModule],
  exports: [
    _BuiSliderComponent,
    _BuiSliderThumbComponent,
    _BuiSliderInputDirective,
    _BuiSliderRangeInputDirective,
  ],
})
export class BuiSliderModule {}
