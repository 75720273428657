import { Component, TemplateRef } from '@angular/core'

@Component({
  selector: 'bui-tooltip',
  styleUrls: ['./bui-tooltip.component.scss'],
  templateUrl: './bui-tooltip.component.html',
})
export class _BuiTooltipComponent {
  public template: TemplateRef<any>
  public text: string
  public htmlContent: string
}
