import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiChipComponent } from './bui-chip.component'
import { BuiIconModule } from '../bui-icon'
import { BuiTooltipModule } from '../bui-tooltip'

@NgModule({
  declarations: [_BuiChipComponent],
  imports: [CommonModule, BuiIconModule, BuiTooltipModule],
  exports: [_BuiChipComponent],
})
export class BuiChipModule {}
