import {
  Component,
  AfterViewInit,
  HostListener,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core'
import { buiToastAnimation } from './bui-toast.animations'
import { BuiToastCloseData, BuiToastData } from '../bui-toast.models'
import { AnimationEvent } from '@angular/animations'
import { Observable, timer, Subscription, BehaviorSubject } from 'rxjs'
import { take } from 'rxjs/operators'
import { BuiToastRef } from './bui-toast-ref'

@Component({
  selector: 'bui-toast',
  styleUrls: ['./bui-toast.component.scss'],
  templateUrl: './bui-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [buiToastAnimation],
})
export class _BuiToastComponent implements AfterViewInit {
  public animationState$ = new BehaviorSubject<'visible' | 'hidden'>('visible')

  private timerSubscription: Subscription | undefined
  private timerStart: number
  private timerRemaining: number

  private closedOnLinkClick: BuiToastCloseData['closedOnLinkClick'] = {
    status: false,
  }

  @HostListener('mouseenter') onMouseOver(): void {
    if (this.duration) {
      this.pauseCloseTimer()
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.setCloseTimer()
  }

  @Input() duration = 5000
  @Input({ required: true }) ref: BuiToastRef
  @Input({ required: true }) variant: BuiToastData['variant']
  @Input() title: BuiToastData['title']
  @Input({ required: true }) message: BuiToastData['message']

  @Input() set links(value: BuiToastData['links']) {
    this._links = value
  }
  get links(): string[] {
    if (this._links) {
      return Array.isArray(this._links) ? this._links : [this._links]
    }
    return []
  }
  private _links: BuiToastData['links']

  ngAfterViewInit(): void {
    this.setCloseTimer()
  }

  public onCloseButtonClick() {
    this.animationState$.next('hidden')
  }

  public onLinkClick(linkLabel: string) {
    this.closedOnLinkClick = { status: true, linkLabel }
    this.animationState$.next('hidden')
  }

  public onAnimationEnd(event: AnimationEvent): void {
    const { fromState, toState } = event
    if ((toState === 'void' && fromState !== 'void') || toState === 'hidden') {
      this.ref?.close({ closedOnLinkClick: this.closedOnLinkClick })
    }
  }

  private setCloseTimer(): void {
    if (this.duration > 0) {
      this.timerStart = Date.now()
      this.timerSubscription = this.createTimer(
        this.timerRemaining || this.duration
      ).subscribe(() => this.animationState$.next('hidden'))
    }
  }

  private pauseCloseTimer(): void {
    this.timerRemaining = this.duration - (Date.now() - this.timerStart)
    this.timerSubscription?.unsubscribe()
  }

  private createTimer(duration: number): Observable<number> {
    return timer(duration).pipe(take(1))
  }
}
