<div class="bui-checkbox-inner" (click)="_preventBubblingFromLabel($event)">
  <label #label [for]="inputId" class="bui-checkbox-label">
    <ng-content></ng-content>
  </label>
  <div class="bui-checkbox-box">
    <input
      #input
      type="checkbox"
      class="bui-checkbox-box__native-control"
      [attr.name]="name"
      [attr.value]="value"
      [checked]="checked"
      [indeterminate]="indeterminate"
      [disabled]="disabled"
      [id]="inputId"
      [required]="required"
      [tabIndex]="tabIndex"
      (blur)="_onBlur()"
      (click)="_onInputClick()"
      (change)="_onInteractionEvent($event)"
    />
    <div class="bui-checkbox-box__interface"></div>
    <div
      class="bui-checkbox-box__touch-target"
      (click)="_onTouchTargetClick()"
    ></div>
  </div>
</div>
