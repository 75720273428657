import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Injectable, Injector } from '@angular/core'
import { BuiGalleryOverlayConfig } from './bui-gallery-overlay-config'
import { _BuiGalleryOverlayContainerComponent } from './bui-gallery-overlay-container.component'
import { BuiGalleryOverlayRef } from './bui-gallery-overlay-ref'

@Injectable({ providedIn: 'root' })
export class BuiGalleryOverlayService {
  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  public open(data: BuiGalleryOverlayConfig['data']): BuiGalleryOverlayRef {
    const config = new BuiGalleryOverlayConfig(data)
    const overlayRef = this.createOverlay(config)
    const galleryOverlayRef = this.attachGallery(overlayRef, config)

    return galleryOverlayRef
  }

  private createOverlay(config: BuiGalleryOverlayConfig): OverlayRef {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: config.hasBackdrop,
    })

    if (config.backdropClass) {
      overlayConfig.backdropClass = config.backdropClass
    }

    return this.overlay.create(overlayConfig)
  }

  private attachGallery(
    overlayRef: OverlayRef,
    config: BuiGalleryOverlayConfig
  ): BuiGalleryOverlayRef {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: BuiGalleryOverlayConfig, useValue: config }],
    })
    const galleryPortal = new ComponentPortal(
      _BuiGalleryOverlayContainerComponent,
      null,
      injector
    )
    const galleryContainerRef =
      overlayRef.attach<_BuiGalleryOverlayContainerComponent>(galleryPortal)

    const galleryOverlayRef = new BuiGalleryOverlayRef(
      overlayRef,
      galleryContainerRef.instance
    )

    if (config.hasBackdrop && !config.disableBackdropClose) {
      overlayRef.backdropClick().subscribe(() => galleryOverlayRef.close())
    }

    galleryOverlayRef.updatePosition()

    return galleryOverlayRef
  }
}
