import { BuiGallerySlide } from '../bui-gallery.models'

export class BuiGalleryOverlayConfig {
  constructor(data: BuiGalleryOverlayConfig['data']) {
    this.data = data
  }

  data: {
    slides: BuiGallerySlide[]
    startIndex?: number
    title?: string
  }
  disableBackdropClose?: boolean = false
  hasBackdrop?: boolean = true
  backdropClass?: string = ''
}
