import { Component, HostBinding } from '@angular/core'
import { buiFormFieldErrorAnimation } from './bui-form-field-error.animations'

const BASE_CSS_CLASS = 'bui-form-field-error'

@Component({
  selector: 'bui-form-field-error',
  template: '<ng-content></ng-content>',
  styleUrls: ['bui-form-field-error.component.scss'],
  animations: [buiFormFieldErrorAnimation],
})
export class _BuiFormFieldErrorComponent {
  @HostBinding('@slideIn') slideIn = true

  @HostBinding('class') get classes() {
    return [BASE_CSS_CLASS]
  }
}
