import {
  Component,
  Directive,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core'

export const BuiListVariant = ['framed', 'horizontal-line'] as const
export type BuiListVariant = (typeof BuiListVariant)[number]

const BASE_CSS_CLASS = 'bui-list'

@Component({
  selector: 'bui-list',
  template: '<ng-content></ng-content>',
  styleUrls: ['./bui-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class _BuiListComponent {
  @Input() variant: BuiListVariant = 'horizontal-line'

  @HostBinding('class') get classes() {
    const classes = [BASE_CSS_CLASS]

    if (this.variant) {
      classes.push(`${BASE_CSS_CLASS}--variant-${this.variant}`)
    }

    return classes
  }
}

@Directive({ selector: 'bui-list-row' })
export class _BuiListRowDirective {
  @HostBinding('class') get classes() {
    return 'bui-list-row'
  }
}

@Directive({ selector: 'bui-list-row-spacer' })
export class _BuiListRowSpacerDirective {
  @HostBinding('class') get classes() {
    return 'bui-list-row-spacer'
  }
}

@Directive({ selector: 'bui-list-cell' })
export class _BuiListCellDirective {
  @HostBinding('class') get classes() {
    return 'bui-list-cell'
  }
}
