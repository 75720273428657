import { Directive, HostBinding, Input } from '@angular/core'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'

const BASE_CSS_CLASS = 'bui-table'

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[buiTable]' })
export class _BuiTableDirective {
  @Input('buiTableSmall') set small(v: BooleanInput) {
    this._small = coerceBooleanProperty(v)
  }
  get small(): boolean {
    return this._small
  }
  private _small = false

  @Input('buiTableZebra') set zebra(v: BooleanInput) {
    this._zebra = coerceBooleanProperty(v)
  }
  get zebra(): boolean {
    return this._zebra
  }
  private _zebra = false

  @Input('buiTableResponsive') set responsive(v: BooleanInput) {
    this._responsive = coerceBooleanProperty(v)
  }
  get responsive(): boolean {
    return this._responsive
  }
  private _responsive = false

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.small) {
      classes.push(`${BASE_CSS_CLASS}--small`)
    }
    if (this.zebra) {
      classes.push(`${BASE_CSS_CLASS}--zebra`)
    }
    if (this.responsive) {
      classes.push(`${BASE_CSS_CLASS}--responsive`)
    }
    return classes
  }
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[buiTableResponsiveLabel]' })
export class _BuiTableResponsiveLabelDirective {
  @HostBinding('class') get classes(): string[] {
    return ['bui-table-responsive-label']
  }
}
