import { NgModule } from '@angular/core'
import { _BuiTooltipComponent } from './bui-tooltip.component'
import { CommonModule } from '@angular/common'
import { _BuiTooltipDirective } from './bui-tooltip.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [_BuiTooltipComponent, _BuiTooltipDirective],
  exports: [_BuiTooltipDirective],
})
export class BuiTooltipModule {}
