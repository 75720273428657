import {
  Injectable,
  Injector,
  InjectionToken,
  TemplateRef,
  Optional,
  Inject,
} from '@angular/core'
import {
  Overlay,
  ComponentType,
  OverlayRef,
  OverlayConfig,
} from '@angular/cdk/overlay'
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { BuiModalConfig } from './bui-modal-config'
import { BuiModalRef } from './bui-modal-ref'
import { _BuiModalContainerComponent } from './bui-modal-container/bui-modal-container.component'

export const BUI_MODAL_DATA = new InjectionToken<any>('BuiModalData')
export const BUI_MODAL_DEFAULT_OPTIONS = new InjectionToken<BuiModalConfig>(
  'bui-modal-default-options'
)

@Injectable({ providedIn: 'root' })
export class BuiModalService {
  private openedModal: BuiModalRef<any>

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    @Optional()
    @Inject(BUI_MODAL_DEFAULT_OPTIONS)
    private defaultOptions: BuiModalConfig
  ) {}

  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: BuiModalConfig<D>
  ): BuiModalRef<T, R> {
    if (this.openedModal) {
      return this.openedModal
    }

    config = applyConfigDefaults(
      config,
      this.defaultOptions || new BuiModalConfig()
    )

    const overlayRef = this.createOverlay(config)
    const modalContainer = this.attachModalContainer(overlayRef, config)
    const modalRef = this.attachModalContent<T, R>(
      componentOrTemplateRef,
      modalContainer,
      overlayRef,
      config
    )

    this.openedModal = modalRef
    modalRef.afterClosed$.subscribe(() => {
      this.openedModal = undefined
    })

    return modalRef
  }

  private createOverlay(config: BuiModalConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config)
    return this.overlay.create(overlayConfig)
  }

  private getOverlayConfig(config: BuiModalConfig): OverlayConfig {
    const _config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: config.hasBackdrop,
    })

    if (config.backdropClass) {
      _config.backdropClass = config.backdropClass
    }
    return _config
  }

  private attachModalContainer(
    overlay: OverlayRef,
    config: BuiModalConfig
  ): _BuiModalContainerComponent {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: BuiModalConfig, useValue: config }],
    })
    const containerPortal = new ComponentPortal(
      _BuiModalContainerComponent,
      null,
      injector
    )
    const containerRef =
      overlay.attach<_BuiModalContainerComponent>(containerPortal)

    return containerRef.instance
  }

  private attachModalContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    modalContainer: _BuiModalContainerComponent,
    overlayRef: OverlayRef,
    config: BuiModalConfig
  ): BuiModalRef<T, R> {
    const modalRef = new BuiModalRef<T, R>(overlayRef, modalContainer)

    if (config.hasBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        if (!modalRef.disableClose) {
          modalRef.close()
        }
      })
    }

    if (componentOrTemplateRef instanceof TemplateRef) {
      modalContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, {
          $implicit: config.data,
          modalRef,
        } as any)
      )
    } else {
      const injector = this.createInjector(config, modalRef, modalContainer)
      const contentRef = modalContainer.attachComponentPortal<T>(
        new ComponentPortal(componentOrTemplateRef, null, injector)
      )
      modalRef.componentInstance = contentRef.instance
    }

    modalRef.updatePosition(config.position)

    return modalRef
  }

  private createInjector<T>(
    config: BuiModalConfig,
    modalRef: BuiModalRef<T>,
    modalContainer: _BuiModalContainerComponent
  ): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: _BuiModalContainerComponent, useValue: modalContainer },
        { provide: BUI_MODAL_DATA, useValue: config.data },
        { provide: BuiModalRef, useValue: modalRef },
      ],
    })
  }
}

function applyConfigDefaults(
  config?: BuiModalConfig,
  defaultOptions?: BuiModalConfig
): BuiModalConfig {
  return { ...defaultOptions, ...config }
}
