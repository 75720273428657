import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { A11yModule } from '@angular/cdk/a11y'
import { BuiLoaderModule } from '../bui-loader'
import { _BuiAsideContainerComponent } from './bui-aside-container/bui-aside-container.component'
import {
  _BuiAsideLayoutDirective,
  _BuiAsideHeaderDirective,
  _BuiAsideContentDirective,
  _BuiAsideFooterDirective,
} from './bui-aside-directives'
import { _BuiAsideLoaderComponent } from './bui-aside-loader/bui-aside-loader.component'

@NgModule({
  declarations: [
    _BuiAsideContainerComponent,
    _BuiAsideLayoutDirective,
    _BuiAsideHeaderDirective,
    _BuiAsideContentDirective,
    _BuiAsideFooterDirective,
    _BuiAsideLoaderComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    A11yModule,
    BuiLoaderModule,
  ],
  exports: [
    _BuiAsideContainerComponent,
    _BuiAsideLayoutDirective,
    _BuiAsideHeaderDirective,
    _BuiAsideContentDirective,
    _BuiAsideFooterDirective,
    _BuiAsideLoaderComponent,
  ],
})
export class BuiAsideModule {}
