import { _BuiRadioButtonBase } from './bui-radio.component'

// Change event object emitted by radio button and radio group
export class BuiRadioChange {
  constructor(
    // The radio button that emits the change event
    public source: _BuiRadioButtonBase,
    // The value of the radio button
    public value: any
  ) {}
}
