import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormControl, ValidatorFn, Validators } from '@angular/forms'
import { debounceTime } from 'rxjs'
import { BuiPaginationChange } from './bui-pagination.interface'

@Component({
  selector: 'bui-pagination',
  templateUrl: './bui-pagination.component.html',
  styleUrls: ['./bui-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiPaginationComponent implements OnChanges, OnDestroy {
  private activePageIndex = 1
  public activePageFormControl = new FormControl<number>(1, {
    validators: this.getActivePageValidators(),
    nonNullable: true,
  })
  private activePageSubscription = this.activePageFormControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe((pageIndex) => {
      if (
        this.activePageFormControl.valid &&
        this.activePageIndex !== pageIndex
      ) {
        this.activePageIndex = pageIndex
        this.paginationChange.emit({ page: pageIndex })
      }
    })

  private _totalPagesCount = 1
  public set totalPagesCount(value: number) {
    this._totalPagesCount = value
    this.activePageFormControl.clearValidators()
    this.activePageFormControl.addValidators(this.getActivePageValidators())
    this.activePageFormControl.updateValueAndValidity()
  }
  public get totalPagesCount(): number {
    return this._totalPagesCount
  }

  @Input() public itemsPerPage = 25
  @Input() public totalCount = 0
  @Input() public disabled = false

  @Output() paginationChange = new EventEmitter<BuiPaginationChange>()

  @HostBinding('class') get classes() {
    return 'bui-pagination'
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalCount'] || changes['itemsPerPage']) {
      this.totalPagesCount = Math.ceil(this.totalCount / this.itemsPerPage)
      this.activePageFormControl.setValue(1)
    }

    if (changes['disabled']) {
      if (this.disabled) {
        this.activePageFormControl.disable()
      } else {
        this.activePageFormControl.enable()
      }
    }
  }

  ngOnDestroy(): void {
    if (this.activePageSubscription) {
      this.activePageSubscription.unsubscribe()
    }
  }

  private getActivePageValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.min(1),
      Validators.max(this.totalPagesCount),
    ]
  }

  public getInfoText(): string {
    const firstItemIndex = (this.activePageIndex - 1) * this.itemsPerPage + 1
    let lastItemIndex = this.activePageIndex * this.itemsPerPage
    if (lastItemIndex > this.totalCount) {
      lastItemIndex = this.totalCount
    }
    return `${firstItemIndex} - ${lastItemIndex} of ${this.totalCount}`
  }

  public isPreviousButtonDisabled(): boolean {
    return this.activePageFormControl.disabled || this.activePageIndex <= 1
  }

  public isNextButtonDisabled(): boolean {
    return (
      this.activePageFormControl.disabled ||
      this.activePageIndex >= this.totalPagesCount
    )
  }

  public goToPreviousPage(): void {
    if (!this.isPreviousButtonDisabled()) {
      this.activePageFormControl.setValue(this.activePageIndex - 1)
    }
  }

  public goToNextPage(): void {
    if (!this.isNextButtonDisabled()) {
      this.activePageFormControl.setValue(this.activePageIndex + 1)
    }
  }
}
