import {
  animate,
  animateChild,
  AnimationTriggerMetadata,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'

export const buiSelectAnimations: {
  readonly transformPanelWrap: AnimationTriggerMetadata
  readonly transformPanel: AnimationTriggerMetadata
} = {
  transformPanelWrap: trigger('transformPanelWrap', [
    transition(
      '* => void',
      query('@transformPanel', [animateChild()], { optional: true })
    ),
  ]),
  transformPanel: trigger('transformPanel', [
    state(
      'void',
      style({
        minWidth: '100%',
        opacity: 0,
      })
    ),
    state(
      'showing',
      style({
        opacity: 1,
      })
    ),
    transition('void => *', animate('120ms cubic-bezier(0, 0, 0.2, 1)')),
    transition(
      '* => void',
      animate('100ms 25ms linear', style({ opacity: 0 }))
    ),
  ]),
}
