import { Component, HostBinding, Input } from '@angular/core'

export const BuiControlBarVariant = ['section', 'table', 'underlined'] as const
export type BuiControlBarVariant = (typeof BuiControlBarVariant)[number]

const BASE_CSS_CLASS = 'bui-control-bar'

@Component({
  selector: 'bui-control-bar',
  templateUrl: './bui-control-bar.component.html',
  styleUrls: ['./bui-control-bar.component.scss'],
})
export class _BuiControlBarComponent {
  @Input() variant: BuiControlBarVariant
  @Input() preventWrap = false

  @HostBinding('class') get classes() {
    const classes = [BASE_CSS_CLASS]

    if (this.variant) {
      classes.push(`${BASE_CSS_CLASS}--variant-${this.variant}`)
    }

    if (this.preventWrap) {
      classes.push(`${BASE_CSS_CLASS}--prevent-wrap`)
    }

    return classes
  }
}
