// Based on Angular Material Button Toggle v15

import { FocusMonitor } from '@angular/cdk/a11y'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  Inject,
  AfterViewInit,
  HostBinding,
  HostListener,
} from '@angular/core'
import {
  BUI_BUTTON_TOGGLE_GROUP,
  BuiButtonToggleGroupDirective,
} from './bui-button-toggle-group.directive'
import { BuiSize } from '../bui-size'
import { BuiButtonToggleChange } from './bui-button-toggle.models'

const BASE_CSS_CLASS = 'bui-button-toggle'

@Component({
  selector: 'bui-button-toggle',
  templateUrl: 'bui-button-toggle.component.html',
  styleUrls: ['bui-button-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiButtonToggleComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    classes.push(`${BASE_CSS_CLASS}--${this.size}`)

    if (!this.buttonToggleGroup) {
      classes.push(`${BASE_CSS_CLASS}--standalone`)
    }

    classes.push(
      this.buttonToggleGroup?.vertical
        ? `${BASE_CSS_CLASS}--vertical`
        : `${BASE_CSS_CLASS}--horizontal`
    )

    if (this.buttonToggleGroup?.multiple) {
      classes.push(`${BASE_CSS_CLASS}--multiple`)
    }
    if (this.checked) {
      classes.push(`${BASE_CSS_CLASS}--checked`)
    }
    if (this.disabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }

    return classes
  }

  // Underlying native `button` element
  @ViewChild('button') _buttonElement: ElementRef<HTMLButtonElement>

  private _size: BuiSize = 'regular'
  private get size(): BuiSize {
    return this.buttonToggleGroup?.size || this._size
  }

  // The parent button toggle group. Optional
  buttonToggleGroup: BuiButtonToggleGroupDirective

  // BuiButtonToggleGroup reads this to assign its own value
  @Input() value: any

  // Tabindex for the toggle
  @Input() tabIndex: number | null

  // Whether the button is checked
  @Input()
  get checked(): boolean {
    return this.buttonToggleGroup
      ? this.buttonToggleGroup._isSelected(this)
      : this._checked
  }
  set checked(value: BooleanInput) {
    const newValue = coerceBooleanProperty(value)

    if (newValue !== this._checked) {
      this._checked = newValue

      if (this.buttonToggleGroup) {
        this.buttonToggleGroup._syncButtonToggle(this, this._checked)
      }

      this._changeDetectorRef.markForCheck()
    }
  }
  private _checked = false

  @Input()
  get disabled(): boolean {
    return (
      this._disabled ||
      (this.buttonToggleGroup && this.buttonToggleGroup.disabled)
    )
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
  }
  private _disabled = false

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change = new EventEmitter<BuiButtonToggleChange>()

  @HostListener('focus') private _onFocus() {
    this.focus()
  }

  constructor(
    @Optional()
    @Inject(BUI_BUTTON_TOGGLE_GROUP)
    toggleGroup: BuiButtonToggleGroupDirective,
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef<HTMLElement>,
    private _focusMonitor: FocusMonitor,
    @Attribute('tabindex') defaultTabIndex: string
  ) {
    const parsedTabIndex = Number(defaultTabIndex)
    this.tabIndex =
      parsedTabIndex || parsedTabIndex === 0 ? parsedTabIndex : null
    this.buttonToggleGroup = toggleGroup
  }

  ngOnInit() {
    const group = this.buttonToggleGroup

    if (group) {
      if (group._isPrechecked(this)) {
        this.checked = true
      } else if (group._isSelected(this) !== this._checked) {
        // As as side effect of the circular dependency between the toggle group and the button,
        // we may end up in a state where the button is supposed to be checked on init, but it
        // isn't, because the checked value was assigned too early.
        group._syncButtonToggle(this, this._checked)
      }
    }
  }

  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true)
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef)

    // Remove the toggle from the selection once it's destroyed. Needs to happen
    // on the next tick in order to avoid "changed after checked" errors.
    if (this.buttonToggleGroup?._isSelected(this)) {
      this.buttonToggleGroup._syncButtonToggle(this, false, false, true)
    }
  }

  // Focuses the button
  focus(options?: FocusOptions): void {
    this._buttonElement.nativeElement.focus(options)
  }

  // Checks the button toggle due to an interaction with the underlying native button
  _onButtonClick() {
    const newChecked = this._isSingleSelector() ? true : !this._checked

    if (newChecked !== this._checked) {
      this._checked = newChecked
      if (this.buttonToggleGroup) {
        this.buttonToggleGroup._syncButtonToggle(this, this._checked, true)
        this.buttonToggleGroup._onTouched()
      }
    }
    // Emit a change event when it's the single selector
    this.change.emit(new BuiButtonToggleChange(this, this.value))
  }

  // Marks the button toggle as needing checking for change detection.
  _markForCheck() {
    // When the group value changes, the button will not be notified.
    // Use `markForCheck` to explicit update button toggle's status.
    this._changeDetectorRef.markForCheck()
  }

  // Whether the toggle is in single selection mode
  private _isSingleSelector(): boolean {
    return this.buttonToggleGroup && !this.buttonToggleGroup.multiple
  }
}
