<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="mode === 'useRouter'; else noRouter">
  <a
    class="bui-tab__element"
    [routerLink]="[value]"
    routerLinkActive="bui-tab__element--active bui-tab__element--active-link"
  >
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </a>
</ng-container>
<ng-template #noRouter>
  <button class="bui-tab__element" [class.bui-tab__element--active]="isActive">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-template>
