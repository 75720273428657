import { ChangeDetectionStrategy, Component } from '@angular/core'
import { _BuiToastInternalService } from '../bui-toast-internal.service'

@Component({
  styleUrls: ['./bui-toast-container.component.scss'],
  templateUrl: './bui-toast-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiToastContainerComponent {
  toasts$ = this.internalService.toasts$

  constructor(private internalService: _BuiToastInternalService) {}
}
