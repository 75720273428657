import { ChangeDetectionStrategy, Component, input } from '@angular/core'

@Component({
  selector: 'bui-info-item',
  template: `
    <div class="info-item">
      <span class="title">{{ title() }}</span>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./bui-info-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiInfoItemComponent {
  public title = input<string>()
}
