import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'

export const BuiTitleElement = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const
export type BuiTitleElement = (typeof BuiTitleElement)[number]

const BASE_CSS_CLASS = 'bui-title'

@Component({
  selector: 'bui-title',
  templateUrl: './bui-title.component.html',
  styleUrls: ['./bui-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiTitleComponent {
  @Input() element: BuiTitleElement = 'h2'
  @Input() styleOverride: BuiTitleElement
  @Input() variant = 'heavy'

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]
    classes.push(
      `${BASE_CSS_CLASS}--element-${this.styleOverride || this.element}`
    )
    classes.push(`${BASE_CSS_CLASS}--variant-${this.variant}`)
    return classes
  }
}
