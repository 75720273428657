// Based on Angular Material Slider v15

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  booleanAttribute,
} from '@angular/core'
import {
  _BuiThumbPosition,
  _BuiSlider,
  _BuiSliderInput,
  _BuiSliderThumb,
  BUI_SLIDER,
  BUI_SLIDER_THUMB,
} from '../bui-slider.models'

const BASE_CSS_CLASS = 'bui-slider-thumb'

// The visual slider thumb. Handles displaying the value indicator on sliders
@Component({
  selector: 'bui-slider-thumb',
  templateUrl: './bui-slider-thumb.component.html',
  styleUrls: ['bui-slider-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BUI_SLIDER_THUMB,
      useExisting: _BuiSliderThumbComponent,
    },
  ],
})
export class _BuiSliderThumbComponent
  implements _BuiSliderThumb, AfterViewInit, OnDestroy
{
  @HostBinding('class') get classes() {
    let classes = BASE_CSS_CLASS

    if (this.disabled) {
      classes += ` ${BASE_CSS_CLASS}--disabled`
    }

    return classes
  }

  // Indicates which slider thumb this input corresponds to
  @Input() thumbPosition: _BuiThumbPosition

  // The display value of the slider thumb
  @Input() valueIndicatorText: string

  @Input({ transform: booleanAttribute }) disabled = false

  // The slider input corresponding to this slider thumb
  private _sliderInput: _BuiSliderInput

  // Whether the slider thumb is currently being hovered
  private set _isHovered(isHovered: boolean) {
    if (this._sliderInput.disabled) {
      return
    }

    if (isHovered) {
      this.renderer.addClass(this._hostElement, `${BASE_CSS_CLASS}--hover`)
    } else {
      this.renderer.removeClass(this._hostElement, `${BASE_CSS_CLASS}--hover`)
    }
  }

  // Whether the slider thumb is currently being focused
  private set _isFocused(isFocused: boolean) {
    if (isFocused) {
      this.renderer.addClass(this._hostElement, `${BASE_CSS_CLASS}--focus`)
    } else {
      this.renderer.removeClass(this._hostElement, `${BASE_CSS_CLASS}--focus`)
    }
  }

  // Whether the slider thumbs are currently overlapping. Applies only to range slider thumbs
  private set _isOverlapping(isOverlapping: boolean) {
    if (isOverlapping) {
      this.renderer.addClass(
        this._hostElement,
        `${BASE_CSS_CLASS}--overlapping`
      )
    } else {
      this.renderer.removeClass(
        this._hostElement,
        `${BASE_CSS_CLASS}--overlapping`
      )
    }
  }

  // Whether the slider thumb is currently being pressed
  public _isActive = false

  // The host native HTML input element
  public _hostElement: HTMLElement

  constructor(
    private readonly _ngZone: NgZone,
    _elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(BUI_SLIDER) private _slider: _BuiSlider
  ) {
    this._hostElement = _elementRef.nativeElement
  }

  ngAfterViewInit(): void {
    this._sliderInput = this._slider._getInput(this.thumbPosition)
    const input = this._sliderInput._hostElement

    // These listeners don't update any data bindings so we bind them outside
    // of the NgZone to prevent Angular from needlessly running change detection.
    this._ngZone.runOutsideAngular(() => {
      input.addEventListener('pointermove', this._onPointerMove)
      input.addEventListener('pointerdown', this._onDragStart)
      input.addEventListener('pointerup', this._onDragEnd)
      input.addEventListener('pointerleave', this._onMouseLeave)
      input.addEventListener('focus', this._onFocus)
      input.addEventListener('blur', this._onBlur)
    })
  }

  ngOnDestroy(): void {
    const input = this._sliderInput._hostElement
    input.removeEventListener('pointermove', this._onPointerMove)
    input.removeEventListener('pointerdown', this._onDragStart)
    input.removeEventListener('pointerup', this._onDragEnd)
    input.removeEventListener('pointerleave', this._onMouseLeave)
    input.removeEventListener('focus', this._onFocus)
    input.removeEventListener('blur', this._onBlur)
  }

  public setIsOverlapping(isOverlapping: boolean): void {
    this._isOverlapping = isOverlapping
  }

  private _onPointerMove = (event: PointerEvent): void => {
    if (this._sliderInput._isFocused) {
      return
    }

    this._isHovered = this._isSliderThumbHovered(event, this._hostElement)
  }

  private _onMouseLeave = (): void => {
    this._isHovered = false
  }

  private _onFocus = (): void => {
    this._isFocused = true
  }

  private _onBlur = (): void => {
    this._isFocused = false
  }

  private _onDragStart = (): void => {
    this._isActive = true
  }

  private _onDragEnd = (): void => {
    this._isActive = false
  }

  private _isSliderThumbHovered(
    event: PointerEvent,
    element: HTMLElement
  ): boolean {
    const rect = element.getBoundingClientRect()
    const radius = rect.width / 2
    const centerX = rect.x + radius
    const centerY = rect.y + radius
    const dx = event.clientX - centerX
    const dy = event.clientY - centerY
    return Math.pow(dx, 2) + Math.pow(dy, 2) < Math.pow(radius, 2)
  }
}
