import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { interval, map, Observable, shareReplay, takeUntil, tap } from 'rxjs'
import { BuiLoaderVariant } from './bui-loader.models'
import { injectDestroy } from '../util'

@Component({
  selector: 'bui-loader',
  templateUrl: './bui-loader.component.html',
  styleUrls: ['./bui-loader.component.scss'],
})
export class _BuiLoaderComponent implements OnInit {
  private destroy$ = injectDestroy()

  @Input() variant: BuiLoaderVariant = 'dots'

  @HostBinding('class') get classes() {
    return `bui-loader bui-loader--${this.variant}`
  }

  private activeDotIndex = 0
  public activeDotIndex$: Observable<number>

  ngOnInit(): void {
    if (this.variant === 'dots') {
      this.activeDotIndex$ = interval(200).pipe(
        takeUntil(this.destroy$),
        map(() => this.activeDotIndex),
        tap(() => {
          if (this.activeDotIndex + 1 >= 3) {
            this.activeDotIndex = 0
          } else {
            this.activeDotIndex++
          }
        }),
        shareReplay(1)
      )
    }
  }
}
