<div class="bui-tabs-inner">
  <bui-tab
    *ngFor="let tab of tabs"
    [value]="tab.value"
    [isActive]="(activeTabValue$ | async) === tab.value"
    (tabClicked)="onTabClicked($event)"
  >
    <span class="bui-tab__label">{{ tab.label }}</span>
    <bui-icon
      *ngIf="tab.icon"
      class="bui-tab__icon"
      [name]="tab.icon"
    ></bui-icon>
  </bui-tab>
</div>
