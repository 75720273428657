<div class="bui-breadcrumbs__inner">
  <ng-container *ngFor="let page of history || []; let i = index">
    <bui-icon
      name="chevron-right"
      class="bui-breadcrumbs__arrow"
      *ngIf="i > 0"
    ></bui-icon>

    <span *ngIf="!page.link" class="bui-breadcrumbs__text">{{
      page.text
    }}</span>
    <a *ngIf="page.link" [routerLink]="page.link" class="bui-breadcrumbs__link">
      {{ page.text }}
    </a>
  </ng-container>
</div>
