import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay'
import { hasModifierKey } from '@angular/cdk/keycodes'
import { Subject, Subscription } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { _BuiGalleryOverlayContainerComponent } from './bui-gallery-overlay-container.component'

export class BuiGalleryOverlayRef {
  private readonly _afterClosed = new Subject<void>()
  private readonly _afterOpened = new Subject<void>()

  public afterOpened$ = this._afterOpened.asObservable()
  public afterClosed$ = this._afterClosed.asObservable()

  private closeFallbackTimeout: number
  private closeSubscription: Subscription

  constructor(
    private overlayRef: OverlayRef,
    public containerInstance: _BuiGalleryOverlayContainerComponent
  ) {
    // Emit when opening animation completes
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'enter'
        ),
        take(1)
      )
      .subscribe(() => {
        this._afterOpened.next()
        this._afterOpened.complete()
      })

    // Dispose overlay when closing animation is complete
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'exit'
        ),
        take(1)
      )
      .subscribe(() => {
        clearTimeout(this.closeFallbackTimeout)
        this.overlayRef.dispose()
      })

    this.closeSubscription = containerInstance.galleryInstance.closeOverlayEvent
      .pipe(take(1))
      .subscribe(() => this.close())

    overlayRef.detachments().subscribe(() => {
      this._afterClosed.next()
      this._afterClosed.complete()
      this.overlayRef.dispose()
    })

    overlayRef
      .keydownEvents()
      .pipe(
        filter(
          (event) =>
            ['Escape', 'ArrowLeft', 'ArrowRight'].includes(event.code) &&
            !hasModifierKey(event)
        )
      )
      .subscribe((event) => {
        event.preventDefault()
        switch (event.code) {
          case 'Escape':
            this.close()
            break
          case 'ArrowLeft':
            this.containerInstance.galleryInstance.goToPrev()
            break
          case 'ArrowRight':
            this.containerInstance.galleryInstance.goToNext()
            break
        }
      })
  }

  public close(): void {
    this.closeSubscription.unsubscribe()

    this.containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start'),
        take(1)
      )
      .subscribe((event) => {
        this.overlayRef.detachBackdrop()

        this.closeFallbackTimeout = window.setTimeout(() => {
          this.overlayRef.dispose()
        }, event.totalTime + 100)
      })

    this.containerInstance.startExitAnimation()
  }

  public updatePosition(): void {
    const strategy = this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy

    strategy.centerHorizontally()
    strategy.centerVertically()

    this.overlayRef.updatePosition()
  }
}
