import { Injectable } from '@angular/core'
import { _BuiToastItem } from './bui-toast.models'
import { BehaviorSubject } from 'rxjs'
import {
  GlobalPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { _BuiToastContainerComponent } from './bui-toast-container/bui-toast-container.component'

/**
 * Internal BUI Toast service. It should not be exported/exposed to BUI lib users
 */
@Injectable()
export class _BuiToastInternalService {
  private toastsSubject$ = new BehaviorSubject<_BuiToastItem[]>([])
  public toasts$ = this.toastsSubject$.asObservable()

  public overlayRef: OverlayRef

  public get toastsCount(): number {
    return this.toastsSubject$.value.length
  }

  constructor(private overlay: Overlay) {}

  public addToast(toastData: _BuiToastItem): void {
    this.toastsSubject$.next([...this.toastsSubject$.value, toastData])
  }

  public removeToast(index: number): void {
    const toasts = [...this.toastsSubject$.value]
    toasts[index] = undefined
    this.toastsSubject$.next(toasts)
    this.disposeIfEmpty()
  }

  public clearAllToasts(): void {
    this.toastsSubject$.next([])
    this.overlayRef?.dispose()
    this.overlayRef = undefined
  }

  public createOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.getPositionStrategy(),
        panelClass: 'bui-toast',
      })
      this.overlayRef.attach<_BuiToastContainerComponent>(
        new ComponentPortal(_BuiToastContainerComponent)
      )
    }
  }

  private getPositionStrategy(): GlobalPositionStrategy {
    return this.overlay.position().global().right('0px').top('0px')
  }

  private disposeIfEmpty(): void {
    const toasts = this.toastsSubject$.value.filter((toast) => !!toast)

    if (!toasts.length) {
      this.clearAllToasts()
    }
  }
}
