import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { fromEvent, Observable } from 'rxjs'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  scan,
} from 'rxjs/operators'

export interface NavigationStartExtended extends NavigationStart {
  originUrl: string
}

@Injectable({ providedIn: 'root' })
export class BuiEventsService {
  public readonly windowResize$: Observable<Event> = fromEvent(
    window,
    'resize'
  ).pipe(debounceTime(200), distinctUntilChanged())

  public readonly navigationStart$: Observable<NavigationStartExtended> =
    this.router.events.pipe(
      filter(isNavigationStart),
      scan(
        (acc: NavigationStartExtended, event: NavigationStart) => ({
          originUrl: acc?.url || this.router.url,
          ...event,
        }),
        null
      )
    )

  constructor(private router: Router) {}
}

function isNavigationStart(event: unknown): event is NavigationStart {
  return event instanceof NavigationStart
}
