import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  booleanAttribute,
  inject,
} from '@angular/core'
import { ControlValueAccessor, FormControl } from '@angular/forms'
import { BuiSize } from '../bui-size'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { injectDestroy } from '../util'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs'

const BASE_CSS_CLASS = 'bui-input'

@Component({ template: '' })
export abstract class _BuiInputBaseComponent<TOutputValue>
  implements ControlValueAccessor
{
  abstract type: string

  private readonly changeDetectorRef = inject(ChangeDetectorRef)
  private readonly destroy$ = injectDestroy()

  // Internal FormControl - holds the internal string value shown
  // in the input field. It may differ from the value in [(ngModel)],
  // [formControl] & (change) if it's modified in transformOutputValue()
  readonly _formControl = new FormControl<string>(null)

  @Input() size: BuiSize | 'extra-small' = 'regular'
  @Input() placeholder: string

  @Input({ transform: booleanAttribute }) muteStyles = false
  @Input({ transform: booleanAttribute }) autofocus = false

  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
    this._disabled
      ? this._formControl.disable({ emitEvent: false })
      : this._formControl.enable({ emitEvent: false })
    this.changeDetectorRef.markForCheck()
  }
  private _disabled = false

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    classes.push(`${BASE_CSS_CLASS}--${this.type}`)
    classes.push(`${BASE_CSS_CLASS}--${this.size}`)

    if (this.disabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }
    if (this.muteStyles) {
      classes.push(`${BASE_CSS_CLASS}--mute-styles`)
    }

    return classes
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly blur = new EventEmitter<FocusEvent>()

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly focus = new EventEmitter<FocusEvent>()

  constructor() {
    this._formControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(0), takeUntil(this.destroy$))
      .subscribe((value) =>
        this._controlValueAccessorChangeFn(this._transformOutputValue(value))
      )
  }

  // The method to be called in order to update ngModel.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _controlValueAccessorChangeFn: (value: TOutputValue) => void = () => {}

  // onTouch function registered via registerOnTouch (ControlValueAccessor)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched: () => void = () => {}

  writeValue(value: TOutputValue): void {
    this._formControl.setValue(value?.toString() ?? '', { emitEvent: false })
  }

  registerOnChange(fn: (value: TOutputValue) => void): void {
    this._controlValueAccessorChangeFn = fn
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  onFocus(event: FocusEvent) {
    this.focus.emit(event)
  }

  onBlur(event: FocusEvent) {
    // When a focused element becomes disabled, the browser *immediately* fires a blur event.
    // Angular does not expect events to be raised during change detection, so any state change
    // (such as a form control's 'ng-touched') will cause a changed-after-checked error.
    // To work around this, defer telling the form control it has been touched until the next tick.
    Promise.resolve().then(() => {
      this._onTouched()
      this.changeDetectorRef.markForCheck()
      this.blur.emit(event)
    })
  }

  // Transforms the internal string value for output. Must output <T>
  protected abstract _transformOutputValue(value: string): TOutputValue
}
