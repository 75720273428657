<!-- <div class="bui-pagination-size"></div> -->

<div class="bui-pagination-info">{{ getInfoText() }}</div>

<div class="bui-pagination-form">
  <bui-button
    icon="chevron-left"
    [disabled]="isPreviousButtonDisabled()"
    (click)="goToPreviousPage()"
  ></bui-button>
  <span class="bui-pagination-form-text bui-pagination-form-text--page">
    Page
  </span>
  <bui-input-number
    type="integer"
    [class.digits-3]="totalPagesCount > 99 && totalPagesCount < 1000"
    [class.digits-4]="totalPagesCount > 999"
    [muteStyles]="true"
    [formControl]="activePageFormControl"
    [min]="1"
    [max]="totalPagesCount"
    size="extra-small"
  ></bui-input-number>
  <span class="bui-pagination-form-text">of {{ totalPagesCount }}</span>
  <bui-button
    icon="chevron-right"
    [disabled]="isNextButtonDisabled()"
    (click)="goToNextPage()"
  ></bui-button>
</div>
