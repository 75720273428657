import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { OverlayModule } from '@angular/cdk/overlay'
import { BuiButtonModule } from '../bui-button'
import { BuiButtonToggleModule } from '../bui-button-toggle'
import { BuiIconModule } from '../bui-icon'
import { BuiInputModule } from '../bui-input'
import { BuiLabelModule } from '../bui-label'
import { _BuiDatepickerDayComponent } from './bui-datepicker-day/bui-datepicker-day.component'
import { _BuiDatepickerMonthComponent } from './bui-datepicker-month/bui-datepicker-month.component'
import { _BuiDateRangeInputComponent } from './bui-date-range-input/bui-date-range-input.component'
import { _BuiDateSingleInputComponent } from './bui-date-single-input/bui-date-single-input.component'
import { _BuiDateTimeInputComponent } from './bui-date-time-input/bui-date-time-input.component'
import { _BuiDatepickerTimeComponent } from './bui-datepicker-time/bui-datepicker-time.component'

@NgModule({
  declarations: [
    _BuiDatepickerDayComponent,
    _BuiDatepickerMonthComponent,
    _BuiDatepickerTimeComponent,
    _BuiDateRangeInputComponent,
    _BuiDateSingleInputComponent,
    _BuiDateTimeInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    BuiButtonModule,
    BuiButtonToggleModule,
    BuiIconModule,
    BuiInputModule,
    BuiLabelModule,
  ],
  exports: [
    _BuiDateRangeInputComponent,
    _BuiDateSingleInputComponent,
    _BuiDateTimeInputComponent,
  ],
})
export class BuiDatepickerModule {}
