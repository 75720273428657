import { BuiCustomCssProperty } from './bui-custom-css-property.models'

export function getBuiCustomCssPropertyValue(
  propertyName: BuiCustomCssProperty
): string {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(propertyName)
    .trim()

  if (!value) {
    console.error(`Unknown BUI CSS Custom Property: '${propertyName}'`)
  }

  return value
}
