import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  _BuiListCellDirective,
  _BuiListComponent,
  _BuiListRowDirective,
  _BuiListRowSpacerDirective,
} from './bui-list.component'

@NgModule({
  declarations: [
    _BuiListComponent,
    _BuiListRowDirective,
    _BuiListRowSpacerDirective,
    _BuiListCellDirective,
  ],
  imports: [CommonModule],
  exports: [
    _BuiListComponent,
    _BuiListRowDirective,
    _BuiListRowSpacerDirective,
    _BuiListCellDirective,
  ],
})
export class BuiListModule {}
