import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import {
  BuiDatepickerType,
  _BuiDatepickerMonthPanelType,
  _BuiDatepickerRangeActiveSelection,
} from './bui-datepicker.models'
import { isBefore, isSameDay, isValid, isWithinInterval, parse } from 'date-fns'

type MonthPanelsState = {
  [key in _BuiDatepickerMonthPanelType]: Date | null
}

type ActiveSelection = Date | _BuiDatepickerRangeActiveSelection

const INITIAL_MONTH_PANELS_STATE: MonthPanelsState = {
  panelStart: null,
  panelEnd: null,
  panelSingle: null,
}

@Injectable()
export class _BuiDatepickerInternalService {
  // Stream to notify bui-datepicker-month & bui-datepicker-day
  // components that UI needs updating
  private readonly uiStateChangesSubject = new BehaviorSubject<void>(null)
  readonly uiStateChanges$ = this.uiStateChangesSubject.asObservable()

  private monthPanelsState$ = new BehaviorSubject<MonthPanelsState>(
    INITIAL_MONTH_PANELS_STATE
  )

  private timeChangedSubject$ = new Subject<Date>()
  readonly timeChanged$ = this.timeChangedSubject$.asObservable()

  private dayClickedSubject$ = new Subject<Date>()
  readonly dayClicked$ = this.dayClickedSubject$.asObservable()

  // activeDateSelection reflects active date selection(s) in the panel
  // which can differ from ngControl.value (user needs to apply the changes)
  set activeDateSelection(value: ActiveSelection) {
    this._activeDateSelection = value
  }
  private _activeDateSelection: ActiveSelection

  datepickerType: BuiDatepickerType
  dateFormat = 'yyyyMMdd'
  dateDisplayFormat = 'MMM dd, yyyy'
  timeDisplayFormat = 'h:mm aa'
  disabledDates: string[] = []
  untouchedSincePanelOpened = false

  private _hoveredOnDay: Date = null

  destroy(): void {
    this.timeChangedSubject$.complete()
    this.dayClickedSubject$.complete()
    this.monthPanelsState$.complete()
    this.uiStateChangesSubject.complete()
  }

  getActiveDateSelection(): Date | null {
    return this._isDate(this._activeDateSelection)
      ? this._activeDateSelection
      : null
  }

  setActiveHoveredDay(day: Date): void {
    this._hoveredOnDay = day
    this.uiStateChangesSubject.next()
  }

  onDayClick(date: Date): void {
    this.dayClickedSubject$.next(date)
  }

  onTimeChange(time: string): void {
    const date = parse(time, this.timeDisplayFormat, new Date())

    if (isValid(date)) {
      this.timeChangedSubject$.next(date)
    }
  }

  triggerUIStateChange(): void {
    this.uiStateChangesSubject.next()
  }

  setMonthPanelDate(
    panelType: _BuiDatepickerMonthPanelType,
    month: Date
  ): void {
    const state = this.monthPanelsState$.value
    this.monthPanelsState$.next({ ...state, [panelType]: month })
    this.uiStateChangesSubject.next()
  }

  getMonthPanelDate(panelType: _BuiDatepickerMonthPanelType): Date {
    return this.monthPanelsState$.value[panelType]
  }

  // bui-datepicker-day state helper
  isSelectedDate(date: Date): boolean {
    if (this._isDateRangePicker(this._activeDateSelection)) {
      const fromDate = this._activeDateSelection.fromDate
      const toDate = this._activeDateSelection.toDate
      return (
        (fromDate && isSameDay(fromDate, date)) ||
        (toDate && isSameDay(toDate, date))
      )
    }
    return isSameDay(this._activeDateSelection, date)
  }

  // bui-datepicker-day state helper
  isSelectedFromDate(date: Date): boolean {
    if (this._isDateRangePicker(this._activeDateSelection)) {
      const fromDate = this._activeDateSelection.fromDate
      return fromDate && isSameDay(fromDate, date)
    }
    return false
  }

  // bui-datepicker-day state helper
  isSelectedToDate(date: Date): boolean {
    if (this._isDateRangePicker(this._activeDateSelection)) {
      const toDate = this._activeDateSelection.toDate
      return toDate && isSameDay(toDate, date)
    }
    return false
  }

  // bui-datepicker-day state helper
  isInSelectedRange(date: Date): boolean {
    if (this._isDateRangePicker(this._activeDateSelection)) {
      const fromDate = this._activeDateSelection.fromDate
      const toDate = this._activeDateSelection.toDate

      return fromDate && toDate
        ? isWithinInterval(date, { start: fromDate, end: toDate })
        : false
    }
    return false
  }

  // bui-datepicker-day state helper
  isInHoveredRange(date: Date, minDate: Date, maxDate: Date): boolean {
    if (
      this._isDateRangePicker(this._activeDateSelection) &&
      !this.untouchedSincePanelOpened
    ) {
      const fromDate = this._activeDateSelection.fromDate
      const toDate = this._activeDateSelection.toDate

      if (fromDate && !toDate && this._hoveredOnDay) {
        if (isBefore(fromDate, this._hoveredOnDay)) {
          let end = this._hoveredOnDay

          if (maxDate) {
            end = isBefore(this._hoveredOnDay, maxDate)
              ? this._hoveredOnDay
              : maxDate
          }

          return isWithinInterval(date, { start: fromDate, end })
        }

        let start = this._hoveredOnDay

        if (minDate) {
          start = isBefore(minDate, this._hoveredOnDay)
            ? this._hoveredOnDay
            : minDate
        }

        return isWithinInterval(date, { start, end: fromDate })
      }
    }

    return false
  }

  // bui-datepicker-day state helper
  shouldSetActiveHoveredDay(date: Date): boolean {
    if (this._isDateRangePicker(this._activeDateSelection)) {
      const hasFromDate = !!this._activeDateSelection.fromDate
      const isMissingToDate = !this._activeDateSelection.toDate

      if (
        hasFromDate &&
        isMissingToDate &&
        (!this._hoveredOnDay || !isSameDay(this._hoveredOnDay, date))
      ) {
        return true
      }
    }

    return false
  }

  private _isDateRangePicker(
    activeDateSelection: ActiveSelection
  ): activeDateSelection is _BuiDatepickerRangeActiveSelection {
    return (
      this.datepickerType === 'range' &&
      activeDateSelection instanceof _BuiDatepickerRangeActiveSelection
    )
  }

  private _isDate(
    activeDateSelection: ActiveSelection
  ): activeDateSelection is Date {
    return activeDateSelection instanceof Date
  }
}
