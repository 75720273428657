import {
  Component,
  ViewChild,
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core'
import {
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
  BasePortalOutlet,
} from '@angular/cdk/portal'
import { AnimationEvent } from '@angular/animations'
import { _buiModalAnimation } from './bui-modal-container.animation'
import { BuiModalConfig } from '../bui-modal-config'

const BASE_CSS_CLASS = 'bui-modal-container'

@Component({
  template: `<div cdkTrapFocus cdkTrapFocusAutoCapture>
    <ng-template cdkPortalOutlet></ng-template>
  </div>`,
  styleUrls: ['./bui-modal-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [_buiModalAnimation],
})
export class _BuiModalContainerComponent extends BasePortalOutlet {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet

  @HostBinding('@buiModalAnimation') get modalAnimation() {
    return this.animationState
  }

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.config.containerClass) {
      classes.push(`${this.config.containerClass}`)
    }

    return classes
  }

  public animationState: 'void' | 'enter' | 'exit' = 'enter'
  public animationStateChanged = new EventEmitter<AnimationEvent>()

  constructor(public config: BuiModalConfig) {
    super()
  }

  @HostListener('@buiModalAnimation.start', ['$event']) onAnimationStart(
    event: AnimationEvent
  ) {
    this.animationStateChanged.emit(event)
  }

  @HostListener('@buiModalAnimation.done', ['$event']) onAnimationDone(
    event: AnimationEvent
  ) {
    this.animationStateChanged.emit(event)
  }

  public attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this.portalOutlet.hasAttached()) {
      throw new Error(
        'Attempting to attach modal content after content is already attached'
      )
    }

    return this.portalOutlet.attachComponentPortal(portal)
  }

  public attachTemplatePortal<C>(
    portal: TemplatePortal<C>
  ): EmbeddedViewRef<C> {
    if (this.portalOutlet.hasAttached()) {
      throw new Error(
        'Attempting to attach modal content after content is already attached'
      )
    }

    return this.portalOutlet.attachTemplatePortal(portal)
  }

  public startExitAnimation(): void {
    this.animationState = 'exit'
  }
}
