import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  booleanAttribute,
} from '@angular/core'
import { BuiIconType } from '../bui-icon'
import { BuiSize } from '../bui-size'

export const BuiButtonType = ['button', 'submit', 'reset'] as const
export type BuiButtonType = (typeof BuiButtonType)[number]

export const BuiButtonVariant = [
  'primary',
  'secondary',
  'utility',
  'destructive',
  'destructive-outline',
  'text',
  'text-brand',
  'phantom',
  'table',
  'icon',
  'success',
] as const
export type BuiButtonVariant = (typeof BuiButtonVariant)[number]

@Component({
  selector: 'bui-button',
  templateUrl: './bui-button.component.html',
  styleUrls: ['./bui-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiButtonComponent {
  @Input() type: BuiButtonType = 'button'
  @Input() variant: BuiButtonVariant = 'utility'
  @Input() size: BuiSize = 'regular'
  @Input() loading = false
  @Input() route: string
  @Input() queryParams: object = null
  @Input() prefixIcon: BuiIconType
  @Input() suffixIcon: BuiIconType
  @Input({ transform: booleanAttribute }) disabled = false

  @Input() set icon(value: BuiIconType) {
    this.variant = 'icon'
    this._icon = value
  }
  public get icon(): BuiIconType {
    return this._icon
  }
  private _icon: BuiIconType

  @HostBinding('class') get classes() {
    let classes = `bui-button bui-button--${this.variant} bui-button--${this.size}`

    if (this.loading) {
      classes += ' bui-button--loading'
    }
    if (this.disabled && !this.loading) {
      classes += ' bui-button--disabled'
    }
    if (this.icon) {
      classes += ` bui-button--${this.variant}-${this.icon}`
    }

    return classes
  }

  onButtonElementClick(event: Event) {
    if (this.loading || this.disabled) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
