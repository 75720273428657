import { animate, style, transition, trigger } from '@angular/animations'

export const buiFormFieldErrorAnimation = trigger('slideIn', [
  transition(':enter', [
    style({
      bottom: '16px',
      opacity: 0,
    }),
    animate(
      '300ms',
      style({
        bottom: '8px',
        opacity: 1,
      })
    ),
  ]),
])
