<div class="bui-form-field__label">
  <ng-content select="bui-label"></ng-content>
</div>

<div class="bui-form-field__control">
  <ng-content></ng-content>
</div>

<div class="bui-form-field__error" *ngIf="!isErrorHidden">
  <ng-content select="bui-form-field-error"></ng-content>
</div>
