import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { BuiIconType } from '../bui-icon'
import { _BuiInputBaseComponent } from './bui-input-base.component'
import { provideNgxMask } from 'ngx-mask'

@Component({
  selector: 'bui-input-text',
  template: `<input
      *ngIf="mask; else noMask"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [apm]="isTimeValueInput ? true : null"
      [type]="type"
      [formControl]="_formControl"
      [attr.placeholder]="placeholder"
      [buiInputAutofocus]="autofocus"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
    />
    <ng-template #noMask>
      <input
        [type]="type"
        [formControl]="_formControl"
        [attr.placeholder]="placeholder"
        [buiInputAutofocus]="autofocus"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)"
      />
    </ng-template>
    <bui-icon
      *ngIf="prefixIcon"
      [name]="prefixIcon"
      class="bui-input-prefix"
    ></bui-icon>`,
  styleUrls: ['./bui-input-base.component.scss'],
  providers: [
    provideNgxMask(),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => _BuiInputTextComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiInputTextComponent extends _BuiInputBaseComponent<string> {
  @Input() type: 'text' | 'email' | 'password' = 'text'
  @Input() prefixIcon: BuiIconType

  // ngx-mask properties
  @Input() mask: string
  @Input() dropSpecialCharacters = true

  @HostBinding('class.bui-input--has-prefix-icon')
  get hasPrefixIcon(): boolean {
    return !!this.prefixIcon
  }

  // ngx-mask setting
  // used to force the 12-hour time format (AM/PM)
  // if the mask string formats hours
  get isTimeValueInput(): boolean {
    return this.mask?.includes('Hh')
  }

  protected _transformOutputValue(value: string): string {
    return value
  }
}
