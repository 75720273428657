import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnInit,
  inject,
} from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import {
  addDays,
  addMonths,
  format,
  isSameMonth,
  setDay,
  startOfMonth,
  subMonths,
} from 'date-fns'
import {
  _BuiDatepickerMonthPanelType,
  _BuiDatepickerDaysMap,
  _BuiDatepickerWeek,
} from '../bui-datepicker.models'
import { injectDestroy } from '../../util'
import { _BuiDatepickerInternalService } from '../bui-datepicker-internal.service'

@Component({
  selector: 'bui-datepicker-month',
  templateUrl: 'bui-datepicker-month.component.html',
  styleUrls: ['bui-datepicker-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDatepickerMonthComponent implements OnInit {
  protected readonly destroy$ = injectDestroy()

  private readonly internalService = inject(_BuiDatepickerInternalService)
  private readonly changeDetectorRef = inject(ChangeDetectorRef)

  @Input({ required: true }) panelType: _BuiDatepickerMonthPanelType

  // minDate & maxDate are just passing through to bui-datepicker-day
  @Input({ required: true }) minDate: Date | string
  @Input({ required: true }) maxDate: Date | string

  readonly daysMap = _BuiDatepickerDaysMap
  weeks: _BuiDatepickerWeek[] = []

  get showNextButton() {
    if (this.panelType === 'panelStart') {
      return !isSameMonth(addMonths(this.startMonth, 1), this.endMonth)
    }
    return true
  }

  get showPreviousButton() {
    if (this.panelType === 'panelEnd') {
      return !isSameMonth(subMonths(this.activeMonth, 1), this.startMonth)
    }
    return true
  }

  get label(): string {
    return format(this.activeMonth, 'MMMM yyyy')
  }

  private get startMonth(): Date {
    return this.internalService.getMonthPanelDate('panelStart')
  }

  private get endMonth(): Date {
    return this.internalService.getMonthPanelDate('panelEnd')
  }

  private get activeMonth(): Date {
    if (
      this.panelType === 'panelEnd' &&
      isSameMonth(this.startMonth, this.endMonth)
    ) {
      this._setMonthPanelDate('panelEnd', addMonths(this.endMonth, 1))
    }
    return this.internalService.getMonthPanelDate(this.panelType)
  }

  ngOnInit(): void {
    this.internalService.uiStateChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.weeks = this.getWeeks(this.activeMonth)
        this.changeDetectorRef.markForCheck()
      })
  }

  onPreviousMonth(): void {
    this._setMonthPanelDate(this.panelType, subMonths(this.activeMonth, 1))
  }

  onNextMonth(): void {
    this._setMonthPanelDate(this.panelType, addMonths(this.activeMonth, 1))
  }

  onTableMouseleave(): void {
    this.internalService.setActiveHoveredDay(null)
  }

  private _setMonthPanelDate(
    panelType: _BuiDatepickerMonthPanelType,
    month: Date
  ): void {
    this.internalService.setMonthPanelDate(panelType, month)
  }

  private getWeeks(selectedMonth: Date): _BuiDatepickerWeek[] {
    let current = startOfMonth(selectedMonth)
    const weeks: _BuiDatepickerWeek[] = []

    for (let i = 0; i < 6; i++) {
      const week: Partial<_BuiDatepickerWeek> = {}
      for (let j = 0; j < 7; j++) {
        current = setDay(current, j)
        const dayOfMonth = current.getDate()

        week[this._getDay(j)] = {
          date: current,
          dayOfMonth,
        }
        if (j < 6) {
          current = new Date(current)
        } else {
          current = addDays(current, 1)
        }
      }
      weeks.push(week as _BuiDatepickerWeek)
    }

    return weeks
  }

  private _getDay(dayIndex: number): keyof _BuiDatepickerWeek {
    return this.daysMap[dayIndex]
  }
}
