// Based on Angular Material Radio v15
/* eslint-disable @typescript-eslint/no-empty-function */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  HostBinding,
  InjectionToken,
  Input,
  Output,
  QueryList,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  _BuiRadioButtonComponent,
  _BuiRadioButtonBase,
} from './bui-radio.component'
import { BuiRadioChange } from './bui-radio.models'

let nextUniqueId = 0

/**
 * Injection token that can be used to inject instances of `BuiRadioGroup`. It serves as
 * alternative token to the actual `BuiRadioGroup` class which could cause unnecessary
 * retention of the class and its component metadata.
 */
export const BUI_RADIO_GROUP = new InjectionToken<
  _BuiRadioGroupBase<_BuiRadioButtonBase>
>('BuiRadioGroup')

// Base class with all of the `BuiRadioGroup` functionality.
@Directive()
export abstract class _BuiRadioGroupBase<T extends _BuiRadioButtonBase>
  implements AfterContentInit, ControlValueAccessor
{
  // Whether the `value` has been set to its initial value
  private _isInitialized = false

  // Event emitted when the group value changes.
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change = new EventEmitter<BuiRadioChange>()

  // Child radio buttons
  abstract _radios: QueryList<T>

  // Name of the radio button group. All radio buttons inside this group will use this name
  private _name = `bui-radio-group-${nextUniqueId++}`
  @Input()
  get name(): string {
    return this._name
  }
  set name(value: string) {
    this._name = value
    this._updateRadioButtonNames()
  }

  // Whether the labels should appear after or before the radio-buttons. Defaults to 'after'
  private _labelPosition: 'before' | 'after' = 'after'
  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition
  }
  set labelPosition(value: 'before' | 'after') {
    this._labelPosition = value
    this._markRadiosForCheck()
  }

  // Selected value for the radio group
  private _value: any = null
  @Input()
  get value(): any {
    return this._value
  }
  set value(newValue: any) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue

      this._updateSelectedRadioFromValue()
      this._checkSelectedRadioButton()
    }
  }

  // The currently selected radio button. Should match value.
  private _selected: T | null = null
  @Input()
  get selected() {
    return this._selected
  }
  set selected(selected: T | null) {
    this._selected = selected
    this.value = selected ? selected.value : null
    this._checkSelectedRadioButton()
  }

  // Whether the radio group is disabled
  private _disabled = false
  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
    this._markRadiosForCheck()
  }

  // Whether the radio group is required
  private _required = false
  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value)
    this._markRadiosForCheck()
  }

  // Whether to show radio buttons as inline elements
  // or as block ('list') elements
  @Input() variant: 'list' | 'inline' = 'list'

  constructor(private _changeDetector: ChangeDetectorRef) {}

  _checkSelectedRadioButton() {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true
    }
  }

  // Initialize properties once content children are available.
  // This allows propagation of relevant attributes to associated buttons.
  ngAfterContentInit() {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on BuiRadioGroup, and it is possible that the OnInit of the
    // NgModel occurs *after* the OnInit of the BuiRadioGroup.
    this._isInitialized = true
  }

  // The method to be called in order to update ngModel
  _controlValueAccessorChangeFn: (value: any) => void = () => {}

  // onTouch function registered via registerOnTouch (ControlValueAccessor)
  onTouched: () => any = () => {}

  // Mark this group as being "touched" (for ngModel). Meant to be called by the contained
  // radio buttons upon their blur.
  _touch() {
    if (this.onTouched) {
      this.onTouched()
    }
  }

  private _updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach((radio) => {
        radio.name = this.name
        radio._markForCheck()
      })
    }
  }

  // Updates the `selected` radio button from the internal _value state
  private _updateSelectedRadioFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected =
      this._selected !== null && this._selected.value === this._value

    if (this._radios && !isAlreadySelected) {
      this._selected = null
      this._radios.forEach((radio) => {
        radio.checked = this.value === radio.value
        if (radio.checked) {
          this._selected = radio
        }
      })
    }
  }

  // Dispatch change event with current selection and group value
  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.change.emit(new BuiRadioChange(this._selected, this._value))
    }
  }

  _markRadiosForCheck() {
    if (this._radios) {
      this._radios.forEach((radio) => radio._markForCheck())
    }
  }

  // Sets the model value. Implemented as part of ControlValueAccessor.
  writeValue(value: any) {
    this.value = value
    this._changeDetector.markForCheck()
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
    this._changeDetector.markForCheck()
  }
}

// A group of radio buttons. May contain one or more `<bui-radio>` elements
@Directive({
  selector: 'bui-radio-group',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => _BuiRadioGroupDirective),
      multi: true,
    },
    { provide: BUI_RADIO_GROUP, useExisting: _BuiRadioGroupDirective },
  ],
})
export class _BuiRadioGroupDirective extends _BuiRadioGroupBase<_BuiRadioButtonComponent> {
  @HostBinding('class.bui-radio-group') private readonly buiClass = true
  @HostBinding('role') get getRole() {
    return 'radiogroup'
  }
  // Child radio buttons
  @ContentChildren(forwardRef(() => _BuiRadioButtonComponent), {
    descendants: true,
  })
  _radios: QueryList<_BuiRadioButtonComponent>
}
