import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { filter, map, merge, Subject } from 'rxjs'
import { getBuiCustomCssPropertyValue } from '../bui-custom-css-property'
import { BuiEventsService } from '../bui-events'
import { BuiGalleryDirection, BuiGallerySlide } from './bui-gallery.models'

@Component({
  selector: 'bui-gallery',
  templateUrl: './bui-gallery.component.html',
  styleUrls: ['./bui-gallery.component.scss'],
})
export class _BuiGalleryComponent {
  @ViewChildren('slideImages') slideImages: QueryList<
    ElementRef<HTMLImageElement>
  >
  public closeOverlayEvent = new EventEmitter<void>()

  @Input() isOverlay = false
  @Input() title: string
  @Input() slides: BuiGallerySlide[] = []
  @Input() set startIndex(value: number) {
    this.activeSlideIndex = value
  }
  @HostBinding('class') get classes() {
    let classes = 'bui-gallery'

    classes += this.isOverlay
      ? ' bui-gallery--is-overlay'
      : ' bui-gallery--not-overlay'

    if (this.title) {
      classes += ' bui-gallery--has-title'
    }

    return classes
  }

  private activeSlideIndexChanged$ = new Subject<number>()
  private _activeSlideIndex = 0
  private set activeSlideIndex(value: number) {
    this._activeSlideIndex = value
    this.activeSlideIndexChanged$.next(value)
  }
  public get activeSlideIndex(): number {
    return this._activeSlideIndex
  }

  private loadedImages: boolean[] = []
  private imageLoaded$ = new Subject<number>()

  private recalculate$ = merge(
    this.activeSlideIndexChanged$,
    this.imageLoaded$.pipe(
      filter((imageIndex) => imageIndex === this.activeSlideIndex)
    ),
    this.buiEventsService.windowResize$
  )
  public activeSlideWidth$ = this.recalculate$.pipe(
    map(() => this.activeSlideWidth)
  )
  public activeSlideHeight$ = this.recalculate$.pipe(
    map(() => this.activeSlideHeight)
  )

  private get activeSlideWidth(): string {
    const isHeightConstrained = this.activeImageRatio > this.hostRatio
    if (isHeightConstrained) {
      return `calc(${this.activeSlideHeight} / ${this.activeImageRatio})`
    }
    return `min(${this.maxOverlayWidth}, ${this.activeImageNaturalWidth}px)`
  }

  private get activeSlideHeight(): string {
    return `min(${this.maxOverlayHeight}, min(${this.maxOverlayWidth}, ${this.activeImageNaturalWidth}px) * ${this.activeImageRatio})`
  }

  private get activeImageRatio(): number {
    const height = this.activeImageNaturalHeight
    const width = this.activeImageNaturalWidth
    return width === 0 ? 0 : height / width
  }

  private get activeImageNaturalWidth(): number {
    const image = this.slideImages?.get(this.activeSlideIndex)
    return image?.nativeElement.naturalWidth || 0
  }

  private get activeImageNaturalHeight(): number {
    const image = this.slideImages?.get(this.activeSlideIndex)
    return image?.nativeElement.naturalHeight || 0
  }

  private get hostRatio(): number {
    const height = this.hostElementRef.nativeElement.offsetHeight
    const width = this.hostElementRef.nativeElement.offsetWidth
    return height / width
  }

  private get maxOverlayWidth(): string {
    return getBuiCustomCssPropertyValue('--bui-gallery-max-overlay-width')
  }

  private get maxOverlayHeight(): string {
    return getBuiCustomCssPropertyValue('--bui-gallery-max-overlay-height')
  }

  constructor(
    private hostElementRef: ElementRef,
    private buiEventsService: BuiEventsService
  ) {}

  private moveSlider(direction: BuiGalleryDirection) {
    if (direction === 'next') {
      if (this.activeSlideIndex >= this.slides.length - 1) {
        this.activeSlideIndex = 0
      } else {
        this.activeSlideIndex++
      }
    } else {
      if (this.activeSlideIndex <= 0) {
        this.activeSlideIndex = this.slides.length - 1
      } else {
        this.activeSlideIndex--
      }
    }
  }

  public isLoading(imageIndex: number): boolean {
    return !this.loadedImages[imageIndex]
  }

  public onImageLoad(imageIndex: number): void {
    this.loadedImages[imageIndex] = true
    this.imageLoaded$.next(imageIndex)
  }

  public goToPrev() {
    this.moveSlider('prev')
  }

  public goToNext() {
    this.moveSlider('next')
  }

  public closeOverlay(): void {
    this.closeOverlayEvent.emit()
  }
}
