<div class="bui-datepicker-month">
  <div class="bui-datepicker-month__nav">
    <bui-button
      icon="chevron-left"
      [style.visibility]="showPreviousButton ? 'visible' : 'hidden'"
      (click)="onPreviousMonth()"
    ></bui-button>
    <span class="bui-datepicker-month__nav__label">{{ label }}</span>
    <bui-button
      icon="chevron-right"
      [style.visibility]="showNextButton ? 'visible' : 'hidden'"
      (click)="onNextMonth()"
    ></bui-button>
  </div>
  <table class="bui-datepicker-month__table">
    <thead>
      <tr>
        <th *ngFor="let dayOfTheWeek of daysMap">
          {{ dayOfTheWeek.slice(0, 1) }}
        </th>
      </tr>
    </thead>
    <tbody (mouseleave)="onTableMouseleave()">
      <tr *ngFor="let week of weeks">
        <td *ngFor="let dayOfTheWeek of daysMap">
          <bui-datepicker-day
            [panelType]="panelType"
            [day]="week[dayOfTheWeek]"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></bui-datepicker-day>
        </td>
      </tr>
    </tbody>
  </table>
</div>
