import {
  Directive,
  Input,
  ElementRef,
  ViewContainerRef,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  HostBinding,
  ChangeDetectorRef,
  booleanAttribute,
} from '@angular/core'
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { merge, Subscription } from 'rxjs'
import { _BuiDropdownComponent } from './bui-dropdown.component'
import { BuiPanelPosition, getPanelPositions } from '../bui-panel-position'

const BASE_CSS_CLASS = 'bui-dropdown-trigger'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[buiDropdownTrigger]',
})
export class _BuiDropdownTriggerDirective implements OnDestroy {
  @Input('buiDropdownTrigger')
  set dropdown(dropdown: _BuiDropdownComponent) {
    if (this.dropdown === dropdown) {
      return
    }
    this._dropdown = dropdown
  }
  get dropdown() {
    return this._dropdown
  }
  private _dropdown: _BuiDropdownComponent

  @Input({ transform: booleanAttribute }) disabled = false

  @Input() buiDropdownPanelPosition: BuiPanelPosition = 'end'

  private _isDropdownOpen = false
  private _overlayRef: OverlayRef | null = null
  private _portal: TemplatePortal
  private _closeActionsSubscription = Subscription.EMPTY

  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this._isDropdownOpen) {
      classes.push(`${BASE_CSS_CLASS}--open`)
    }

    return classes
  }

  @Output() readonly dropdownOpened = new EventEmitter<void>()
  @Output() readonly dropdownClosed = new EventEmitter<void>()

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostListener('click') onClick() {
    this.toggle()
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose()
      this._overlayRef = null
    }
  }

  public toggle(): void {
    if (this.disabled) {
      return
    }
    this._isDropdownOpen ? this.closeDropdown() : this.openDropdown()
  }

  public openDropdown(): void {
    if (this._isDropdownOpen) {
      return
    }

    this._createOverlay()

    this._closeActionsSubscription = merge(
      this._overlayRef.backdropClick(),
      this._overlayRef.detachments(),
      this.dropdown.closed
    ).subscribe(() => this.closeDropdown())

    this._isDropdownOpen = true
    this.dropdownOpened.emit()
    this._attachOverlay()
    this._changeDetectorRef.markForCheck()
  }

  public closeDropdown(): void {
    if (!this._overlayRef || !this._isDropdownOpen) {
      return
    }
    this._isDropdownOpen = false
    this.dropdownClosed.emit()
    this._overlayRef.detach()
    this._closeActionsSubscription.unsubscribe()
    this._changeDetectorRef.markForCheck()
  }

  private _attachOverlay(): void {
    this._overlayRef.attach(this._getPortal())
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const config = this._getOverlayConfig()
      this._overlayRef = this._overlay.create(config)
    }
    return this._overlayRef
  }

  private _getPortal(): TemplatePortal {
    if (!this._portal) {
      this._portal = new TemplatePortal(
        this.dropdown.templateRef,
        this._viewContainerRef
      )
    }
    return this._portal
  }

  private _getOverlayConfig(): OverlayConfig {
    const panelPositions = getPanelPositions(this.buiDropdownPanelPosition)

    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef)
        .withPositions(panelPositions),
      panelClass: this.dropdown.panelClass,
    })
  }
}
