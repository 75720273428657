// Based on Angular Material Slider v15

import { InjectionToken } from '@angular/core'

// Thumb types: range slider has two thumbs (START, END) whereas single point
// slider only has one thumb (END).
export const enum _BuiThumbPosition {
  START = 1,
  END = 2,
}

// Injection token that can be used for a `BuiSlider` to provide itself as a
// parent to the `BuiSliderInput` and `BuiSliderRangeInput`.
// Used primarily to avoid circular imports.
export const BUI_SLIDER = new InjectionToken<{}>('_BuiSlider')

// Injection token that can be used to query for a `BuiSliderInput`.
// Used primarily to avoid circular imports.
export const BUI_SLIDER_INPUT = new InjectionToken<{}>('_BuiSliderInput')

// Injection token that can be used to query for a `BuiSliderRangeInput`.
// Used primarily to avoid circular imports.
export const BUI_SLIDER_RANGE_INPUT = new InjectionToken<{}>(
  '_BuiSliderRangeInput'
)

// Injection token that can be used to query for a `BuiSliderThumb`.
// Used primarily to avoid circular imports.
export const BUI_SLIDER_THUMB = new InjectionToken<{}>('_BuiSliderThumb')

// Represents a drag event emitted by the BuiSlider component
export interface BuiSliderDragEvent {
  // The BuiSliderThumb that was interacted with
  source: _BuiSliderInput

  // The BuiSlider that was interacted with
  parent: _BuiSlider

  // The current value of the slider
  value: number
}

export interface _BuiSlider {
  // Gets the slider thumb input of the given thumb position
  _getInput(
    thumbPosition: _BuiThumbPosition
  ): _BuiSliderInput | _BuiSliderRangeInput | undefined

  // The minimum value that the slider can have
  min: number

  // The maximum value that the slider can have
  max: number

  // The amount that slider values can increment or decrement by
  step: number

  // Whether the slider is disabled
  disabled: boolean

  // Whether the slider is a range slider
  _isRange: boolean

  // The stored width of the host element's bounding client rect
  _cachedWidth: number

  // The stored width of the host element's bounding client rect
  _cachedLeft: number

  // Triggers UI updates that are needed after a slider input value has changed
  _onValueChange: (source: _BuiSliderInput) => void

  // Triggers UI updates that are needed after the slider thumb position has changed
  _onTranslateXChange: (source: _BuiSliderInput) => void

  // Updates the stored slider dimensions using the current bounding client rect
  _updateDimensions: () => void
}

export interface _BuiSliderInput {
  // The minimum value that the slider can have
  min: number

  // The maximum value that the slider can have
  max: number

  // The amount that slider values can increment or decrement by
  step: number

  // The current value of this slider input
  value: number

  // The current translateX in px of the slider visual thumb
  translateX: number

  // Indicates whether this thumb is the start or end thumb
  thumbPosition: _BuiThumbPosition

  // Similar to percentage but calcualted using translateX relative to the total track width
  fillPercentage: number

  // Whether the slider is disabled
  disabled: boolean

  // The host native HTML input element
  _hostElement: HTMLInputElement

  // Whether the input is currently focused (either by tab or after clicking)
  _isFocused: boolean

  // The aria-valuetext string representation of the input's value
  _valuetext: string

  // Indicates whether UI updates should be skipped.
  // This flag is used to avoid flickering when correcting values on pointer up/down.
  _skipUIUpdate: boolean

  // Handles the initialization of properties for the slider input
  initProps: () => void

  // Handles UI initialization controlled by this slider input
  initUI: () => void

  // Calculates the visual thumb's translateX based on the slider input's current value
  _calcTranslateXByValue: () => number

  // Updates the visual thumb based on the slider input's current value
  _updateThumbUIByValue: () => void

  // Sets the slider input to disproportionate dimensions to allow for touch events to be captured on touch devices.
  _updateWidthInactive: () => void

  // Used to set the slider width to the correct dimensions while the user is dragging.
  _updateWidthActive: () => void
}

export interface _BuiSliderRangeInput extends _BuiSliderInput {
  // Whether this slider corresponds to the input on the left hand side
  _isLeftThumb: boolean

  // Gets the sibling BuiSliderRangeThumb.
  // Returns undefined if it is too early in Angular's life cycle.
  getSibling: () => _BuiSliderRangeInput | undefined

  // Used to cache whether this slider input corresponds to the visual left thumb
  _setIsLeftThumb: () => void

  // Updates the input styles to control whether it is pinned to the start or end of the bui-slider
  _updateStaticStyles: () => void

  // Updates the min and max properties of this slider input according to it's sibling
  _updateMinMax: () => void
}

export interface _BuiSliderThumb {
  // Whether the slider thumb is currently being pressed
  _isActive: boolean

  // The host native HTML input element
  _hostElement: HTMLElement

  // Sets whether the slider thumb is currently on the bottom
  setIsOverlapping: (isOverlapping: boolean) => void
}
