import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostBinding,
  HostListener,
  inject,
} from '@angular/core'
import { fromEvent } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isValid,
  parse,
} from 'date-fns'
import {
  _BuiDatepickerMonthPanelType,
  _BuiDatepickerDay,
} from '../bui-datepicker.models'
import { injectDestroy } from '../../util'
import { _BuiDatepickerInternalService } from '../bui-datepicker-internal.service'

const BASE_CSS_CLASS = 'bui-datepicker-day'

@Component({
  selector: 'bui-datepicker-day',
  templateUrl: 'bui-datepicker-day.component.html',
  styleUrls: ['bui-datepicker-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDatepickerDayComponent implements AfterViewInit {
  protected readonly destroy$ = injectDestroy()

  private readonly internalService = inject(_BuiDatepickerInternalService)
  private readonly changeDetectorRef = inject(ChangeDetectorRef)
  private readonly ngZone = inject(NgZone)

  @Input() day: _BuiDatepickerDay
  @Input() panelType: _BuiDatepickerMonthPanelType

  @Input({ required: true }) set minDate(value: Date | string) {
    const parsedDate = this._isDate(value)
      ? value
      : parse(value, this.dateFormat, new Date())
    this._minDate = isValid(parsedDate) ? parsedDate : null
  }
  private _minDate: Date

  @Input({ required: true }) set maxDate(value: Date | string) {
    const parsedDate = this._isDate(value)
      ? value
      : parse(value, this.dateFormat, new Date())
    this._maxDate = isValid(parsedDate) ? parsedDate : null
  }
  private _maxDate: Date

  @ViewChild('innerElement') innerElement: ElementRef<HTMLDivElement>

  private get dateFormat(): string {
    return this.internalService.dateFormat
  }

  private get activeMonth() {
    return this.internalService.getMonthPanelDate(this.panelType)
  }

  private get disabled(): boolean {
    const formattedDayDate = format(this.day.date, this.dateFormat)

    return (
      this.internalService.disabledDates.includes(formattedDayDate) ||
      (isValid(this._minDate) && isAfter(this._minDate, this.day.date)) ||
      (isValid(this._maxDate) && isBefore(this._maxDate, this.day.date))
    )
  }

  private get isEmpty(): boolean {
    return !isSameMonth(this.day.date, this.activeMonth)
  }

  private get isToday(): boolean {
    return (
      isSameDay(this.day.date, new Date()) &&
      isSameMonth(this.day.date, this.activeMonth)
    )
  }

  private get isSelectedDate(): boolean {
    return this.internalService.isSelectedDate(this.day.date)
  }

  private get isSelectedFromDate(): boolean {
    return this.internalService.isSelectedFromDate(this.day.date)
  }

  private get isSelectedToDate(): boolean {
    return this.internalService.isSelectedToDate(this.day.date)
  }

  private get isInSelectedRange(): boolean {
    return this.internalService.isInSelectedRange(this.day.date)
  }

  private get isInHoveredRange(): boolean {
    return this.internalService.isInHoveredRange(
      this.day.date,
      this._minDate,
      this._maxDate
    )
  }

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.isEmpty) {
      this.day.dayOfMonth = null
      classes.push(`${BASE_CSS_CLASS}--empty`)
    } else {
      if (this.isSelectedDate) {
        classes.push(`${BASE_CSS_CLASS}--selected`)
      }
      if (this.isSelectedFromDate) {
        classes.push(`${BASE_CSS_CLASS}--selected-from`)
      }
      if (this.isSelectedToDate) {
        classes.push(`${BASE_CSS_CLASS}--selected-to`)
      }
      if (this.isInHoveredRange) {
        classes.push(`${BASE_CSS_CLASS}--in-hovered-range`)
      }
      if (this.isInSelectedRange) {
        classes.push(`${BASE_CSS_CLASS}--in-selected-range`)
      }
      if (this.disabled) {
        classes.push(`${BASE_CSS_CLASS}--disabled`)
      }
      if (this.isToday) {
        classes.push(`${BASE_CSS_CLASS}--today`)
      }
    }

    return classes
  }

  @HostListener('click') onClick(): void {
    if (this.disabled || this.isEmpty) {
      return
    }
    this.internalService.onDayClick(new Date(this.day.date))
  }

  constructor() {
    this.internalService.uiStateChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.changeDetectorRef.markForCheck())
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.innerElement.nativeElement, 'mouseover')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.internalService.shouldSetActiveHoveredDay(this.day.date)) {
            this.ngZone.run(() =>
              this.internalService.setActiveHoveredDay(this.day.date)
            )
          }
        })
    })
  }

  private _isDate(date: Date | string): date is Date {
    return date instanceof Date
  }
}
