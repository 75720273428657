<div class="bui-select-option-text">
  <ng-content></ng-content>
</div>
<div class="bui-select-option-checkbox">
  <input
    *ngIf="isMultiselect"
    class="bui-select-option-checkbox__native-control"
    type="checkbox"
    [disabled]="disabled"
    [checked]="isSelected"
  />
  <span class="bui-select-option-checkbox__checkmark"></span>
</div>
