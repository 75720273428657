import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Optional,
  HostListener,
  HostBinding,
} from '@angular/core'
import { merge } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { _BuiTableSortDirective } from './bui-table-sort.directive'
import { injectDestroy } from '../../util'
import { BuiTableSortDirection } from './bui-table-sort.models'

const BASE_CSS_CLASS = 'bui-table-sort-control'

@Component({
  selector: 'bui-table-sort-control',
  templateUrl: 'bui-table-sort-control.component.html',
  styleUrls: ['bui-table-sort-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiTableSortControlComponent implements OnInit {
  private destroy$ = injectDestroy()

  @Input() public sortId: string

  public direction: BuiTableSortDirection

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.isDisabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }

    return classes
  }

  private get isDisabled(): boolean {
    return this.sortDirective.disabled
  }

  constructor(
    @Optional() private sortDirective: _BuiTableSortDirective,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.direction = this.sortDirective.direction

    merge(this.sortDirective.stateChanges$, this.sortDirective.sortChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.sortDirective.activeId !== this.sortId) {
          this.direction = null
        }
        if (this.isDisabled) {
          return
        }
        this.changeDetectorRef.markForCheck()
      })
  }

  ngOnInit(): void {
    if (!this.sortDirective) {
      throw new Error(`BuiTableSortDirective missing.`)
    }
  }

  @HostListener('click') private _onClick() {
    if (this.isDisabled) {
      return
    }

    this.direction = this.direction === 'asc' ? 'desc' : 'asc'
    this.sortDirective.sort({
      id: this.sortId,
      direction: this.direction,
    })
  }

  public isActive(direction: BuiTableSortDirection): boolean {
    return (
      this.sortDirective.activeId === this.sortId &&
      this.sortDirective.direction === direction
    )
  }
}
