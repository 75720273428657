import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  numberAttribute,
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { _BuiInputBaseComponent } from './bui-input-base.component'

@Component({
  selector: 'bui-textarea',
  template: `<textarea
    [formControl]="_formControl"
    [rows]="rows"
    [attr.placeholder]="placeholder"
    [buiInputAutofocus]="autofocus"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
  ></textarea>`,
  styleUrls: ['./bui-input-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => _BuiInputTextareaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiInputTextareaComponent extends _BuiInputBaseComponent<string> {
  type = 'textarea'
  @Input({ transform: numberAttribute }) rows = 12

  @HostBinding('class.bui-textarea') isTextarea = true

  protected _transformOutputValue(value: string): string {
    return value
  }
}
