import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core'
import { BuiTab, BuiTabMode } from '../bui-tabs.models'
import { BUI_TABS_PARENT_TOKEN, _BuiTabsComponent } from '../bui-tabs.component'

const BASE_CSS_CLASS = 'bui-tab'

@Component({
  selector: 'bui-tab',
  templateUrl: './bui-tab.component.html',
  styleUrls: ['./bui-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiTabComponent {
  @Input({ required: true }) value: string | number
  @Input() isActive = false

  @Output() readonly tabClicked = new EventEmitter<BuiTab['value']>()

  @HostBinding('class') private get classes(): string[] {
    return [BASE_CSS_CLASS]
  }

  mode: BuiTabMode = this.parent.mode

  constructor(
    @Inject(BUI_TABS_PARENT_TOKEN) private parent: _BuiTabsComponent
  ) {}

  @HostListener('click') private onItemClick() {
    this.tabClicked.emit(this.value)
  }
}
