import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core'
import {
  parse,
  isValid,
  format,
  isSameDay,
  isAfter,
  addMonths,
  addDays,
  isBefore,
} from 'date-fns'
import { buiDatepickerAnimations } from '../bui-datepicker.animations'
import { _BuiDatepickerInputBase } from '../bui-datepicker-base.abstract'
import { _BuiDatepickerInternalService } from '../bui-datepicker-internal.service'
import {
  BuiDatepickerRange,
  BuiDatepickerType,
  _BuiDatepickerMonthPanelType,
  _BuiDatepickerRangeActiveSelection,
} from '../bui-datepicker.models'

@Component({
  selector: 'bui-date-range-input',
  templateUrl: '../bui-datepicker-base.component.html',
  styleUrls: ['../bui-datepicker-base.component.scss'],
  animations: [
    buiDatepickerAnimations.transformPanel,
    buiDatepickerAnimations.transformPanelWrap,
  ],
  providers: [
    _BuiDatepickerInternalService,
    {
      provide: 'BUI_DATE_RANGE_INPUT',
      useExisting: _BuiDateRangeInputComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDateRangeInputComponent extends _BuiDatepickerInputBase<BuiDatepickerRange> {
  @Input({ transform: booleanAttribute }) override dualPanels = false
  @Input() override placeholder = 'All dates'
  @Input() maxRangeDays: number
  @Input() singleDateSelectionStyle = false

  @Input() set minDate(value: Date | string) {
    this._minDate = value
  }
  get minDate(): Date | string {
    if (this.maxRangeDays && this.fromDate && !this.toDate) {
      const minDateFromMaxRangeDays = addDays(
        this.fromDate,
        (this.maxRangeDays - 1) * -1
      )
      const minDate =
        this._minDate instanceof Date
          ? new Date(this._minDate)
          : parse(this._minDate, this.dateFormat, new Date())

      return isValid(minDate) && isBefore(minDateFromMaxRangeDays, minDate)
        ? this._minDate
        : format(minDateFromMaxRangeDays, this.dateFormat)
    }

    return this._minDate
  }
  private _minDate: Date | string

  @Input() set maxDate(value: Date | string) {
    this._maxDate = value
  }
  get maxDate(): Date | string {
    if (this.maxRangeDays && this.fromDate && !this.toDate) {
      const maxDateFromMaxRangeDays = addDays(
        this.fromDate,
        this.maxRangeDays - 1
      )
      const maxDate =
        this._maxDate instanceof Date
          ? new Date(this._maxDate)
          : parse(this._maxDate, this.dateFormat, new Date())

      return isValid(maxDate) && isBefore(maxDate, maxDateFromMaxRangeDays)
        ? this._maxDate
        : format(maxDateFromMaxRangeDays, this.dateFormat)
    }

    return this._maxDate
  }
  private _maxDate: Date | string

  get label(): string {
    if (this.isEmpty) {
      return this.placeholder
    }

    let label = isValid(this.fromDate)
      ? format(this.fromDate, this.displayDateFormat)
      : ''

    if (isValid(this.toDate)) {
      label += ` - ${format(this.toDate, this.displayDateFormat)}`
    } else if (!this.singleDateSelectionStyle && isValid(this.fromDate)) {
      label += ` - `
    }

    return label
  }

  get isEmpty(): boolean {
    return this.fromDate === null && this.toDate === null
  }

  // fromDate & toDate reflect active selection in the panel which can
  // differ from ngControl.value (user needs to apply the changes)
  set fromDate(value: Date) {
    this._fromDate = value
    this.setActiveDateSelection()
  }
  get fromDate(): Date {
    return this._fromDate
  }
  private _fromDate: Date = null

  set toDate(value: Date) {
    this._toDate = value
    this.setActiveDateSelection()
  }
  get toDate(): Date {
    return this._toDate
  }
  private _toDate: Date = null

  getDatepickerType(): BuiDatepickerType {
    return 'range'
  }

  setActiveDateSelection(): void {
    this.internalService.activeDateSelection =
      new _BuiDatepickerRangeActiveSelection(this.fromDate, this.toDate)
  }

  updateValue(value: BuiDatepickerRange): void {
    const from = parse(value?.from, this.dateFormat, new Date())
    const to = parse(value?.to, this.dateFormat, new Date())

    this.fromDate = isValid(from) ? new Date(from) : null
    this.toDate = isValid(to) ? new Date(to) : null

    this._setMonthPanels()
  }

  onDayClicked(value: Date): void {
    if (
      this.internalService.untouchedSincePanelOpened ||
      (this.fromDate && this.toDate)
    ) {
      this.internalService.setActiveHoveredDay(null)
      this.fromDate = null
      this.toDate = null
    }

    this.internalService.untouchedSincePanelOpened = false

    if (this.fromDate) {
      if (isSameDay(this.fromDate, value)) {
        this.fromDate = null
        this.toDate = null
      } else if (isAfter(this.fromDate, value)) {
        this.toDate = new Date(this.fromDate)
        this.fromDate = new Date(value)
      } else {
        if (this.toDate && isSameDay(this.toDate, value)) {
          this.toDate = null
        } else {
          this.toDate = new Date(value)
        }
      }
    } else {
      this.fromDate = new Date(value)
    }

    this.internalService.triggerUIStateChange()
  }

  getValueToApply(): BuiDatepickerRange {
    return {
      from: this.fromDate ? format(this.fromDate, this.dateFormat) : null,
      to: this.toDate ? format(this.toDate, this.dateFormat) : null,
    }
  }

  getEmptyValue(): BuiDatepickerRange {
    return { from: null, to: null }
  }

  private _setMonthPanels(): void {
    if (this.dualPanels) {
      this.fromDate
        ? this._setMonthPanelDate('panelStart', new Date(this.fromDate))
        : this._setMonthPanelDate('panelStart', new Date())

      this.toDate
        ? this._setMonthPanelDate('panelEnd', new Date(this.toDate))
        : this._setMonthPanelDate('panelEnd', addMonths(new Date(), 1))
    } else {
      this.fromDate
        ? this._setMonthPanelDate('panelSingle', new Date(this.fromDate))
        : this._setMonthPanelDate('panelSingle', new Date())
    }
  }

  private _setMonthPanelDate(
    panelType: _BuiDatepickerMonthPanelType,
    month: Date
  ) {
    return this.internalService.setMonthPanelDate(panelType, month)
  }
}
