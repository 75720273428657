<div
  class="bui-typeahead-trigger"
  #trigger
  #origin="cdkOverlayOrigin"
  cdkOverlayOrigin
  (click)="toggle()"
>
  <bui-input-text
    [prefixIcon]="prefixIcon"
    [placeholder]="placeholder"
    [size]="size"
    [formControl]="searchFC"
  ></bui-input-text>

  <ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayLockPosition
    cdkConnectedOverlayHasBackdrop
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
    [cdkConnectedOverlayOpen]="isPanelOpen"
    [cdkConnectedOverlayPositions]="getPositions()"
    [cdkConnectedOverlayMinWidth]="_triggerRect?.width"
    (backdropClick)="close()"
    (attach)="_onAttached()"
    (detach)="close()"
  >
    <div class="bui-typeahead-panel-wrap" [@transformPanelWrap]>
      <div
        #panel
        [attr.id]="id + 'panel'"
        role="listbox"
        tabindex="-1"
        class="bui-typeahead-panel"
        [@transformPanel]="'showing'"
        (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
        (keydown)="_handleKeydown($event)"
      >
        <ng-container
          *ngFor="let option of options$ | async; trackBy: trackByIndex"
        >
          <bui-typeahead-option
            [optionTemplate]="optionTemplate"
            [value]="option"
          ></bui-typeahead-option>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
