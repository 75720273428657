import {
  Component,
  Input,
  ChangeDetectionStrategy,
  HostBinding,
} from '@angular/core'

const BASE_CSS_CLASS = 'bui-content-loader'

@Component({
  selector: 'bui-content-loader, [bui-content-loader], [buiContentLoader]',
  templateUrl: 'bui-content-loader.component.html',
  styleUrls: ['bui-content-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiContentLoaderComponent {
  @Input('buiContentLoader') isLoading: boolean

  @HostBinding('class') get classes(): string[] {
    return [BASE_CSS_CLASS]
  }
}
