<div
  class="bui-select-trigger"
  [class.bui-select-trigger--custom]="buiSelectTrigger"
  #trigger
  #origin="cdkOverlayOrigin"
  cdkOverlayOrigin
  (click)="toggle()"
>
  <ng-content
    select="[bui-select-trigger]"
    *ngIf="buiSelectTrigger; else triggerTemplate"
  ></ng-content>
</div>

<ng-template #triggerTemplate>
  <bui-icon
    *ngIf="prefixIcon"
    [name]="prefixIcon"
    class="bui-select-icon"
  ></bui-icon>
  <div class="bui-select-value" [ngSwitch]="empty && !staticLabel">
    <span class="bui-select-placeholder" *ngSwitchCase="true">
      {{ placeholder }}
    </span>
    <span class="bui-select-value-text" *ngSwitchCase="false">
      <span class="bui-select-option-prefix" *ngIf="prefix">{{ prefix }} </span>
      <span *ngIf="!numericalTriggerValue">{{ triggerValue }}</span>
      <span *ngIf="numericalTriggerValue" class="trigger-value-container">
        <span class="trigger-value-inner">{{ triggerValue }}</span>
      </span>
    </span>
  </div>
  <div class="bui-select-arrow">
    <bui-icon [name]="isPanelOpen ? 'chevron-up' : 'chevron-down'"></bui-icon>
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOpen]="isPanelOpen"
  [cdkConnectedOverlayPositions]="getPositions()"
  [cdkConnectedOverlayMinWidth]="_triggerRect?.width"
  (backdropClick)="close()"
  (attach)="_onAttached()"
  (detach)="close()"
>
  <div class="bui-select-panel-wrap" [@transformPanelWrap]>
    <div
      #panel
      [attr.id]="id + 'panel'"
      role="listbox"
      tabindex="-1"
      class="bui-select-panel"
      [@transformPanel]="'showing'"
      (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
      (keydown)="_handleKeydown($event)"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
