import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BuiLabelModule } from '../bui-label'
import { _BuiFormFieldComponent } from './bui-form-field.component'
import { _BuiFormFieldSuffixDirective } from './bui-form-field-suffix.directive'
import { _BuiFormFieldErrorComponent } from './bui-form-field-error.component'

@NgModule({
  declarations: [
    _BuiFormFieldComponent,
    _BuiFormFieldSuffixDirective,
    _BuiFormFieldErrorComponent,
  ],
  imports: [CommonModule, BuiLabelModule],
  exports: [
    _BuiFormFieldComponent,
    _BuiFormFieldSuffixDirective,
    _BuiFormFieldErrorComponent,
  ],
})
export class BuiFormFieldModule {}
