import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { FocusMonitor } from '@angular/cdk/a11y'
import { _BuiCheckboxSwitchBase } from '../abstract-classes/bui-checkbox-switch-base.abstract'
import { BuiSwitchChange } from './bui-switch.models'

const BASE_CSS_CLASS = 'bui-switch'

@Component({
  selector: 'bui-switch',
  templateUrl: './bui-switch.component.html',
  styleUrls: ['./bui-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => _BuiSwitchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiSwitchComponent extends _BuiCheckboxSwitchBase<BuiSwitchChange> {
  constructor(
    elementRef: ElementRef,
    focusMonitor: FocusMonitor,
    changeDetectorRef: ChangeDetectorRef,
    @Attribute('tabindex') tabIndex?: string
  ) {
    super(elementRef, focusMonitor, changeDetectorRef, BASE_CSS_CLASS, tabIndex)
  }

  protected _createChangeEvent(isChecked: boolean): BuiSwitchChange {
    return new BuiSwitchChange(this, isChecked)
  }
}
