import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiTabsComponent } from './bui-tabs.component'
import { _BuiTabComponent } from './bui-tab/bui-tab.component'
import { RouterModule } from '@angular/router'
import { BuiIconModule } from '../bui-icon'

@NgModule({
  declarations: [_BuiTabsComponent, _BuiTabComponent],
  imports: [CommonModule, RouterModule, BuiIconModule],
  exports: [_BuiTabsComponent],
})
export class BuiTabsModule {}
