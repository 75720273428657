import { animate, state, style, transition, trigger } from '@angular/animations'

export const buiAsideAnimation = trigger('buiAsideAnimation', [
  state('void, exit', style({ transform: 'translateX(100%)', opacity: 0 })),
  state('enter', style({ transform: 'none' })),
  transition(
    '* => enter',
    animate(
      '150ms cubic-bezier(0, 0, 0.2, 1)',
      style({ transform: 'none', opacity: 1 })
    )
  ),
  transition(
    '* => void, * => exit',
    animate(
      '150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      style({ transform: 'translateX(100%)', opacity: 0 })
    )
  ),
])
