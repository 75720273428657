import { NgModule } from '@angular/core'
import { _BuiSelectComponent } from './bui-select.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { _BuiSelectOptionComponent } from './bui-select-option.component'
import { _BuiSelectTriggerDirective } from './bui-select-trigger.directive'
import { BUI_SELECT_SCROLL_STRATEGY_PROVIDER } from './bui-select.tokens'
import { BuiIconModule } from '../bui-icon'

@NgModule({
  declarations: [
    _BuiSelectComponent,
    _BuiSelectOptionComponent,
    _BuiSelectTriggerDirective,
  ],
  imports: [CommonModule, OverlayModule, BuiIconModule],
  providers: [BUI_SELECT_SCROLL_STRATEGY_PROVIDER],
  exports: [
    _BuiSelectComponent,
    _BuiSelectOptionComponent,
    _BuiSelectTriggerDirective,
  ],
})
export class BuiSelectModule {}
