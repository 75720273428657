import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BuiAccordionComponent } from './bui-accordion.component'
import { BuiAccordionItemComponent } from './bui-accordion-item/bui-accordion-item.component'
import {
  BuiAccordionDescriptionDirective,
  BuiAccordionHeaderComponent,
  BuiAccordionTitleDirective,
} from './bui-accordion-header/bui-accordion-header.component'

@NgModule({
  declarations: [
    BuiAccordionComponent,
    BuiAccordionItemComponent,
    BuiAccordionHeaderComponent,
    BuiAccordionTitleDirective,
    BuiAccordionDescriptionDirective,
  ],
  imports: [CommonModule],
  exports: [
    BuiAccordionComponent,
    BuiAccordionItemComponent,
    BuiAccordionHeaderComponent,
    BuiAccordionTitleDirective,
    BuiAccordionDescriptionDirective,
  ],
})
export class BuiAccordionModule {}
