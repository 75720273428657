<ng-content></ng-content>

<div class="bui-table-sort-control__arrows">
  <div
    class="bui-table-sort-control__arrow bui-table-sort-control__arrow--asc"
    [class.bui-table-sort-control__arrow--active]="isActive('asc')"
  ></div>
  <div
    class="bui-table-sort-control__arrow bui-table-sort-control__arrow--desc"
    [class.bui-table-sort-control__arrow--active]="isActive('desc')"
  ></div>
</div>
