export interface BuiModalPosition {
  top?: string
  bottom?: string
  left?: string
  right?: string
}

export class BuiModalConfig<D = any> {
  disableClose?: boolean = false
  hasBackdrop?: boolean = true
  backdropClass?: string = ''
  containerClass?: string = ''
  position?: BuiModalPosition
  data?: D | null = null
}
