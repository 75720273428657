import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiRadioButtonComponent } from './bui-radio.component'
import { _BuiRadioGroupDirective } from './bui-radio-group.directive'

@NgModule({
  declarations: [_BuiRadioButtonComponent, _BuiRadioGroupDirective],
  imports: [CommonModule],
  exports: [_BuiRadioButtonComponent, _BuiRadioGroupDirective],
})
export class BuiRadioModule {}
