import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'
import { BUI_NAVLIST, BuiNavlistVariant } from './bui-navlist.models'

const BASE_CSS_CLASS = 'bui-navlist'

@Component({
  selector: 'bui-navlist',
  templateUrl: './bui-navlist.component.html',
  styleUrls: ['./bui-navlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BUI_NAVLIST, useExisting: _BuiNavlistComponent }],
})
export class _BuiNavlistComponent {
  @Input() variant: BuiNavlistVariant = 'light'

  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    classes.push(`${BASE_CSS_CLASS}--variant-${this.variant}`)

    return classes
  }
}
