import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  ViewChild,
} from '@angular/core'
import { _BuiStepTrackerItemState } from '../bui-step-tracker.models'

const BASE_CSS_CLASS = 'bui-step-tracker-item'

@Component({
  selector: 'bui-step-tracker-item',
  templateUrl: './bui-step-tracker-item.component.html',
  styleUrls: ['./bui-step-tracker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiStepTrackerItemComponent {
  private _state: _BuiStepTrackerItemState = 'todo'
  set state(value: _BuiStepTrackerItemState) {
    this._state = value
    this.changeDetectorRef.detectChanges()
  }
  get state(): _BuiStepTrackerItemState {
    return this._state
  }

  @ViewChild('contentRef') private contentRef: ElementRef<HTMLDivElement>

  @HostBinding('class') private get classes(): string[] {
    return [BASE_CSS_CLASS, `${BASE_CSS_CLASS}--state-${this._state}`]
  }

  constructor(
    public hostElement: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public get content(): string {
    return this.contentRef?.nativeElement.innerHTML ?? ''
  }
}
