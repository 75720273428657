// Based on Angular Material Slider v15

import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion'
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  _BuiThumbPosition,
  BuiSliderDragEvent,
  _BuiSlider,
  _BuiSliderRangeInput,
  _BuiSliderInput,
  BUI_SLIDER_RANGE_INPUT,
  BUI_SLIDER_INPUT,
  BUI_SLIDER,
} from './bui-slider.models'
import { getBuiCustomCssPropertyValue } from '../bui-custom-css-property'
import { convertRemToPx, injectDestroy } from '../util'

const BASE_CSS_CLASS = 'bui-slider-input'

// Provider that allows the slider thumb to register as a ControlValueAccessor
export const BUI_SLIDER_THUMB_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => _BuiSliderInputDirective),
  multi: true,
}

// Provider that allows the range slider thumb to register as a ControlValueAccessor
export const BUI_SLIDER_RANGE_THUMB_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => _BuiSliderRangeInputDirective),
  multi: true,
}

// Directive that adds slider-specific behaviors to an input element inside `<bui-slider>`.
// Up to two may be placed inside of a `<bui-slider>`.
// If one is used, the selector `buiSliderInput` must be used, and the outcome will be a basic
// slider. If two are used, the selectors `buiSliderStartInput` and `buiSliderEndInput` must be
// used, and the outcome will be a range slider with two slider thumbs.
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[buiSliderInput]',
  exportAs: 'buiSliderInput',
  providers: [
    BUI_SLIDER_THUMB_VALUE_ACCESSOR,
    { provide: BUI_SLIDER_INPUT, useExisting: _BuiSliderInputDirective },
  ],
})
export class _BuiSliderInputDirective
  implements _BuiSliderInput, OnDestroy, ControlValueAccessor
{
  @Input()
  get value(): number {
    return coerceNumberProperty(this._hostElement.value)
  }
  set value(v: NumberInput) {
    const val = coerceNumberProperty(v).toString()
    if (!this._hasSetInitialValue) {
      this._initialValue = val
      return
    }
    if (this._isActive) {
      return
    }
    this._hostElement.value = val
    this._updateThumbUIByValue()
    this._slider._onValueChange(this)
    this._cdr.detectChanges()
  }

  // Event emitted when the `value` is changed
  @Output() readonly valueChange = new EventEmitter<number>()

  // Event emitted when the slider thumb starts being dragged
  @Output() readonly dragStart = new EventEmitter<BuiSliderDragEvent>()

  // Event emitted when the slider thumb stops being dragged
  @Output() readonly dragEnd = new EventEmitter<BuiSliderDragEvent>()

  // The current translateX in px of the slider visual thumb
  get translateX(): number {
    if (this._slider.min >= this._slider.max) {
      this._translateX = 0
      return this._translateX
    }
    if (this._translateX === undefined) {
      this._translateX = this._calcTranslateXByValue()
    }
    return this._translateX
  }
  set translateX(v: number) {
    this._translateX = v
  }
  private _translateX: number | undefined

  // Indicates whether this thumb is the start or end thumb
  thumbPosition: _BuiThumbPosition = _BuiThumbPosition.END

  get min(): number {
    return coerceNumberProperty(this._hostElement.min)
  }
  set min(v: NumberInput) {
    this._hostElement.min = coerceNumberProperty(v).toString()
    this._cdr.detectChanges()
  }

  get max(): number {
    return coerceNumberProperty(this._hostElement.max)
  }
  set max(v: NumberInput) {
    this._hostElement.max = coerceNumberProperty(v).toString()
    this._cdr.detectChanges()
  }

  get step(): number {
    return coerceNumberProperty(this._hostElement.step)
  }
  set step(v: NumberInput) {
    this._hostElement.step = coerceNumberProperty(v).toString()
    this._cdr.detectChanges()
  }

  get disabled(): boolean {
    return coerceBooleanProperty(this._hostElement.disabled)
  }
  set disabled(v: BooleanInput) {
    this._hostElement.disabled = coerceBooleanProperty(v)
    this._cdr.detectChanges()

    if (this._slider.disabled !== this.disabled) {
      this._slider.disabled = this.disabled
    }
  }

  // The percentage of the slider that coincides with the value
  get percentage(): number {
    if (this._slider.min >= this._slider.max) {
      return 0
    }
    return (
      (this.value - this._slider.min) / (this._slider.max - this._slider.min)
    )
  }

  get fillPercentage(): number {
    if (!this._slider._cachedWidth) {
      return 0
    }
    if (this._translateX === 0) {
      return 0
    }
    return this.translateX / this._slider._cachedWidth
  }

  // The host native HTML input element
  _hostElement: HTMLInputElement

  // The aria-valuetext string representation of the input's value
  _valuetext: string

  // Whether user's cursor is currently in a mouse down state on the input
  _isActive = false

  // Whether the input is currently focused (either by tab or after clicking)
  _isFocused = false

  // Whether the initial value has been set.
  // This exists because the initial value cannot be immediately set because the min and max
  // must first be relayed from the parent BuiSlider component, which can only happen later
  // in the component lifecycle.
  private _hasSetInitialValue = false

  // The stored initial value
  _initialValue: string | undefined

  // Emits when the component is destroyed
  protected readonly destroy$ = injectDestroy()

  // Indicates whether UI updates should be skipped.
  // This flag is used to avoid flickering
  // when correcting values on pointer up/down.
  _skipUIUpdate = false

  _thumbRadius =
    convertRemToPx(getBuiCustomCssPropertyValue('--bui-slider-thumb-size')) / 2

  private _knobRadius =
    convertRemToPx(
      getBuiCustomCssPropertyValue('--bui-slider-thumb-knob-size')
    ) / 2

  // The padding around the thumb knob. This is added in order to allow the interactive
  // region around the thumb (that extends past the end of the slider track) to be clickable.
  _thumbPadding = this._thumbRadius - this._knobRadius

  @HostBinding(`class.${BASE_CSS_CLASS}`) readonly cssClass = true

  @HostBinding('attr.type') get getType(): string {
    return 'range'
  }

  @HostListener('change') onChange() {
    this._onChange()
  }

  @HostListener('input') onInput() {
    this._onInput()
  }

  @HostListener('blur') onBlur() {
    this._onBlur()
  }

  @HostListener('focus') onFocus() {
    this._onFocus()
  }

  constructor(
    readonly _ngZone: NgZone,
    readonly _elementRef: ElementRef<HTMLInputElement>,
    readonly _cdr: ChangeDetectorRef,
    @Inject(BUI_SLIDER) protected _slider: _BuiSlider
  ) {
    this._hostElement = _elementRef.nativeElement
    this._ngZone.runOutsideAngular(() => {
      this._hostElement.addEventListener(
        'pointerdown',
        this._onPointerDown.bind(this)
      )
      this._hostElement.addEventListener(
        'pointermove',
        this._onPointerMove.bind(this)
      )
      this._hostElement.addEventListener(
        'pointerup',
        this._onPointerUp.bind(this)
      )
    })
  }

  ngOnDestroy(): void {
    this._hostElement.removeEventListener('pointerdown', this._onPointerDown)
    this._hostElement.removeEventListener('pointermove', this._onPointerMove)
    this._hostElement.removeEventListener('pointerup', this._onPointerUp)
    this.dragStart.complete()
    this.dragEnd.complete()
  }

  // Used to relay updates to _isFocused to the slider visual thumbs
  private _setIsFocused(v: boolean): void {
    this._isFocused = v
  }

  // Callback called when the slider input value changes
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onChangeFn: (value: any) => void = () => {}

  // Callback called when the slider input has been touched
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouchedFn: () => void = () => {}

  initProps(): void {
    this._updateWidthInactive()

    // If this or the parent slider is disabled, just make everything disabled
    if (this.disabled !== this._slider.disabled) {
      // The BuiSlider setter for disabled will relay this and disable both inputs
      this._slider.disabled = true
    }

    this.step = this._slider.step
    this.min = this._slider.min
    this.max = this._slider.max
    this._initValue()
  }

  initUI(): void {
    this._updateThumbUIByValue()
  }

  _initValue(): void {
    this._hasSetInitialValue = true
    if (this._initialValue === undefined) {
      this.value = this._getDefaultValue()
    } else {
      this._hostElement.value = this._initialValue
      this._updateThumbUIByValue()
      this._slider._onValueChange(this)
      this._cdr.detectChanges()
    }
  }

  _getDefaultValue(): number {
    return this.min
  }

  _onBlur(): void {
    this._setIsFocused(false)
    this._onTouchedFn()
  }

  _onFocus(): void {
    this._setIsFocused(true)
  }

  _onChange(): void {
    this.valueChange.emit(this.value)
    // only used to handle the edge case where user
    // mousedown on the slider then uses arrow keys
    if (this._isActive) {
      this._updateThumbUIByValue()
    }
  }

  _onInput(): void {
    this._onChangeFn(this.value)
    // handles arrowing and updating the value when
    // a step is defined
    if (this._slider.step || !this._isActive) {
      this._updateThumbUIByValue()
    }
    this._slider._onValueChange(this)
  }

  _onPointerDown(event: PointerEvent): void {
    if (this.disabled || event.button !== 0) {
      return
    }

    this._isActive = true
    this._setIsFocused(true)
    this._updateWidthActive()
    this._slider._updateDimensions()

    // Does nothing if a step is defined because we
    // want the value to snap to the values on input
    if (!this._slider.step) {
      this._updateThumbUIByPointerEvent(event)
    }

    if (!this.disabled) {
      this._handleValueCorrection(event)
      this.dragStart.emit({
        source: this,
        parent: this._slider,
        value: this.value,
      })
    }
  }

  // Corrects the value of the slider on pointer up/down.
  // Called on pointer down and up because the value is set based
  // on the inactive width instead of the active width.
  private _handleValueCorrection(event: PointerEvent): void {
    // Don't update the UI with the current value! The value on pointerdown
    // and pointerup is calculated in the split second before the input(s)
    // resize. See _updateWidthInactive() and _updateWidthActive() for more
    // details.
    this._skipUIUpdate = true

    // Note that this function gets triggered before the actual value of the
    // slider is updated. This means if we were to set the value here, it
    // would immediately be overwritten. Using setTimeout ensures the setting
    // of the value happens after the value has been updated by the
    // pointerdown event.
    setTimeout(() => {
      this._skipUIUpdate = false
      this._fixValue(event)
    }, 0)
  }

  // Corrects the value of the slider based on the pointer event's position
  _fixValue(event: PointerEvent): void {
    const xPos = event.clientX - this._slider._cachedLeft
    const width = this._slider._cachedWidth
    const step = this._slider.step === 0 ? 1 : this._slider.step
    const numSteps = Math.floor((this._slider.max - this._slider.min) / step)
    const percentage = xPos / width

    // To ensure the percentage is rounded to the necessary number of decimals.
    const fixedPercentage = Math.round(percentage * numSteps) / numSteps

    const impreciseValue =
      fixedPercentage * (this._slider.max - this._slider.min) + this._slider.min
    const value = Math.round(impreciseValue / step) * step
    const prevValue = this.value

    if (value === prevValue) {
      // Because we prevented UI updates, if it turns out that the race
      // condition didn't happen and the value is already correct, we
      // have to apply the ui updates now.
      this._slider._onValueChange(this)
      this._slider.step > 0
        ? this._updateThumbUIByValue()
        : this._updateThumbUIByPointerEvent(event)
      return
    }

    this.value = value
    this.valueChange.emit(this.value)
    this._onChangeFn(this.value)
    this._slider._onValueChange(this)
    this._slider.step > 0
      ? this._updateThumbUIByValue()
      : this._updateThumbUIByPointerEvent(event)
  }

  _onPointerMove(event: PointerEvent): void {
    // Again, does nothing if a step is defined because
    // we want the value to snap to the values on input.
    if (!this._slider.step && this._isActive) {
      this._updateThumbUIByPointerEvent(event)
    }
  }

  _onPointerUp(): void {
    if (this._isActive) {
      this._isActive = false
      this.dragEnd.emit({
        source: this,
        parent: this._slider,
        value: this.value,
      })
      setTimeout(() => this._updateWidthInactive())
    }
  }

  _clamp(v: number): number {
    return Math.max(Math.min(v, this._slider._cachedWidth), 0)
  }

  _calcTranslateXByValue(): number {
    return this.percentage * this._slider._cachedWidth
  }

  _calcTranslateXByPointerEvent(event: PointerEvent): number {
    return event.clientX - this._slider._cachedLeft
  }

  // Used to set the slider width to the correct dimensions while the user is dragging
  _updateWidthActive(): void {
    this._hostElement.style.padding = `0 ${this._thumbPadding}px`
    this._hostElement.style.width = `calc(100% + ${this._thumbPadding}px)`
  }

  // Sets the slider input to disproportionate dimensions to allow for touch
  // events to be captured on touch devices.
  _updateWidthInactive(): void {
    this._hostElement.style.padding = '0'
    this._hostElement.style.width = `calc(100% + ${this._thumbRadius * 2}px)`
    this._hostElement.style.left = `-${this._thumbRadius}px`
  }

  _updateThumbUIByValue(): void {
    this.translateX = this._clamp(this._calcTranslateXByValue())
    this._updateThumbUI()
  }

  _updateThumbUIByPointerEvent(event: PointerEvent): void {
    this.translateX = this._clamp(this._calcTranslateXByPointerEvent(event))
    this._updateThumbUI()
  }

  _updateThumbUI() {
    this._slider._onTranslateXChange(this)
  }

  // Sets the input's value
  writeValue(value: any): void {
    this.value = value
  }

  // Registers a callback to be invoked when the input's value changes from user input
  registerOnChange(fn: any): void {
    this._onChangeFn = fn
  }

  // Registers a callback to be invoked when the input is blurred by the user
  registerOnTouched(fn: any): void {
    this._onTouchedFn = fn
  }

  // Sets the disabled state of the slider
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  focus(): void {
    this._hostElement.focus()
  }

  blur(): void {
    this._hostElement.blur()
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[buiSliderStartInput], input[buiSliderEndInput]',
  exportAs: 'buiSliderRangeThumb',
  providers: [
    BUI_SLIDER_RANGE_THUMB_VALUE_ACCESSOR,
    {
      provide: BUI_SLIDER_RANGE_INPUT,
      useExisting: _BuiSliderRangeInputDirective,
    },
  ],
})
export class _BuiSliderRangeInputDirective
  extends _BuiSliderInputDirective
  implements _BuiSliderRangeInput
{
  private _sibling: _BuiSliderRangeInputDirective | undefined

  // Whether this slider corresponds to the input on the left hand side
  _isLeftThumb: boolean

  // Whether this slider corresponds to the input with greater value
  _isEndThumb: boolean

  constructor(
    _ngZone: NgZone,
    @Inject(BUI_SLIDER) _slider: _BuiSlider,
    _elementRef: ElementRef<HTMLInputElement>,
    override readonly _cdr: ChangeDetectorRef
  ) {
    super(_ngZone, _elementRef, _cdr, _slider)
    this._isEndThumb = this._hostElement.hasAttribute('buiSliderEndInput')
    this._setIsLeftThumb()
    this.thumbPosition = this._isEndThumb
      ? _BuiThumbPosition.END
      : _BuiThumbPosition.START
  }

  getSibling(): _BuiSliderRangeInput | undefined {
    if (!this._sibling) {
      this._sibling = this._slider._getInput(
        this._isEndThumb ? _BuiThumbPosition.START : _BuiThumbPosition.END
      ) as _BuiSliderRangeInputDirective | undefined
    }
    return this._sibling
  }

  // Returns the minimum translateX position allowed for this slider input's visual thumb
  getMinPos(): number {
    const sibling = this.getSibling()
    if (!this._isLeftThumb && sibling) {
      return Math.min(sibling.translateX, this._slider._cachedWidth)
    }
    return 0
  }

  // Returns the maximum translateX position allowed for this slider input's visual thumb
  getMaxPos(): number {
    const sibling = this.getSibling()
    if (this._isLeftThumb && sibling) {
      return sibling.translateX
    }
    return this._slider._cachedWidth
  }

  _setIsLeftThumb(): void {
    this._isLeftThumb = !this._isEndThumb
  }

  override _getDefaultValue(): number {
    return this._isEndThumb && this._slider._isRange ? this.max : this.min
  }

  override _onInput(): void {
    super._onInput()
    this._updateSibling()
    if (!this._isActive) {
      this._updateWidthInactive()
    }
  }

  override _onPointerDown(event: PointerEvent): void {
    if (this.disabled) {
      return
    }
    if (this._sibling) {
      this._sibling._updateWidthActive()
      this._sibling._hostElement.classList.add(
        `${BASE_CSS_CLASS}--no-pointer-events`
      )
    }
    super._onPointerDown(event)
  }

  override _onPointerUp(): void {
    super._onPointerUp()
    if (this._sibling) {
      setTimeout(() => {
        this._sibling._updateWidthInactive()
        this._sibling._hostElement.classList.remove(
          `${BASE_CSS_CLASS}--no-pointer-events`
        )
      })
    }
  }

  override _onPointerMove(event: PointerEvent): void {
    super._onPointerMove(event)
    if (!this._slider.step && this._isActive) {
      this._updateSibling()
    }
  }

  override _fixValue(event: PointerEvent): void {
    super._fixValue(event)
    this._sibling?._updateMinMax()
  }

  override _clamp(v: number): number {
    return Math.max(Math.min(v, this.getMaxPos()), this.getMinPos())
  }

  _updateMinMax(): void {
    const sibling = this.getSibling()
    if (!sibling) {
      return
    }
    if (this._isEndThumb) {
      this.min = Math.max(this._slider.min, sibling.value)
      this.max = this._slider.max
    } else {
      this.min = this._slider.min
      this.max = Math.min(this._slider.max, sibling.value)
    }
  }

  override _updateWidthActive(): void {
    const minWidth = this._thumbRadius * 2 - this._thumbPadding * 2
    const maxWidth = this._slider._cachedWidth + this._thumbPadding - minWidth
    const percentage =
      this._slider.min < this._slider.max
        ? (this.max - this.min) / (this._slider.max - this._slider.min)
        : 1
    const width = maxWidth * percentage + minWidth
    this._hostElement.style.width = `${width}px`
    this._hostElement.style.padding = `0 ${this._thumbPadding}px`
  }

  override _updateWidthInactive(): void {
    const sibling = this.getSibling()
    if (!sibling) {
      return
    }
    const maxWidth = this._slider._cachedWidth
    const midValue = this._isEndThumb
      ? this.value - (this.value - sibling.value) / 2
      : this.value + (sibling.value - this.value) / 2

    const _percentage = this._isEndThumb
      ? (this.max - midValue) / (this._slider.max - this._slider.min)
      : (midValue - this.min) / (this._slider.max - this._slider.min)

    const percentage = this._slider.min < this._slider.max ? _percentage : 1

    const width = maxWidth * percentage + this._thumbRadius
    this._hostElement.style.width = `${width}px`
    this._hostElement.style.padding = '0'

    if (this._isLeftThumb) {
      this._hostElement.style.left = `-${this._thumbRadius}px`
      this._hostElement.style.right = 'auto'
    } else {
      this._hostElement.style.left = 'auto'
      this._hostElement.style.right = `-${this._thumbRadius}px`
    }
  }

  _updateStaticStyles(): void {
    this._hostElement.classList.toggle(
      `${BASE_CSS_CLASS}--right`,
      !this._isLeftThumb
    )
  }

  private _updateSibling(): void {
    const sibling = this.getSibling()
    if (!sibling) {
      return
    }
    sibling._updateMinMax()
    if (this._isActive) {
      sibling._updateWidthActive()
    } else {
      sibling._updateWidthInactive()
    }
  }

  // Sets the input's value
  override writeValue(value: any): void {
    this.value = value
    this._updateWidthInactive()
    this._updateSibling()
  }
}
