import { _BuiButtonToggleComponent } from './bui-button-toggle.component'

// Change event object emitted by button toggle
export class BuiButtonToggleChange {
  constructor(
    // The button toggle that emits the event
    public source: _BuiButtonToggleComponent,

    // The value assigned to the button toggle
    public value: any
  ) {}
}
