import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  booleanAttribute,
} from '@angular/core'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[buiInputAutofocus]',
  exportAs: 'buiInputAutofocus',
})
export class _BuiInputAutofocusDirective implements OnChanges, AfterViewInit {
  @Input({ transform: booleanAttribute }) buiInputAutofocus = false

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(): void {
    this.focus()
  }

  ngAfterViewInit(): void {
    this.focus()
  }

  private focus() {
    if (this.buiInputAutofocus && !this.elementRef.nativeElement?.readOnly) {
      this.elementRef.nativeElement?.focus()
    }
  }
}
