import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'

const BASE_CSS_CLASS = 'bui-link'

@Component({
  selector: 'bui-link',
  templateUrl: './bui-link.component.html',
  styleUrls: ['./bui-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiLinkComponent {
  @Input() route?: string
  @Input() queryParams?: Record<string, string | number> = null
  @Input() link?: string
  @Input() variant: 'regular' | 'bold' | 'heavy' = 'regular'
  @Input() disabled = false

  @HostBinding('class') get classes() {
    const classes = [BASE_CSS_CLASS, `${BASE_CSS_CLASS}--${this.variant}`]

    if (this.disabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }

    return classes
  }
}
