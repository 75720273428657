import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core'

export const BuiMessageVariant = [
  'info',
  'warning',
  'error',
  'success',
] as const
export type BuiMessageVariant = (typeof BuiMessageVariant)[number]

@Component({
  selector: 'bui-message',
  templateUrl: './bui-message.component.html',
  styleUrls: ['./bui-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class _BuiMessageComponent {
  @Input() variant: BuiMessageVariant = 'info'

  @HostBinding('class') get classes() {
    return `bui-message bui-message--variant-${this.variant}`
  }
}
