import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiTitleComponent } from './bui-title.component'

@NgModule({
  declarations: [_BuiTitleComponent],
  imports: [CommonModule],
  exports: [_BuiTitleComponent],
})
export class BuiTitleModule {}
