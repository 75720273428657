/* eslint-disable no-self-assign */

// Based on Angular Material Slider v15

import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion'
import { Platform } from '@angular/cdk/platform'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  isDevMode,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import {
  _BuiThumbPosition,
  _BuiSlider,
  _BuiSliderRangeInput,
  _BuiSliderInput,
  _BuiSliderThumb,
  BUI_SLIDER_RANGE_INPUT,
  BUI_SLIDER_INPUT,
  BUI_SLIDER,
  BUI_SLIDER_THUMB,
} from './bui-slider.models'

const BASE_CSS_CLASS = 'bui-slider'

@Component({
  selector: 'bui-slider',
  templateUrl: './bui-slider.component.html',
  styleUrls: ['./bui-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BUI_SLIDER, useExisting: _BuiSliderComponent }],
})
export class _BuiSliderComponent
  implements AfterViewInit, OnDestroy, _BuiSlider
{
  @HostBinding('class') get classes() {
    let classes = BASE_CSS_CLASS

    if (this._isRange) {
      classes += ` ${BASE_CSS_CLASS}--range`
    }
    if (this.disabled) {
      classes += ` ${BASE_CSS_CLASS}--disabled`
    }
    if (this.showValue) {
      classes += ` ${BASE_CSS_CLASS}--show-value`
    }
    if (this.hasError) {
      classes += ` ${BASE_CSS_CLASS}--error`
    }

    return classes
  }

  // The active portion of the slider track
  @ViewChild('trackActive') _trackActive: ElementRef<HTMLElement>

  // The slider thumb(s)
  @ViewChildren(BUI_SLIDER_THUMB)
  _thumbs: QueryList<_BuiSliderThumb>

  // The sliders hidden range input(s)
  @ContentChild(BUI_SLIDER_INPUT) _input: _BuiSliderInput

  // The sliders hidden range input(s)
  @ContentChildren(BUI_SLIDER_RANGE_INPUT, { descendants: false })
  _inputs: QueryList<_BuiSliderRangeInput>

  // Whether the slider has error styling
  @Input()
  get hasError(): boolean {
    return this._hasError
  }
  set hasError(v: BooleanInput) {
    this._hasError = coerceBooleanProperty(v)
  }
  private _hasError = false

  // Whether the slider is disabled
  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(v: BooleanInput) {
    this._disabled = coerceBooleanProperty(v)
    const endInput = this._getInput(_BuiThumbPosition.END)
    const startInput = this._getInput(_BuiThumbPosition.START)

    if (endInput) {
      endInput.disabled = this._disabled
    }
    if (startInput) {
      startInput.disabled = this._disabled
    }
  }
  private _disabled = false

  // Whether the slider displays a numeric value label above the thumb
  @Input()
  get showValue(): boolean {
    return this._showValue
  }
  set showValue(v: BooleanInput) {
    this._showValue = coerceBooleanProperty(v)
    this._updateValueIndicatorUIs()
  }
  private _showValue = false

  // The minimum value that the slider can have
  @Input()
  get min(): number {
    return this._min
  }
  set min(v: NumberInput) {
    const min = coerceNumberProperty(v, this._min)
    if (this._min !== min) {
      this._updateMin(min)
    }
  }
  private _min = 0

  // The maximum value that the slider can have
  @Input()
  get max(): number {
    return this._max
  }
  set max(v: NumberInput) {
    const max = coerceNumberProperty(v, this._max)
    if (this._max !== max) {
      this._updateMax(max)
    }
  }
  private _max = 100

  // The values at which the thumb will snap
  @Input()
  get step(): number {
    return this._step
  }
  set step(v: NumberInput) {
    const step = coerceNumberProperty(v, this._step)
    if (this._step !== step) {
      this._updateStep(step)
    }
  }
  private _step = 0

  // Observer used to monitor size changes in the slider
  private _resizeObserver: ResizeObserver | null

  // Stored dimensions to avoid calling getBoundingClientRect redundantly
  _cachedWidth: number
  _cachedLeft: number

  // The value indicator tooltip text for the visual slider thumb(s)
  protected endValueIndicatorText: string
  protected startValueIndicatorText: string

  // Used to control the translateX of the visual slider thumb(s)
  _endThumbTransform: string
  _startThumbTransform: string

  _isRange = false

  private _hasViewInitialized = false

  // Whether or not the slider thumbs overlap
  private _thumbsOverlap = false

  // Function that will be used to format the value before it is displayed
  // in the thumb label. Can be used to format very large number in order
  // for them to fit into the slider thumb.
  @Input() displayWith: (value: number) => string = (value: number) =>
    `${value}`

  constructor(
    readonly _ngZone: NgZone,
    readonly _cdr: ChangeDetectorRef,
    readonly _platform: Platform,
    readonly _elementRef: ElementRef<HTMLElement>
  ) {}

  ngAfterViewInit(): void {
    this._updateDimensions()

    const eInput = this._getInput(_BuiThumbPosition.END)
    const sInput = this._getInput(_BuiThumbPosition.START)
    this._isRange = !!eInput && !!sInput
    this._cdr.detectChanges()

    if (isDevMode()) {
      _validateInputs(
        this._isRange,
        this._getInput(_BuiThumbPosition.END),
        this._getInput(_BuiThumbPosition.START)
      )
    }

    this._isRange
      ? this._initUIRange(
          eInput as _BuiSliderRangeInput,
          sInput as _BuiSliderRangeInput
        )
      : this._initUINonRange(eInput)

    this._updateTrackUI(eInput)

    this._observeHostResize()
    this._cdr.detectChanges()
  }

  ngOnDestroy(): void {
    this._resizeObserver?.disconnect()
    this._resizeObserver = null
  }

  private _initUINonRange(eInput: _BuiSliderInput): void {
    eInput.initProps()
    eInput.initUI()

    this._updateValueIndicatorUI(eInput)

    this._hasViewInitialized = true
    eInput._updateThumbUIByValue()
  }

  private _initUIRange(
    eInput: _BuiSliderRangeInput,
    sInput: _BuiSliderRangeInput
  ): void {
    eInput.initProps()
    eInput.initUI()

    sInput.initProps()
    sInput.initUI()

    eInput._updateMinMax()
    sInput._updateMinMax()

    eInput._updateStaticStyles()
    sInput._updateStaticStyles()

    this._updateValueIndicatorUIs()

    this._hasViewInitialized = true

    eInput._updateThumbUIByValue()
    sInput._updateThumbUIByValue()
  }

  private _updateMin(min: number): void {
    const prevMin = this._min
    this._min = min
    this._isRange
      ? this._updateMinRange({ old: prevMin, new: min })
      : this._updateMinNonRange(min)
    this._onMinMaxOrStepChange()
  }

  private _updateMinRange(min: { old: number; new: number }): void {
    const endInput = this._getInput(
      _BuiThumbPosition.END
    ) as _BuiSliderRangeInput
    const startInput = this._getInput(
      _BuiThumbPosition.START
    ) as _BuiSliderRangeInput

    const oldEndValue = endInput.value
    const oldStartValue = startInput.value

    startInput.min = min.new
    endInput.min = Math.max(min.new, startInput.value)
    startInput.max = Math.min(endInput.max, endInput.value)

    startInput._updateWidthInactive()
    endInput._updateWidthInactive()

    min.new < min.old
      ? this._onTranslateXChangeBySideEffect(endInput, startInput)
      : this._onTranslateXChangeBySideEffect(startInput, endInput)

    if (oldEndValue !== endInput.value) {
      this._onValueChange(endInput)
    }

    if (oldStartValue !== startInput.value) {
      this._onValueChange(startInput)
    }
  }

  private _updateMinNonRange(min: number): void {
    const input = this._getInput(_BuiThumbPosition.END)
    if (input) {
      const oldValue = input.value

      input.min = min
      input._updateThumbUIByValue()
      this._updateTrackUI(input)

      if (oldValue !== input.value) {
        this._onValueChange(input)
      }
    }
  }

  private _updateMax(max: number): void {
    const prevMax = this._max
    this._max = max
    this._isRange
      ? this._updateMaxRange({ old: prevMax, new: max })
      : this._updateMaxNonRange(max)
    this._onMinMaxOrStepChange()
  }

  private _updateMaxRange(max: { old: number; new: number }): void {
    const endInput = this._getInput(
      _BuiThumbPosition.END
    ) as _BuiSliderRangeInput
    const startInput = this._getInput(
      _BuiThumbPosition.START
    ) as _BuiSliderRangeInput

    const oldEndValue = endInput.value
    const oldStartValue = startInput.value

    endInput.max = max.new
    startInput.max = Math.min(max.new, endInput.value)
    endInput.min = startInput.value

    endInput._updateWidthInactive()
    startInput._updateWidthInactive()

    max.new > max.old
      ? this._onTranslateXChangeBySideEffect(startInput, endInput)
      : this._onTranslateXChangeBySideEffect(endInput, startInput)

    if (oldEndValue !== endInput.value) {
      this._onValueChange(endInput)
    }

    if (oldStartValue !== startInput.value) {
      this._onValueChange(startInput)
    }
  }

  private _updateMaxNonRange(max: number): void {
    const input = this._getInput(_BuiThumbPosition.END)
    if (input) {
      const oldValue = input.value

      input.max = max
      input._updateThumbUIByValue()
      this._updateTrackUI(input)

      if (oldValue !== input.value) {
        this._onValueChange(input)
      }
    }
  }

  private _updateStep(step: number): void {
    this._step = step
    this._isRange ? this._updateStepRange() : this._updateStepNonRange()
    this._onMinMaxOrStepChange()
  }

  private _updateStepRange(): void {
    const endInput = this._getInput(
      _BuiThumbPosition.END
    ) as _BuiSliderRangeInput
    const startInput = this._getInput(
      _BuiThumbPosition.START
    ) as _BuiSliderRangeInput

    const oldEndValue = endInput.value
    const oldStartValue = startInput.value

    const prevStartValue = startInput.value

    endInput.min = this._min
    startInput.max = this._max

    endInput.step = this._step
    startInput.step = this._step

    if (this._platform.SAFARI) {
      endInput.value = endInput.value
      startInput.value = startInput.value
    }

    endInput.min = Math.max(this._min, startInput.value)
    startInput.max = Math.min(this._max, endInput.value)

    startInput._updateWidthInactive()
    endInput._updateWidthInactive()

    endInput.value < prevStartValue
      ? this._onTranslateXChangeBySideEffect(startInput, endInput)
      : this._onTranslateXChangeBySideEffect(endInput, startInput)

    if (oldEndValue !== endInput.value) {
      this._onValueChange(endInput)
    }

    if (oldStartValue !== startInput.value) {
      this._onValueChange(startInput)
    }
  }

  private _updateStepNonRange(): void {
    const input = this._getInput(_BuiThumbPosition.END)
    if (input) {
      const oldValue = input.value

      input.step = this._step
      if (this._platform.SAFARI) {
        input.value = input.value
      }

      input._updateThumbUIByValue()

      if (oldValue !== input.value) {
        this._onValueChange(input)
      }
    }
  }

  // Starts observing and updating the slider if the host changes its size
  private _observeHostResize() {
    if (typeof ResizeObserver === 'undefined' || !ResizeObserver) {
      return
    }

    this._ngZone.runOutsideAngular(() => {
      this._resizeObserver = new ResizeObserver(() => {
        if (this._isActive()) {
          return
        }
        this._onResize()
      })
      this._resizeObserver.observe(this._elementRef.nativeElement)
    })
  }

  // Whether any of the thumbs are currently active
  private _isActive(): boolean {
    return (
      this._getThumb(_BuiThumbPosition.START)._isActive ||
      this._getThumb(_BuiThumbPosition.END)._isActive
    )
  }

  private _skipUpdate(): boolean {
    return !!(
      this._getInput(_BuiThumbPosition.START)?._skipUIUpdate ||
      this._getInput(_BuiThumbPosition.END)?._skipUIUpdate
    )
  }

  // Stores the slider dimensions
  _updateDimensions(): void {
    this._cachedWidth = this._elementRef.nativeElement.offsetWidth
    this._cachedLeft =
      this._elementRef.nativeElement.getBoundingClientRect().left
  }

  // Sets the styles for the active portion of the track
  _setTrackActiveStyles(styles: {
    left: string
    right: string
    transform: string
    transformOrigin: string
  }): void {
    const trackStyle = this._trackActive.nativeElement.style

    trackStyle.left = styles.left
    trackStyle.right = styles.right
    trackStyle.transformOrigin = styles.transformOrigin
    trackStyle.transform = styles.transform
  }

  _onTranslateXChange(source: _BuiSliderInput): void {
    if (!this._hasViewInitialized) {
      return
    }

    this._updateThumbUI(source)
    this._updateTrackUI(source)
    this._updateOverlappingThumbUI(source as _BuiSliderRangeInput)
  }

  _onTranslateXChangeBySideEffect(
    input1: _BuiSliderRangeInput,
    input2: _BuiSliderRangeInput
  ): void {
    if (!this._hasViewInitialized) {
      return
    }

    input1._updateThumbUIByValue()
    input2._updateThumbUIByValue()
  }

  _onValueChange(source: _BuiSliderInput): void {
    if (!this._hasViewInitialized) {
      return
    }

    this._updateValueIndicatorUI(source)
    this._cdr.detectChanges()
  }

  _onMinMaxOrStepChange(): void {
    if (!this._hasViewInitialized) {
      return
    }

    this._cdr.markForCheck()
  }

  _onResize(): void {
    if (!this._hasViewInitialized) {
      return
    }

    this._updateDimensions()
    if (this._isRange) {
      const eInput = this._getInput(
        _BuiThumbPosition.END
      ) as _BuiSliderRangeInput
      const sInput = this._getInput(
        _BuiThumbPosition.START
      ) as _BuiSliderRangeInput

      eInput._updateThumbUIByValue()
      sInput._updateThumbUIByValue()

      eInput._updateStaticStyles()
      sInput._updateStaticStyles()

      eInput._updateMinMax()
      sInput._updateMinMax()

      eInput._updateWidthInactive()
      sInput._updateWidthInactive()
    } else {
      const eInput = this._getInput(_BuiThumbPosition.END)
      if (eInput) {
        eInput._updateThumbUIByValue()
      }
    }

    this._cdr.detectChanges()
  }

  // Returns true if the slider knobs are overlapping one another
  private _areThumbsOverlapping(): boolean {
    const startInput = this._getInput(_BuiThumbPosition.START)
    const endInput = this._getInput(_BuiThumbPosition.END)
    if (!startInput || !endInput) {
      return false
    }
    return endInput.translateX - startInput.translateX < 40
  }

  // Updates the class names of overlapping slider thumbs so
  // that the current active thumb is styled to be on "top"
  private _updateOverlappingThumbClassNames(
    source: _BuiSliderRangeInput
  ): void {
    const sibling = source.getSibling()
    const siblingThumb = this._getThumb(sibling.thumbPosition)
    const sourceThumb = this._getThumb(source.thumbPosition)

    siblingThumb.setIsOverlapping(this._thumbsOverlap)
    sourceThumb.setIsOverlapping(this._thumbsOverlap)
  }

  // Updates the UI of slider thumbs when they begin or stop overlapping
  private _updateOverlappingThumbUI(source: _BuiSliderRangeInput): void {
    if (!this._isRange || this._skipUpdate()) {
      return
    }
    if (this._thumbsOverlap !== this._areThumbsOverlapping()) {
      this._thumbsOverlap = !this._thumbsOverlap
      this._updateOverlappingThumbClassNames(source)
    }
  }

  // _BuiThumb styles update conditions
  //
  // 1. TranslateX, resize
  //    - Reason: The thumb styles need to be updated according to the new translateX.
  // 2. Min, max, or step
  //    - Reason: The value may have silently changed.

  // Updates the translateX of the given thumb
  _updateThumbUI(source: _BuiSliderInput) {
    if (this._skipUpdate()) {
      return
    }
    const thumb = this._getThumb(
      source.thumbPosition === _BuiThumbPosition.END
        ? _BuiThumbPosition.END
        : _BuiThumbPosition.START
    )
    thumb._hostElement.style.transform = `translateX(${source.translateX}px)`
  }

  // Value indicator text update conditions
  //
  // 1. Value
  //    - Reason: The value displayed needs to be updated.
  // 2. Min, max, or step
  //    - Reason: The value may have silently changed.

  // Updates the value indicator tooltip ui for the given thumb
  _updateValueIndicatorUI(source: _BuiSliderInput): void {
    if (this._skipUpdate()) {
      return
    }

    const valuetext = this.displayWith(source.value)

    this._hasViewInitialized
      ? (source._valuetext = valuetext)
      : source._hostElement.setAttribute('aria-valuetext', valuetext)

    if (this.showValue) {
      source.thumbPosition === _BuiThumbPosition.START
        ? (this.startValueIndicatorText = valuetext)
        : (this.endValueIndicatorText = valuetext)
    }
  }

  // Updates all value indicator UIs in the slider
  private _updateValueIndicatorUIs(): void {
    const eInput = this._getInput(_BuiThumbPosition.END)
    const sInput = this._getInput(_BuiThumbPosition.START)

    if (eInput) {
      this._updateValueIndicatorUI(eInput)
    }
    if (sInput) {
      this._updateValueIndicatorUI(sInput)
    }
  }

  // Updates the scale on the active portion of the track
  _updateTrackUI(source: _BuiSliderInput): void {
    if (this._skipUpdate()) {
      return
    }

    this._isRange
      ? this._updateTrackUIRange(source as _BuiSliderRangeInput)
      : this._updateTrackUINonRange(source as _BuiSliderInput)
  }

  private _updateTrackUIRange(source: _BuiSliderRangeInput): void {
    const sibling = source.getSibling()
    if (!sibling || !this._cachedWidth) {
      return
    }

    const activePercentage =
      Math.abs(sibling.translateX - source.translateX) / this._cachedWidth

    if (source._isLeftThumb && this._cachedWidth) {
      this._setTrackActiveStyles({
        left: 'auto',
        right: `${this._cachedWidth - sibling.translateX}px`,
        transformOrigin: 'right',
        transform: `scaleX(${activePercentage})`,
      })
    } else {
      this._setTrackActiveStyles({
        left: `${sibling.translateX}px`,
        right: 'auto',
        transformOrigin: 'left',
        transform: `scaleX(${activePercentage})`,
      })
    }
  }

  private _updateTrackUINonRange(source: _BuiSliderInput): void {
    this._setTrackActiveStyles({
      left: '0',
      right: 'auto',
      transformOrigin: 'left',
      transform: `scaleX(${source.fillPercentage})`,
    })
  }

  // Gets the slider thumb input of the given thumb position
  _getInput(
    thumbPosition: _BuiThumbPosition
  ): _BuiSliderInput | _BuiSliderRangeInput | undefined {
    if (thumbPosition === _BuiThumbPosition.END && this._input) {
      return this._input
    }
    if (this._inputs?.length) {
      return thumbPosition === _BuiThumbPosition.START
        ? this._inputs.first
        : this._inputs.last
    }
    return undefined
  }

  // Gets the slider thumb HTML input element of the given thumb position
  private _getThumb(thumbPosition: _BuiThumbPosition): _BuiSliderThumb {
    return thumbPosition === _BuiThumbPosition.END
      ? this._thumbs?.last
      : this._thumbs?.first
  }
}

// Ensures that there is not an invalid configuration for the slider thumb inputs
function _validateInputs(
  isRange: boolean,
  endInputElement: _BuiSliderInput | _BuiSliderRangeInput,
  startInputElement?: _BuiSliderInput
): void {
  const startValid =
    !isRange ||
    startInputElement?._hostElement.hasAttribute('buiSliderStartInput')
  const endValid = endInputElement._hostElement.hasAttribute(
    isRange ? 'buiSliderEndInput' : 'buiSliderInput'
  )

  if (!startValid || !endValid) {
    _throwInvalidInputConfigurationError()
  }
}

function _throwInvalidInputConfigurationError(): void {
  throw Error(`Invalid slider thumb input configuration!

   Valid configurations are as follows:

     <bui-slider>
       <input buiSliderInput>
     </bui-slider>

     or

     <bui-slider>
       <input buiSliderStartInput>
       <input buiSliderEndInput>
     </bui-slider>
   `)
}
