// Based on Angular Material Radio v15
/* eslint-disable @typescript-eslint/no-empty-function */

import {
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core'
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y'
import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion'
import { UniqueSelectionDispatcher } from '@angular/cdk/collections'
import { BuiRadioChange } from './bui-radio.models'
import {
  _BuiRadioGroupDirective,
  BUI_RADIO_GROUP,
  _BuiRadioGroupBase,
} from './bui-radio-group.directive'
import { BuiSize } from '../bui-size'

const BASE_CSS_CLASS = 'bui-radio'

// Increasing integer for generating unique ids for radio components.
let nextUniqueId = 0

// Base class with all of the `BuiRadioButton` functionality.
@Directive()
export abstract class _BuiRadioButtonBase
  implements OnInit, AfterViewInit, DoCheck, OnDestroy
{
  private _uniqueId = `${BASE_CSS_CLASS}-${++nextUniqueId}`

  // Tabindex of the component
  tabIndex: number

  // Previous value of the input's tabindex
  private _previousTabIndex: number | undefined

  // The unique ID for the radio button
  @Input() id: string = this._uniqueId

  // Analog to HTML 'name' attribute used to group radios for unique selection
  @Input() name: string

  @Input() size: Exclude<BuiSize, 'large'> = 'regular'

  // Whether this radio button is checked
  private _checked = false
  @Input()
  get checked(): boolean {
    return this._checked
  }
  set checked(value: BooleanInput) {
    const newCheckedState = coerceBooleanProperty(value)
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState
      if (
        newCheckedState &&
        this.radioGroup &&
        this.radioGroup.value !== this.value
      ) {
        this.radioGroup.selected = this
      } else if (
        !newCheckedState &&
        this.radioGroup &&
        this.radioGroup.value === this.value
      ) {
        // When unchecking the selected radio button, update the selected radio
        // property on the group.
        this.radioGroup.selected = null
      }

      if (newCheckedState) {
        // Notify all radio buttons with the same name to un-check.
        this._radioDispatcher.notify(this.id, this.name)
      }
      this._changeDetector.markForCheck()
    }
  }

  // The value of this radio button
  private _value: any = null
  @Input()
  get value(): any {
    return this._value
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value
      if (this.radioGroup !== null) {
        if (!this.checked) {
          // Update checked when the value changed to match the radio group's value
          this.checked = this.radioGroup.value === value
        }
        if (this.checked) {
          this.radioGroup.selected = this
        }
      }
    }
  }

  // Whether the label should appear after or before the radio button. Defaults to 'after'
  private _labelPosition: 'before' | 'after'
  @Input()
  get labelPosition(): 'before' | 'after' {
    return (
      this._labelPosition ||
      (this.radioGroup && this.radioGroup.labelPosition) ||
      'after'
    )
  }
  set labelPosition(value) {
    this._labelPosition = value
  }

  // Whether the radio button is disabled
  private _disabled: boolean
  @Input()
  get disabled(): boolean {
    return (
      this._disabled || (this.radioGroup !== null && this.radioGroup.disabled)
    )
  }
  set disabled(value: BooleanInput) {
    this._setDisabled(coerceBooleanProperty(value))
  }

  // Whether the radio button is required
  private _required: boolean
  @Input()
  get required(): boolean {
    return this._required || (this.radioGroup && this.radioGroup.required)
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value)
  }

  // Event emitted when the checked state of this radio button changes
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change = new EventEmitter<BuiRadioChange>()

  // The parent radio group. May or may not be present
  radioGroup: _BuiRadioGroupBase<_BuiRadioButtonBase>

  // ID of the native input element inside `<bui-radio>`
  get inputId(): string {
    return `${this.id || this._uniqueId}-input`
  }

  // The native `<input type=radio>` element
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>

  constructor(
    radioGroup: _BuiRadioGroupBase<_BuiRadioButtonBase>,
    private _elementRef: ElementRef,
    protected _changeDetector: ChangeDetectorRef,
    private _focusMonitor: FocusMonitor,
    private _radioDispatcher: UniqueSelectionDispatcher,
    tabIndex?: string
  ) {
    this.radioGroup = radioGroup

    if (tabIndex) {
      this.tabIndex = coerceNumberProperty(tabIndex, 0)
    }
  }

  // Unregister function for _radioDispatcher
  private _removeUniqueSelectionListener: () => void = () => {}

  // Focuses the radio button
  focus(options?: FocusOptions, origin?: FocusOrigin): void {
    if (origin) {
      this._focusMonitor.focusVia(this._inputElement, origin, options)
    } else {
      this._inputElement.nativeElement.focus(options)
    }
  }

  /**
   * Marks the radio button as needing checking for change detection.
   * This method is exposed because the parent radio group will directly
   * update bound properties of the radio button.
   */
  _markForCheck() {
    // When group value changes, the button will not be notified. Use `markForCheck` to explicit
    // update radio button's status
    this._changeDetector.markForCheck()
  }

  ngOnInit() {
    if (this.radioGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this._value

      if (this.checked) {
        this.radioGroup.selected = this
      }

      // Copy name from parent radio group
      this.name = this.radioGroup.name
    }

    this._removeUniqueSelectionListener = this._radioDispatcher.listen(
      (id, name) => {
        if (id !== this.id && name === this.name) {
          this.checked = false
        }
      }
    )
  }

  ngDoCheck(): void {
    this._updateTabIndex()
  }

  ngAfterViewInit() {
    this._updateTabIndex()
    this._focusMonitor
      .monitor(this._elementRef, true)
      .subscribe((focusOrigin) => {
        if (!focusOrigin && this.radioGroup) {
          this.radioGroup._touch()
        }
      })
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef)
    this._removeUniqueSelectionListener()
  }

  // Dispatch change event with current value
  private _emitChangeEvent(): void {
    this.change.emit(new BuiRadioChange(this, this._value))
  }

  // Triggered when the radio button receives an interaction from the user
  _onInputInteraction(event: Event) {
    // Stop propagation on the change event. Otherwise the change event, from the input element,
    // will bubble up and emit its event object to the `change` output.
    event.stopPropagation()

    if (!this.checked && !this.disabled) {
      const groupValueChanged =
        this.radioGroup && this.value !== this.radioGroup.value
      this.checked = true
      this._emitChangeEvent()

      if (this.radioGroup) {
        this.radioGroup._controlValueAccessorChangeFn(this.value)
        if (groupValueChanged) {
          this.radioGroup._emitChangeEvent()
        }
      }
    }
  }

  // Triggered when the user clicks on the touch target
  _onTouchTargetClick(event: Event) {
    this._onInputInteraction(event)

    if (!this.disabled) {
      // Normally the input should be focused already, but if the click
      // comes from the touch target, then set focus manually.
      this.focus(null, 'mouse')
    }
  }

  // Sets the disabled state and marks for check if a change occurred
  protected _setDisabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value
      this._changeDetector.markForCheck()
    }
  }

  // Gets the tabindex for the underlying input element
  private _updateTabIndex() {
    const group = this.radioGroup
    let value: number

    // Implement a roving tabindex if the button is inside a group. For most cases this isn't
    // necessary, because the browser handles the tab order for inputs inside a group automatically,
    // but an explicitly higher tabindex in needed for the selected button in order for things like
    // the focus trap to pick it up correctly.
    if (!group || !group.selected || this.disabled) {
      value = this.tabIndex
    } else {
      value = group.selected === this ? this.tabIndex : -1
    }

    if (value !== this._previousTabIndex) {
      // Set tabindex directly on the DOM node, because it depends on
      // the selected state which is prone to "changed after checked errors".
      const input: HTMLInputElement | undefined =
        this._inputElement?.nativeElement

      if (input) {
        input.setAttribute('tabindex', value + '')
        this._previousTabIndex = value
      }
    }
  }
}

@Component({
  selector: 'bui-radio',
  templateUrl: 'bui-radio.component.html',
  styleUrls: ['bui-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiRadioButtonComponent extends _BuiRadioButtonBase {
  @HostBinding('attr.id') get getId(): string {
    return this.id
  }

  @HostBinding('attr.tabindex') get tabindex(): string {
    // clearing tabindex to avoid a11y issues
    return null
  }

  @HostBinding('class') get classes() {
    let classes = BASE_CSS_CLASS
    classes += ` ${BASE_CSS_CLASS}--label-position-${this.labelPosition}`
    classes += ` ${BASE_CSS_CLASS}--size-${this.size}`

    if (this.checked) {
      classes += ` ${BASE_CSS_CLASS}--checked`
    }
    if (this.disabled) {
      classes += ` ${BASE_CSS_CLASS}--disabled`
    }
    if (this.radioGroup) {
      classes += ` ${BASE_CSS_CLASS}--variant-${this.radioGroup.variant}`
    }
    return classes
  }

  // Under normal conditions focus shouldn't land on this element. However, it may be
  // programmatically set, for example inside of a focus trap, in that case the focus
  // is forwarded to the native element.
  @HostListener('focus') onFocus(): void {
    this._inputElement.nativeElement.focus()
  }

  constructor(
    @Optional() @Inject(BUI_RADIO_GROUP) radioGroup: _BuiRadioGroupDirective,
    elementRef: ElementRef,
    _changeDetector: ChangeDetectorRef,
    _focusMonitor: FocusMonitor,
    _radioDispatcher: UniqueSelectionDispatcher,
    @Attribute('tabindex') tabIndex?: string
  ) {
    super(
      radioGroup,
      elementRef,
      _changeDetector,
      _focusMonitor,
      _radioDispatcher,
      tabIndex
    )
  }
}
