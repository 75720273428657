import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core'

@Component({
  selector: 'bui-aside-loader',
  template: '<bui-loader variant="circle"></bui-loader>',
  styleUrls: ['./bui-aside-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiAsideLoaderComponent {
  @HostBinding('class.bui-aside-loader') private contentClass = true
}
