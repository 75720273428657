import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  isDevMode,
} from '@angular/core'
import {
  BUI_TAB_QUERY_PARAM_KEY,
  BuiTab,
  BuiTabEvent,
  BuiTabMode,
} from './bui-tabs.models'
import { BuiQueryParamsService } from '../bui-query-params'
import { BehaviorSubject } from 'rxjs'

export const BUI_TABS_PARENT_TOKEN = new InjectionToken<_BuiTabsComponent>(
  'BUI_TABS_PARENT'
)

const BASE_CSS_CLASS = 'bui-tabs'

@Component({
  selector: 'bui-tabs',
  templateUrl: './bui-tabs.component.html',
  styleUrls: ['./bui-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BUI_TABS_PARENT_TOKEN, useExisting: _BuiTabsComponent },
  ],
})
export class _BuiTabsComponent implements OnInit, OnDestroy {
  @Input({ required: true }) tabs: BuiTab[]
  @Input() initialTabValue: BuiTab['value']
  @Input() mode: BuiTabMode = 'noop'
  @Input() extendBorder = true

  @Output() readonly selected = new EventEmitter<BuiTabEvent>()

  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.extendBorder) {
      classes.push(`${BASE_CSS_CLASS}--extended-border`)
    }

    return classes
  }

  public readonly activeTabValue$ = new BehaviorSubject<BuiTab['value']>(null)

  constructor(private queryParamsService: BuiQueryParamsService) {}

  ngOnInit(): void {
    if (isDevMode() && this.mode === 'useRouter' && this.initialTabValue) {
      console.warn(`initialTabValue does nothing in 'useRouter' mode`)
    }

    if (this.mode !== 'useRouter') {
      this._initTab()
    }
  }

  ngOnDestroy(): void {
    this.activeTabValue$.next(null)
    this.activeTabValue$.complete()
  }

  public onTabClicked(tabValue: BuiTab['value']): void {
    this._setActiveTab(tabValue, false)
  }

  private _initTab(): void {
    const tab = this.queryParamsService.get(BUI_TAB_QUERY_PARAM_KEY)

    // sets activeTabValue from queryParams if tab param is in the array of tab values
    const activeTabValue =
      tab && this._isTabValueValid(tab)
        ? tab
        : this.initialTabValue ?? this._getTabValue(0)

    this._setActiveTab(activeTabValue, true)
  }

  private _isTabValueValid(value: BuiTab['value']): boolean {
    return this.tabs.some((tab) => tab.value === value)
  }

  private _getTabValue(index: number): BuiTab['value'] {
    return this.tabs[index].value
  }

  private _setActiveTab(value: BuiTab['value'], firstChange = false) {
    this.activeTabValue$.next(value)

    if (this.mode === 'useQueryParams') {
      this._updateTabQueryParam(value)
    }

    this.selected.emit({ value, firstChange })
  }

  private _updateTabQueryParam(value: BuiTab['value']) {
    // in 'useQueryParams' mode sets tab query param (&tab=value) unless the 1st tab is active
    if (value === this._getTabValue(0)) {
      this.queryParamsService.remove(BUI_TAB_QUERY_PARAM_KEY)
    } else {
      this.queryParamsService.add({ [BUI_TAB_QUERY_PARAM_KEY]: value })
    }
  }
}
