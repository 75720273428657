<ng-content></ng-content>
<ng-container *ngIf="removable">
  <div class="bui-chip__removable-spacer"></div>
  <div
    class="bui-chip__removable-btn"
    (click)="onRemoveClick()"
    [buiTooltip]="removeButtonTooltip"
    [buiTooltipDisabled]="!removeButtonTooltip"
  >
    <bui-icon name="close"></bui-icon>
  </div>
</ng-container>
