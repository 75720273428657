<div class="bui-datepicker-time__field">
  <div class="bui-datepicker-time__field-label">Hour</div>
  <bui-input-text
    class="bui-datepicker-time__field-input"
    [formControl]="formControlHours"
    size="large"
    mask="Hh"
    (blur)="onHoursBlur()"
    (keydown)="onInputKeydown($event, 'hours')"
  ></bui-input-text>
</div>
<span class="bui-datepicker-time__colon">:</span>

<div class="bui-datepicker-time__field">
  <div class="bui-datepicker-time__field-label">Minute</div>
  <bui-input-text
    class="bui-datepicker-time__field-input"
    [formControl]="formControlMinutes"
    size="large"
    mask="m0"
    (blur)="onMinutesBlur()"
    (keydown)="onInputKeydown($event, 'minutes')"
  ></bui-input-text>
</div>

<bui-button-toggle-group
  class="bui-datepicker-time__button-toggle-group"
  [formControl]="formControlIsPM"
  vertical
  (keydown)="onToggleKeydown($event)"
>
  <bui-button-toggle class="bui-datepicker-time__button-toggle" [value]="false">
    AM
  </bui-button-toggle>
  <bui-button-toggle class="bui-datepicker-time__button-toggle" [value]="true">
    PM
  </bui-button-toggle>
</bui-button-toggle-group>
