import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { _BuiInputBaseComponent } from './bui-input-base.component'
import { coerceNumberProperty } from '@angular/cdk/coercion'
import { BuiIconType } from '../bui-icon'
import { provideNgxMask } from 'ngx-mask'
import { integerAttribute } from '../util'

@Component({
  selector: 'bui-input-number',
  template: `<input
      #inputField
      type="text"
      [formControl]="_formControl"
      [buiInputAutofocus]="autofocus"
      [mask]="mask"
      [leadZero]="leadZero"
      [allowNegativeNumbers]="allowNegativeNumbers"
      [thousandSeparator]="thousandSeparator"
      [decimalMarker]="decimalMarker"
      [inputTransformFn]="getInputTransformFn(min, max, _formControl)"
      [attr.placeholder]="placeholder"
      inputmode="numeric"
      autocomplete="off"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (keydown)="onKeydown($event)"
    />
    <bui-icon
      *ngIf="prefixIcon"
      [name]="prefixIcon"
      class="bui-input-prefix"
    ></bui-icon>`,
  styleUrls: ['./bui-input-base.component.scss'],
  providers: [
    provideNgxMask(),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => _BuiInputNumberComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiInputNumberComponent
  extends _BuiInputBaseComponent<number>
  implements OnChanges
{
  @Input({ required: true }) type: 'integer' | 'float'

  // ngx-mask settings
  mask = 'separator'
  readonly thousandSeparator = ''
  readonly decimalMarker = '.'
  @Input({ transform: booleanAttribute }) allowNegativeNumbers = true
  @Input({ transform: booleanAttribute }) leadZero = false

  @Input({ transform: integerAttribute }) step = 1
  @Input({ transform: integerAttribute }) min: number
  @Input({ transform: integerAttribute }) max: number
  @Input({ transform: integerAttribute }) limitDecimalPlaces: number
  @Input() prefixIcon: BuiIconType

  @HostBinding('class.bui-input--has-prefix-icon')
  get hasPrefixIcon(): boolean {
    return !!this.prefixIcon
  }

  @HostBinding('class.bui-input--number') private _isNumberInput = true

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['type'] || !!changes['limitDecimalPlaces']) {
      this._setMask()
    }
  }

  // passed to ngx-mask as [inputTransformFn]
  getInputTransformFn(
    min: number,
    max: number,
    _formControl: FormControl
  ): (value: unknown) => string {
    // performs string transformation before ngx-mask is applied
    return (value: unknown): string => {
      if (value === null || value === undefined) {
        return ''
      }

      let _value = String(value).trim()
      _value = _replaceComma(_value)
      _value = _trimLeadingZeros(_value)
      _value = _addLeadingZero(_value)

      if (_value === '') {
        return ''
      }

      const numericValue = Number(_value)
      if (
        !isNaN(numericValue) &&
        _limitMinMax(numericValue, min, max) !== numericValue
      ) {
        _value = _limitMinMax(numericValue, min, max)?.toString() || ''
        _formControl.setValue(_value, { emitEvent: false })
      }

      return _value
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === '.' || event.key === ',') {
      if (this.type === 'integer') {
        event.preventDefault()
      }
    } else if (event.key === 'ArrowUp') {
      const modifier = event.shiftKey ? 10 : 1
      this._step(this.step * modifier)
    } else if (event.key === 'ArrowDown') {
      const modifier = event.shiftKey ? -10 : -1
      this._step(this.step * modifier)
    }
  }

  private _step(step: number) {
    if (!step) {
      return
    }

    // Avoiding floating-point arithmetic issues
    // https://floating-point-gui.de/
    const value = Number(this._formControl.value ?? 0)
    const splitString = value.toString().split('.')
    const integer = parseInt(splitString[0])
    const isSafeInteger = integer + 1 > value

    if (isSafeInteger) {
      if (!this.allowNegativeNumbers && integer + step < 0) {
        splitString[0] = '0'
      } else {
        splitString[0] = (integer + step).toString()
      }
      this._formControl.setValue(splitString.join('.'))

      // Dispatch change event to mimic native number input behavior
      const event = new Event('change', { bubbles: true })
      this.inputField.nativeElement.dispatchEvent(event)
    }
  }

  protected _transformOutputValue(value: string): number {
    return value === '' ? null : coerceNumberProperty(value)
  }

  private _setMask(): void {
    if (this.type === 'integer') {
      this.mask = 'separator.0'
    } else {
      this.mask =
        this.limitDecimalPlaces === undefined ||
        this.limitDecimalPlaces === null
          ? 'separator'
          : `separator.${this.limitDecimalPlaces}`
    }
  }
}

function _limitMinMax(value: number, min: number, max: number): number {
  if (min !== undefined && min !== null && value < min) {
    return min
  } else if (max !== undefined && max !== null && value > max) {
    return max
  }

  return value
}

function _replaceComma(value: string): string {
  return value.indexOf('.') === -1
    ? value.replaceAll(',', '.')
    : value.replaceAll(',', '')
}

function _trimLeadingZeros(value: string): string {
  // trims redundant leading zeros
  const splitString = value.split('.')

  while (
    (splitString[0].startsWith('0') && splitString[0] !== '0') ||
    (splitString[0].startsWith('-0') && splitString[0] !== '-0')
  ) {
    splitString[0] = splitString[0].replace('0', '')
  }

  return splitString.join('.')
}

function _addLeadingZero(value: string): string {
  // adds leading zero if string starts with the decimal separator
  if (value.startsWith('.')) {
    return value.replace('.', '0.')
  } else if (value.startsWith('-.')) {
    return value.replace('-.', '-0.')
  }
  return value
}
