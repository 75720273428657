import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
} from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Subject } from 'rxjs'
import { _BuiStepTrackerItemComponent } from './bui-step-tracker-item/bui-step-tracker-item.component'
import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion'

const BASE_CSS_CLASS = 'bui-step-tracker'

@Component({
  selector: 'bui-step-tracker',
  templateUrl: './bui-step-tracker.component.html',
  styleUrls: ['./bui-step-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiStepTrackerComponent implements AfterViewInit {
  @Input() set activeStep(value: NumberInput) {
    this._activeStep = coerceNumberProperty(value)
    this._updateChildren()
  }
  get activeStep(): number {
    return this._activeStep
  }
  private _activeStep = 1

  private get activeStepIndex(): number {
    return this._activeStep - 1
  }

  @HostBinding('class') private get classes(): string[] {
    return [BASE_CSS_CLASS]
  }

  @ContentChildren(_BuiStepTrackerItemComponent)
  private _children: QueryList<_BuiStepTrackerItemComponent>

  readonly selectedStepLabel$ = new Subject<SafeHtml>()

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    setTimeout(() => this._updateChildren())
  }

  private _updateChildren(): void {
    this._updateChildStates()
    this._updateSelectedStepLabel()
  }

  private _updateChildStates(): void {
    this._children?.forEach((child, ix) => {
      if (ix === this.activeStepIndex) {
        child.state = 'active'
      } else if (ix < this.activeStepIndex) {
        child.state = 'completed'
      } else {
        child.state = 'todo'
      }
    })
  }

  private _updateSelectedStepLabel(): void {
    const content = this._children?.get(this.activeStepIndex).content
    const html = this.sanitizer.bypassSecurityTrustHtml(
      `Step ${this.activeStep}${content ? ': ' + content : ''}`
    )
    this.selectedStepLabel$.next(html)
  }
}
