import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay'
import { hasModifierKey } from '@angular/cdk/keycodes'
import { Subject } from 'rxjs'
import { filter, take, takeUntil } from 'rxjs/operators'
import { _BuiAsideContainerComponent } from './bui-aside-container/bui-aside-container.component'
import { _BuiAsideConfigInternal } from './bui-aside-config'
import { BuiEventsService } from '../bui-events'

export class BuiAsideRef<T, R = any> {
  componentInstance: T

  private readonly _afterOpened = new Subject<void>()
  private readonly _startClosing = new Subject<void>()
  private readonly _afterClosed = new Subject<R | undefined>()

  readonly afterOpened$ = this._afterOpened.asObservable()
  readonly startClosing$ = this._startClosing.asObservable()
  readonly afterClosed$ = this._afterClosed.asObservable()

  private closeFallbackTimeout: number
  private closeResult: R | undefined

  constructor(
    private overlayRef: OverlayRef,
    public containerInstance: _BuiAsideContainerComponent,
    private buiEventsService: BuiEventsService
  ) {
    // Emit when opening animation completes
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'enter'
        ),
        take(1)
      )
      .subscribe(() => {
        this._afterOpened.next()
        this._afterOpened.complete()
      })

    // Emit when closing animation starts
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'start' && event.toState === 'exit'
        ),
        take(1)
      )
      .subscribe(() => {
        this._startClosing.next()
        this._startClosing.complete()
      })

    // Dispose overlay when closing animation is complete
    containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'exit'
        ),
        take(1)
      )
      .subscribe(() => {
        clearTimeout(this.closeFallbackTimeout)
        this.overlayRef.dispose()
      })

    overlayRef.detachments().subscribe(() => {
      this._afterClosed.next(this.closeResult)
      this._afterClosed.complete()
      this.componentInstance = null
      this.overlayRef.dispose()
    })

    if (!containerInstance.config.disableRouterNavigationClose) {
      this.buiEventsService.navigationStart$
        .pipe(takeUntil(this.afterClosed$))
        .subscribe(() => this.close())
    }

    overlayRef
      .keydownEvents()
      .pipe(
        filter(
          (event) =>
            event.key === 'Escape' &&
            !hasModifierKey(event) &&
            !containerInstance.config.disableEscKeyClose
        )
      )
      .subscribe((event) => {
        event.preventDefault()
        this.close()
      })
  }

  close(data?: R): void {
    this.closeResult = data

    this.containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start'),
        take(1)
      )
      .subscribe((event) => {
        this.overlayRef.detachBackdrop()

        this.closeFallbackTimeout = window.setTimeout(() => {
          this.overlayRef.dispose()
        }, event.totalTime + 100)
      })

    this.containerInstance.startExitAnimation()
  }

  updatePosition(): void {
    const strategy = this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy
    strategy.right('right')

    this.overlayRef.updatePosition()
  }

  sendToBack(topLevelSize: _BuiAsideConfigInternal['size']): void {
    this.containerInstance.sendToBack(topLevelSize)
  }

  bringToFront(): void {
    this.containerInstance.bringToFront()
  }

  changeSize(size: _BuiAsideConfigInternal['size']): void {
    this.containerInstance.changeSize(size)
  }
}
