<div class="bui-gallery-inner">
  <div class="gallery-top-bar">
    <bui-button
      *ngIf="isOverlay"
      class="gallery-close-button"
      icon="close"
      (click)="closeOverlay()"
    ></bui-button>
    <h2 *ngIf="title" class="gallery-title">{{ title }}</h2>
  </div>

  <div
    class="bui-gallery-slides"
    [style.width]="isOverlay ? (activeSlideWidth$ | async) : null"
    [style.height]="isOverlay ? (activeSlideHeight$ | async) : null"
  >
    <div
      *ngFor="let slide of slides; index as ix"
      class="bui-gallery-slide"
      [class.bui-gallery-slide--is-active]="ix === activeSlideIndex"
    >
      <bui-loader
        *ngIf="ix === activeSlideIndex && isLoading(ix)"
        variant="dots"
      ></bui-loader>
      <img
        #slideImages
        [src]="slide.imgSrc"
        [alt]="slide.imgAlt || ''"
        class="bui-gallery-slide-image"
        [class.bui-gallery-slide-image--loading]="isLoading(ix)"
        (load)="onImageLoad(ix)"
        (click)="goToNext()"
      />
    </div>
  </div>

  <div
    *ngIf="slides.length > 1"
    class="bui-gallery-buttons"
    [style.width]="activeSlideWidth$ | async"
    [style.height]="activeSlideHeight$ | async"
  >
    <button
      class="bui-gallery-button bui-gallery-button--prev"
      (click)="goToPrev()"
    >
      <bui-icon name="chevron-left"></bui-icon>
    </button>
    <button
      class="bui-gallery-button bui-gallery-button--next"
      (click)="goToNext()"
    >
      <bui-icon name="chevron-right"></bui-icon>
    </button>
  </div>

  <div class="bui-gallery-pagination-text">
    {{ activeSlideIndex + 1 }} of {{ slides.length }}
  </div>
</div>
