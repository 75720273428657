import { InjectionToken } from '@angular/core'
import { BuiToastRef } from './bui-toast/bui-toast-ref'

export const BuiToastVariant = ['info', 'success', 'warning', 'error'] as const
export type BuiToastVariant = (typeof BuiToastVariant)[number]

export interface BuiToastData {
  variant?: BuiToastVariant
  title?: string
  message: string
  links?: string | string[]
}

export interface BuiToastConfig {
  duration?: number
}

export const defaultToastConfig: BuiToastConfig = {
  duration: 5000,
}

export const TOAST_DATA = new InjectionToken<BuiToastData>('TOAST_DATA')
export const BUI_TOAST_CONFIG_TOKEN = new InjectionToken<BuiToastConfig>(
  'BUI_TOAST_CONFIG_TOKEN'
)

export interface _BuiToastItem {
  data: BuiToastData
  config: BuiToastConfig
  ref: BuiToastRef
}

export interface BuiToastCloseData {
  closedOnLinkClick: {
    status: boolean
    linkLabel?: string
  }
}
