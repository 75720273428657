<!-- Inputs -->
<ng-content></ng-content>

<!-- Track -->
<div class="bui-slider-track">
  <div class="bui-slider-track__inactive"></div>
  <div class="bui-slider-track__active">
    <div #trackActive class="bui-slider-track__active-fill"></div>
  </div>
</div>

<!-- Thumbs -->
<bui-slider-thumb
  *ngIf="_isRange"
  [thumbPosition]="1"
  [valueIndicatorText]="startValueIndicatorText"
  [disabled]="disabled"
></bui-slider-thumb>

<bui-slider-thumb
  [thumbPosition]="2"
  [valueIndicatorText]="endValueIndicatorText"
  [disabled]="disabled"
></bui-slider-thumb>
