<button
  #button
  class="bui-button-toggle__element"
  type="button"
  [attr.tabindex]="disabled ? -1 : tabIndex"
  [disabled]="disabled || null"
  (click)="_onButtonClick()"
>
  <ng-content></ng-content>
</button>
