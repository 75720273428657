import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core'
import { BuiSize } from '../bui-size'

const BASE_CSS_CLASS = 'bui-label'

@Component({
  selector: 'bui-label',
  templateUrl: './bui-label.component.html',
  styleUrls: ['./bui-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiLabelComponent {
  @Input() size: BuiSize = 'small'
  @Input() for: string

  @HostBinding('class') get classes() {
    const classes = [BASE_CSS_CLASS]
    classes.push(`${BASE_CSS_CLASS}--size-${this.size}`)

    return classes
  }
}
