import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { _BuiGalleryOverlayContainerComponent } from './bui-gallery-overlay/bui-gallery-overlay-container.component'
import { _BuiGalleryComponent } from './bui-gallery.component'
import { BuiLoaderModule } from '../bui-loader'
import { BuiIconModule } from '../bui-icon'
import { BuiButtonModule } from '../bui-button'

@NgModule({
  declarations: [_BuiGalleryComponent, _BuiGalleryOverlayContainerComponent],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    BuiLoaderModule,
    BuiButtonModule,
    BuiIconModule,
  ],
  exports: [_BuiGalleryComponent],
})
export class BuiGalleryModule {}
