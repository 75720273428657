import { Directive, HostBinding } from '@angular/core'

const BASE_CSS_CLASS = 'bui-form-field-suffix'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[buiFormFieldSuffix]',
})
export class _BuiFormFieldSuffixDirective {
  @HostBinding('class') get classes() {
    return [BASE_CSS_CLASS]
  }
}
