import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  Input,
  HostBinding,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  Output,
  EventEmitter,
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { FocusMonitor } from '@angular/cdk/a11y'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { BuiCheckboxChange } from './bui-checkbox.models'
import { _BuiCheckboxSwitchBase } from '../abstract-classes/bui-checkbox-switch-base.abstract'

const BASE_CSS_CLASS = 'bui-checkbox'

@Component({
  selector: 'bui-checkbox',
  templateUrl: './bui-checkbox.component.html',
  styleUrls: ['./bui-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => _BuiCheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiCheckboxComponent
  extends _BuiCheckboxSwitchBase<BuiCheckboxChange>
  implements AfterViewInit
{
  /**
   * Whether the checkbox is indeterminate. This is also known as "mixed" mode and can be used to
   * represent a checkbox with three states, e.g. a checkbox that represents a nested list of
   * checkable items. Note that whenever checkbox is manually clicked, indeterminate is immediately
   * set to false.
   */
  private _indeterminate = false
  @Input()
  get indeterminate(): boolean {
    return this._indeterminate
  }
  set indeterminate(value: BooleanInput) {
    const changed = value != this._indeterminate
    this._indeterminate = coerceBooleanProperty(value)

    if (changed) {
      this.indeterminateChange.emit(this._indeterminate)
    }

    this._syncIndeterminate(this._indeterminate)
  }

  // Event emitted when the checkbox's `indeterminate` value changes
  @Output() readonly indeterminateChange = new EventEmitter<boolean>()

  @HostBinding(`class.${BASE_CSS_CLASS}--indeterminate`)
  get isIndeterminate(): boolean {
    return this.indeterminate
  }

  constructor(
    elementRef: ElementRef,
    focusMonitor: FocusMonitor,
    changeDetectorRef: ChangeDetectorRef,
    @Attribute('tabindex') tabIndex?: string
  ) {
    super(elementRef, focusMonitor, changeDetectorRef, BASE_CSS_CLASS, tabIndex)
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit()
    this._syncIndeterminate(this._indeterminate)
  }

  protected _createChangeEvent(isChecked: boolean): BuiCheckboxChange {
    return new BuiCheckboxChange(this, isChecked)
  }

  protected override _handleInputClick() {
    if (!this.disabled) {
      // When user manually clicks on the checkbox, `indeterminate` is set to false.
      if (this.indeterminate) {
        Promise.resolve().then(() => {
          this._indeterminate = false
          this.indeterminateChange.emit(this._indeterminate)
        })
      }
    }

    super._handleInputClick()
  }

  // Syncs the indeterminate value with the checkbox in DOM
  private _syncIndeterminate(value: boolean) {
    const nativeCheckbox = this._inputElement

    if (nativeCheckbox) {
      nativeCheckbox.nativeElement.indeterminate = value
    }
  }
}
