import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  ViewChild,
} from '@angular/core'
import { BuiGalleryOverlayConfig } from './bui-gallery-overlay-config'
import { BuiGallerySlide } from '../bui-gallery.models'
import { AnimationEvent } from '@angular/animations'
import { buiGalleryOverlayAnimation } from './bui-gallery-overlay.animation'
import { _BuiGalleryComponent } from '../bui-gallery.component'

@Component({
  templateUrl: './bui-gallery-overlay-container.component.html',
  animations: [buiGalleryOverlayAnimation],
})
export class _BuiGalleryOverlayContainerComponent {
  @ViewChild('gallery', { static: true })
  public galleryInstance: _BuiGalleryComponent

  public slides: BuiGallerySlide[] = []
  public startIndex = 0
  public title: string

  public animationState: 'void' | 'enter' | 'exit' = 'enter'
  public animationStateChanged = new EventEmitter<AnimationEvent>()

  @HostBinding('@buiGalleryOverlayAnimation')
  get buiGalleryOverlayAnimation() {
    return this.animationState
  }

  @HostBinding('class.bui-gallery-container') private readonly buiClass = true

  @HostListener('@buiGalleryOverlayAnimation.start', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event)
  }

  @HostListener('@buiGalleryOverlayAnimation.done', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event)
  }

  constructor(public config: BuiGalleryOverlayConfig) {
    this.slides = config.data.slides
    this.startIndex = config.data.startIndex || 0
    this.title = config.data.title
  }

  public startExitAnimation(): void {
    this.animationState = 'exit'
  }
}
