import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiStepTrackerComponent } from './bui-step-tracker.component'
import { _BuiStepTrackerItemComponent } from './bui-step-tracker-item/bui-step-tracker-item.component'

@NgModule({
  declarations: [_BuiStepTrackerComponent, _BuiStepTrackerItemComponent],
  imports: [CommonModule],
  exports: [_BuiStepTrackerComponent, _BuiStepTrackerItemComponent],
})
export class BuiStepTrackerModule {}
