import {
  Component,
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  HostListener,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal'
import { AnimationEvent } from '@angular/animations'
import { _BuiAsideConfigInternal, BuiAsideLevel } from '../bui-aside-config'
import { buiAsideAnimation } from './bui-aside-container.animation'

const BASE_CSS_CLASS = 'bui-aside-container'

@Component({
  template: `<div cdkTrapFocus cdkTrapFocusAutoCapture>
    <ng-template cdkPortalOutlet></ng-template>
  </div>`,
  styleUrls: ['./bui-aside-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [buiAsideAnimation],
})
export class _BuiAsideContainerComponent extends BasePortalOutlet {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet

  @HostBinding('@buiAsideAnimation') get buiAsideAnimation() {
    return this.animationState
  }

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]
    classes.push(`${BASE_CSS_CLASS}--${this.size}`)

    switch (this.level) {
      case BuiAsideLevel.L1:
        classes.push(`${BASE_CSS_CLASS}--level-L1`)
        break
      case BuiAsideLevel.L2:
        classes.push(`${BASE_CSS_CLASS}--level-L2`)
        break
      case BuiAsideLevel.L3:
        classes.push(`${BASE_CSS_CLASS}--level-L3`)
        break
    }

    if (this.sizeAsBackground) {
      classes.push(`${BASE_CSS_CLASS}--background`)
      classes.push(`${BASE_CSS_CLASS}--background-${this.sizeAsBackground}`)
    }

    return classes
  }

  animationState: 'void' | 'enter' | 'exit' = 'enter'
  animationStateChanged = new EventEmitter<AnimationEvent>()

  private size: _BuiAsideConfigInternal['size']
  private level: _BuiAsideConfigInternal['level']
  private sizeAsBackground: _BuiAsideConfigInternal['size']

  @HostListener('@buiAsideAnimation.start', ['$event']) onAnimationStart(
    event: AnimationEvent
  ) {
    this.animationStateChanged.emit(event)
  }

  @HostListener('@buiAsideAnimation.done', ['$event']) onAnimationDone(
    event: AnimationEvent
  ) {
    this.animationStateChanged.emit(event)
  }

  constructor(public config: _BuiAsideConfigInternal) {
    super()
    this.size = config.size
    this.level = config.level
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this.throwIfAttached()
    return this.portalOutlet.attachComponentPortal(portal)
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this.throwIfAttached()
    return this.portalOutlet.attachTemplatePortal(portal)
  }

  startExitAnimation(): void {
    this.animationState = 'exit'
  }

  sendToBack(topLevelSize: _BuiAsideConfigInternal['size']): void {
    this.sizeAsBackground = topLevelSize
  }

  bringToFront(): void {
    this.sizeAsBackground = undefined
  }

  changeSize(size: _BuiAsideConfigInternal['size']): void {
    this.size = size
  }

  private throwIfAttached(): void {
    if (this.portalOutlet.hasAttached()) {
      throw new Error(
        'Attempting to attach aside content after it has already been attached'
      )
    }
  }
}
