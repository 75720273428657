import { NgModule } from '@angular/core'
import { _BuiDropdownComponent } from './bui-dropdown.component'
import { CommonModule } from '@angular/common'
import { OverlayModule } from '@angular/cdk/overlay'
import { _BuiDropdownItemComponent } from './bui-dropdown-item/bui-dropdown-item.component'
import { _BuiDropdownTriggerDirective } from './bui-dropdown-trigger.directive'

@NgModule({
  declarations: [
    _BuiDropdownComponent,
    _BuiDropdownItemComponent,
    _BuiDropdownTriggerDirective,
  ],
  imports: [CommonModule, OverlayModule],
  exports: [
    _BuiDropdownComponent,
    _BuiDropdownItemComponent,
    _BuiDropdownTriggerDirective,
  ],
})
export class BuiDropdownModule {}
