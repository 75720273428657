import { Component, HostBinding } from '@angular/core'

const BASE_CSS_CLASS = 'bui-table-footer'

@Component({
  selector: 'bui-table-footer',
  templateUrl: './bui-table-footer.component.html',
  styleUrls: ['./bui-table-footer.component.scss'],
})
export class _BuiTableFooterComponent {
  @HostBinding('class') get classes(): string[] {
    return [BASE_CSS_CLASS]
  }
}
