import { Component, HostBinding, Input } from '@angular/core'

const BASE_CSS_CLASS = 'bui-form-field'

@Component({
  selector: 'bui-form-field',
  styleUrls: ['bui-form-field.component.scss'],
  templateUrl: 'bui-form-field.component.html',
})
export class _BuiFormFieldComponent {
  @Input() isErrorHidden = false

  @HostBinding('class') get classes() {
    return [BASE_CSS_CLASS]
  }
}
