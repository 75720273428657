/**
 * Transforms a value (a string or a number) to an integer.
 * Intended to be used as a transform function of an input.
 *
 * @Input({ transform: integerAttribute }) step: number
 *
 */
export function integerAttribute(value: unknown): number {
  return Number.isNaN(parseInt(value?.toString()))
    ? undefined
    : parseInt(value.toString())
}
