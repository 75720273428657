import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { A11yModule } from '@angular/cdk/a11y'
import { _BuiButtonComponent } from './bui-button.component'
import { BuiIconModule } from '../bui-icon'

@NgModule({
  declarations: [_BuiButtonComponent],
  imports: [CommonModule, RouterModule, A11yModule, BuiIconModule],
  exports: [_BuiButtonComponent],
})
export class BuiButtonModule {}
