import { Subject } from 'rxjs'
import { _BuiToastInternalService } from '../bui-toast-internal.service'
import { BuiToastCloseData } from '../bui-toast.models'

export class BuiToastRef {
  private readonly _onClose = new Subject<void>()
  public readonly onClose$ = this._onClose.asObservable()

  private readonly _onLinkClick = new Subject<
    BuiToastCloseData['closedOnLinkClick']
  >()
  public readonly onLinkClick$ = this._onLinkClick.asObservable()

  constructor(
    private readonly toastIndex: number,
    private readonly internalService: _BuiToastInternalService
  ) {}

  public close(data?: BuiToastCloseData): void {
    if (data?.closedOnLinkClick?.status) {
      this._onLinkClick.next(data.closedOnLinkClick)
    }
    this._onLinkClick.complete()

    this.internalService.removeToast(this.toastIndex)

    this._onClose.next()
    this._onClose.complete()
  }
}
