<div
  class="bui-content-loader__inner"
  [class.bui-content-loader__inner--loading]="isLoading"
>
  <ng-container *ngIf="isLoading">
    <div class="bui-content-loader__overlay"></div>
    <bui-loader
      class="bui-content-loader__loader"
      variant="circle"
    ></bui-loader>
  </ng-container>
  <ng-content></ng-content>
</div>
