<div class="bui-radio-inner">
  <label class="bui-radio-label" [for]="inputId">
    <ng-content></ng-content>
  </label>
  <div class="bui-radio-button">
    <input
      #input
      class="bui-radio-button__native-control"
      type="radio"
      [id]="inputId"
      [checked]="checked"
      [disabled]="disabled"
      [attr.name]="name"
      [attr.value]="value"
      [required]="required"
      (change)="_onInputInteraction($event)"
    />
    <div class="bui-radio-button__interface"></div>
    <div
      class="bui-radio-button__touch-target"
      (click)="_onTouchTargetClick($event)"
    ></div>
  </div>
</div>
