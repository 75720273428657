import { Injectable, Inject } from '@angular/core'
import {
  BuiToastConfig,
  BUI_TOAST_CONFIG_TOKEN,
  BuiToastData,
} from './bui-toast.models'
import { _BuiToastInternalService } from './bui-toast-internal.service'
import { BuiToastRef } from './bui-toast/bui-toast-ref'

@Injectable({ providedIn: 'root' })
export class BuiToastService {
  constructor(
    private internalService: _BuiToastInternalService,
    @Inject(BUI_TOAST_CONFIG_TOKEN) readonly toastConfig: BuiToastConfig
  ) {}

  public open(data: BuiToastData, userConfig?: BuiToastConfig): BuiToastRef {
    this.internalService.createOverlay()

    const duration =
      data.variant === 'error' ? 30000 : this.toastConfig.duration
    const config = { ...this.toastConfig, duration, ...userConfig }
    const toastIndex = this.internalService.toastsCount
    const ref = new BuiToastRef(toastIndex, this.internalService)

    this.internalService.addToast({ data, config, ref })
    return ref
  }
}
