import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiMessageComponent } from './bui-message.component'
import {
  _BuiMessageLinksDirective,
  _BuiMessageTitleDirective,
} from './bui-message-directives'

@NgModule({
  declarations: [
    _BuiMessageComponent,
    _BuiMessageTitleDirective,
    _BuiMessageLinksDirective,
  ],
  imports: [CommonModule],
  exports: [
    _BuiMessageComponent,
    _BuiMessageTitleDirective,
    _BuiMessageLinksDirective,
  ],
})
export class BuiMessageModule {}
