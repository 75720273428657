import {
  Directive,
  Input,
  TemplateRef,
  ComponentRef,
  ElementRef,
  HostListener,
  OnChanges,
  HostBinding,
  Renderer2,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core'
import { _BuiTooltipComponent } from './bui-tooltip.component'
import {
  Overlay,
  OverlayRef,
  FlexibleConnectedPositionStrategy,
  ScrollDispatcher,
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { hasModifierKey } from '@angular/cdk/keycodes'
import { BuiOverlayPosition, setConnectedPosition } from '../util/set-position'
import { delay, filter, fromEvent, merge, Subscription } from 'rxjs'
import { _BuiTooltipInternalService } from './bui-tooltip.service'
import { BuiEventsService } from '../bui-events/bui-events.service'

const BASE_CSS_CLASS = 'bui-tooltip'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[buiTooltip]',
  exportAs: 'buiTooltip',
})
export class _BuiTooltipDirective implements OnChanges, OnDestroy {
  @Input() buiTooltip: string | TemplateRef<unknown>
  @Input() buiTooltipShowsHtmlContent = false
  @Input() buiTooltipPosition: BuiOverlayPosition = 'top'
  @Input() buiTooltipTextDecoration: boolean
  @Input() buiTooltipTextAlign: 'left' | 'center' | 'right' = 'center'
  @Input() buiTooltipDisabled: boolean
  @Input() buiTooltipCursorStyle: 'default' | 'help' | 'pointer' = 'pointer'
  @Input() buiTooltipSize: 'sm' | 'md' | 'xl' = 'sm'
  @Input() buiTooltipStyle: 'light' | 'dark' = 'dark'
  @Input() buiTooltipShowOn: 'hover' | 'click' | 'hoverAndStayOpenOnClick' =
    'hover'
  @Input() buiTooltipOffsetX = 0
  @Input() buiTooltipOffsetY = 0
  @Input() buiTooltipId: string = Math.random().toString(36).substring(2)

  @HostBinding('style.cursor') hostCursor: string
  @HostBinding('style.borderBottom') hostBorderBottom: string
  @HostBinding('style.textAlign') hostTextAlign: string
  @HostBinding(`class.${BASE_CSS_CLASS}-host`) get hostClass(): boolean {
    return this.hasTooltip
  }

  private overlayRef: OverlayRef
  private subscription: Subscription
  private get hasTooltip(): boolean {
    return this.buiTooltip && !this.buiTooltipDisabled
  }

  // tracks "stuck state" for 'click' and 'hoverAndStayOpenOnClick' modes
  private get isAnyTooltipStuck(): boolean {
    return !!this.buiTooltipService.stuckTooltipId
  }
  private get isThisTooltipStuck(): boolean {
    return this.buiTooltipService.stuckTooltipId === this.buiTooltipId
  }
  private set stuckTooltip(value: boolean) {
    this.buiTooltipService.stuckTooltipId = value
      ? this.buiTooltipId
      : undefined
  }

  @HostListener('click') onClick() {
    if (this.hasTooltip) {
      if (
        this.buiTooltipShowOn === 'click' ||
        this.buiTooltipShowOn === 'hoverAndStayOpenOnClick'
      ) {
        if (this.isThisTooltipStuck) {
          this.hide()
        } else {
          this.show()
          this.stuckTooltip = true
        }
      }
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.hasTooltip) {
      if (this.buiTooltipShowOn === 'hover') {
        this.show()
      }
      if (
        this.buiTooltipShowOn === 'hoverAndStayOpenOnClick' &&
        !this.isAnyTooltipStuck
      ) {
        this.show()
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.hasTooltip) {
      if (this.buiTooltipShowOn === 'hover') {
        this.hide()
      }
      if (
        this.buiTooltipShowOn === 'hoverAndStayOpenOnClick' &&
        !this.isAnyTooltipStuck
      ) {
        this.hide()
      }
    }
  }

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private renderer: Renderer2,
    private scrollDispatcher: ScrollDispatcher,
    private buiTooltipService: _BuiTooltipInternalService,
    private buiEventsService: BuiEventsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    if (this.hasTooltip) {
      this.overlayRef?.dispose()

      this.overlayRef = this.overlay.create({
        positionStrategy: this.setPositionStrategy(this.buiTooltipPosition),
        disposeOnNavigation: true,
      })

      this.hostCursor = this.buiTooltipCursorStyle
      this.hostTextAlign = this.buiTooltipTextAlign

      if (this.buiTooltipTextDecoration) {
        this.hostBorderBottom = '1px dotted currentColor'
      }
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose()
  }

  private bindCloseEvents(): void {
    if (
      this.buiTooltipShowOn === 'click' ||
      this.buiTooltipShowOn === 'hoverAndStayOpenOnClick'
    ) {
      this.subscription = merge(
        this.scrollDispatcher.ancestorScrolled(this.elementRef),
        fromEvent(window, 'resize'),
        this.buiEventsService.navigationStart$,
        this.overlayRef.outsidePointerEvents(),
        this.overlayRef
          .keydownEvents()
          .pipe(
            filter((event) => event.key === 'Escape' && !hasModifierKey(event))
          )
      )
        .pipe(delay(0))
        .subscribe(() => this.hide())
    } else {
      this.subscription = this.scrollDispatcher
        .ancestorScrolled(this.elementRef)
        .pipe(delay(0))
        .subscribe(() => this.hide())
    }
  }

  private unbindCloseEvents(): void {
    this.subscription?.unsubscribe()
  }

  private show(): void {
    this.hide()
    this.bindCloseEvents()
    const tooltipPortal = this.createComponentPortal()
    const tooltipRef: ComponentRef<_BuiTooltipComponent> =
      this.overlayRef.attach(tooltipPortal)

    this.addCssClasses(tooltipRef)
    this.addOffset(tooltipRef)

    if (this.buiTooltip instanceof TemplateRef) {
      tooltipRef.instance.template = this.buiTooltip
    } else {
      this.buiTooltipShowsHtmlContent
        ? (tooltipRef.instance.htmlContent = this.buiTooltip)
        : (tooltipRef.instance.text = this.buiTooltip)
    }
  }

  private hide(): void {
    this.overlayRef.detach()
    if (this.isThisTooltipStuck) {
      this.stuckTooltip = false
    }

    const host = this.elementRef.nativeElement
    this.renderer.removeClass(host, `${BASE_CSS_CLASS}-host--active`)
    this.unbindCloseEvents()
    this.cdr.detectChanges()
  }

  private addOffset(tooltipRef: ComponentRef<_BuiTooltipComponent>): void {
    if (this.buiTooltipOffsetX || this.buiTooltipOffsetY) {
      const el = tooltipRef.location.nativeElement
      this.renderer.setStyle(
        el,
        'transform',
        `translate(${this.buiTooltipOffsetX}px, ${this.buiTooltipOffsetY}px)`
      )
    }
  }

  private addCssClasses(tooltipRef: ComponentRef<_BuiTooltipComponent>): void {
    const el = tooltipRef.location.nativeElement
    this.renderer.addClass(el, BASE_CSS_CLASS)
    this.renderer.addClass(el, `${BASE_CSS_CLASS}--${this.buiTooltipPosition}`)
    this.renderer.addClass(el, `${BASE_CSS_CLASS}--${this.buiTooltipSize}`)
    this.renderer.addClass(el, `${BASE_CSS_CLASS}--${this.buiTooltipStyle}`)

    const host = this.elementRef.nativeElement
    this.renderer.addClass(host, `${BASE_CSS_CLASS}-host--active`)
  }

  private setPositionStrategy(
    position: BuiOverlayPosition
  ): FlexibleConnectedPositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([setConnectedPosition(position)])
  }

  private createComponentPortal(): ComponentPortal<_BuiTooltipComponent> {
    return new ComponentPortal(_BuiTooltipComponent)
  }
}
