import { BuiIconType } from '../bui-icon'

export interface BuiTab<T = string | number> {
  value: T
  label: string
  icon?: Extract<BuiIconType, 'alert'>
}

export interface BuiTabEvent {
  firstChange: boolean
  value: BuiTab['value']
}

export type BuiTabMode = 'useRouter' | 'useQueryParams' | 'noop'

export const BUI_TAB_QUERY_PARAM_KEY = 'tab'
