import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core'
import { parse, isValid, format, addMonths } from 'date-fns'
import { buiDatepickerAnimations } from '../bui-datepicker.animations'
import { _BuiDatepickerInputBase } from '../bui-datepicker-base.abstract'
import { _BuiDatepickerInternalService } from '../bui-datepicker-internal.service'
import {
  BuiDatepickerType,
  _BuiDatepickerMonthPanelType,
} from '../bui-datepicker.models'

@Component({
  selector: 'bui-date-single-input',
  templateUrl: '../bui-datepicker-base.component.html',
  styleUrls: ['../bui-datepicker-base.component.scss'],
  animations: [
    buiDatepickerAnimations.transformPanel,
    buiDatepickerAnimations.transformPanelWrap,
  ],
  providers: [
    _BuiDatepickerInternalService,
    {
      provide: 'BUI_DATE_SINGLE_INPUT',
      useExisting: _BuiDateSingleInputComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDateSingleInputComponent extends _BuiDatepickerInputBase<string> {
  @Input() minDate: Date | string
  @Input() maxDate: Date | string

  get label(): string {
    return isValid(this.selectedDate)
      ? format(this.selectedDate, this.displayDateFormat)
      : this.placeholder
  }

  get isEmpty(): boolean {
    return this.selectedDate === null
  }

  // selectedDate reflects active selection in the panel which can
  // differ from ngControl.value (user needs to apply the changes)
  set selectedDate(value: Date) {
    this._selectedDate = value
    this.setActiveDateSelection()
  }
  get selectedDate(): Date {
    return this._selectedDate
  }
  private _selectedDate: Date = null

  getDatepickerType(): BuiDatepickerType {
    return 'single-date'
  }

  setActiveDateSelection(): void {
    this.internalService.activeDateSelection = this.selectedDate
  }

  updateValue(value: string): void {
    const from = parse(value, this.dateFormat, new Date())
    this.selectedDate = isValid(from) ? new Date(from) : null
    this._setMonthPanels()
  }

  onDayClicked(value: Date): void {
    this.internalService.untouchedSincePanelOpened = false
    this.selectedDate = new Date(value)
    this.internalService.triggerUIStateChange()
  }

  getValueToApply(): string {
    return this.selectedDate ? format(this.selectedDate, this.dateFormat) : null
  }

  getEmptyValue(): string {
    return null
  }

  private _setMonthPanels(): void {
    if (this.dualPanels) {
      this.selectedDate
        ? this._setMonthPanelDate('panelStart', new Date(this.selectedDate))
        : this._setMonthPanelDate('panelStart', new Date())

      this.selectedDate
        ? this._setMonthPanelDate('panelEnd', new Date(this.selectedDate))
        : this._setMonthPanelDate('panelEnd', addMonths(new Date(), 1))
    } else {
      this.selectedDate
        ? this._setMonthPanelDate('panelSingle', new Date(this.selectedDate))
        : this._setMonthPanelDate('panelSingle', new Date())
    }
  }

  private _setMonthPanelDate(
    panelType: _BuiDatepickerMonthPanelType,
    month: Date
  ) {
    return this.internalService.setMonthPanelDate(panelType, month)
  }
}
