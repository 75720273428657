import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiLabelComponent } from './bui-label.component'

@NgModule({
  declarations: [_BuiLabelComponent],
  imports: [CommonModule],
  exports: [_BuiLabelComponent],
})
export class BuiLabelModule {}
