/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostBinding,
  HostListener,
  ViewEncapsulation,
} from '@angular/core'
import {
  AccordionState,
  BuiAccordionItemComponent,
} from '../bui-accordion-item/bui-accordion-item.component'

@Directive({
  selector: 'bui-accordion-title, [buiAccordionTitle]',
  exportAs: 'buiAccordionTitle',
  host: { class: 'bui-accordion-title' },
})
export class BuiAccordionTitleDirective {}

@Directive({
  selector: 'bui-accordion-description, [buiAccordionDescription]',
  exportAs: 'buiAccordionDescription',
  host: { class: 'bui-accordion-description' },
})
export class BuiAccordionDescriptionDirective {}

@Component({
  selector: 'bui-accordion-header',
  templateUrl: 'bui-accordion-header.component.html',
  styleUrls: ['bui-accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'bui-accordion-header' },
})
export class BuiAccordionHeaderComponent {
  @HostListener('click') toggleAccordion(): void {
    this._parent.emitChange()
  }

  @HostBinding('class.bui-accordion-header--expanded')
  get isExpanded(): boolean {
    return this._getExpandedState() === 'expanded'
  }

  constructor(private _parent: BuiAccordionItemComponent) {}

  public _getExpandedState(): AccordionState {
    return this._parent.animationState
  }
}
