import {
  Input,
  ElementRef,
  HostBinding,
  Component,
  ChangeDetectionStrategy,
  Renderer2,
  NgZone,
  AfterViewInit,
} from '@angular/core'
import { fromEvent, merge, Observable, Subject } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { CdkScrollable } from '@angular/cdk/scrolling'
import { BuiEventsService } from '../../bui-events'

const BASE_CSS_CLASS = 'bui-sticky-table'

@Component({
  selector: 'bui-sticky-table',
  templateUrl: 'bui-sticky-table.component.html',
  styleUrls: ['bui-sticky-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [CdkScrollable],
})
export class BuiStickyTableComponent implements AfterViewInit {
  @Input() type: 'firstCol' | 'lastCol' | 'firstAndLast' | 'lastTwoCols' =
    'lastCol'

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.isFirstColSticky) {
      classes.push(`${BASE_CSS_CLASS}--first-col-sticky`)
    }
    if (this.isLastColSticky) {
      classes.push(`${BASE_CSS_CLASS}--last-col-sticky`)
    }
    if (this.areLastTwoColsSticky) {
      classes.push(`${BASE_CSS_CLASS}--last-two-cols-sticky`)
    }

    return classes
  }

  public get isFirstColSticky(): boolean {
    return this.type === 'firstAndLast' || this.type === 'firstCol'
  }

  public get isLastColSticky(): boolean {
    return this.type === 'firstAndLast' || this.type === 'lastCol'
  }

  public get areLastTwoColsSticky(): boolean {
    return this.type === 'lastTwoCols'
  }

  private readonly elementScroll$ = fromEvent(
    this.hostElem.nativeElement,
    'scroll'
  )

  private readonly contentChanged$ = new Subject<void>()

  private events$ = merge(
    this.elementScroll$,
    this.buiEventsService.windowResize$,
    this.contentChanged$
  ).pipe(shareReplay(1))

  public readonly showFirstColShadow$: Observable<boolean> = this.events$.pipe(
    map(() => this.hostElem.nativeElement.scrollLeft !== 0)
  )

  public readonly showLastColShadow$: Observable<boolean> = this.events$.pipe(
    map(() => {
      const el = this.hostElem.nativeElement
      const isMaxScrollLeft =
        el.offsetWidth + el.scrollLeft + 1 >= el.scrollWidth
      return !isMaxScrollLeft
    })
  )

  public get height(): number {
    return this.hostElem.nativeElement?.offsetHeight || 0
  }

  constructor(
    private hostElem: ElementRef,
    private buiEventsService: BuiEventsService,
    private renderer: Renderer2,
    private zone: NgZone
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.onContentChanged())
  }

  public scrollToTop(): void {
    this.renderer.setProperty(this.hostElem.nativeElement, 'scrollTop', 0)
  }

  public onContentChanged(): void {
    // Seems that (cdkObserveContent) emits outside of the zone
    // https://github.com/angular/components/issues/11280
    this.zone.run(() => this.contentChanged$.next())
  }
}
