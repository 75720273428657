import { _BuiSwitchComponent } from './bui-switch.component'

// Change event object emitted by switch
export class BuiSwitchChange {
  constructor(source: _BuiSwitchComponent, checked: boolean) {
    this.source = source
    this.checked = checked
  }

  // The source control of the event
  source: _BuiSwitchComponent

  // The new `checked` value of the control
  checked: boolean
}
