import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  inject,
  Input,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { injectDestroy } from '../../util'
import { _BuiDatepickerInternalService } from '../bui-datepicker-internal.service'
import { FormControl } from '@angular/forms'
import { merge } from 'rxjs'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { format } from 'date-fns'

const BASE_CSS_CLASS = 'bui-datepicker-time'

@Component({
  selector: 'bui-datepicker-time',
  templateUrl: 'bui-datepicker-time.component.html',
  styleUrls: ['bui-datepicker-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiDatepickerTimeComponent implements OnInit {
  protected readonly destroy$ = injectDestroy()
  private readonly internalService = inject(_BuiDatepickerInternalService)
  private readonly changeDetectorRef = inject(ChangeDetectorRef)

  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
    this._disabled ? this._disableForm() : this._enableForm()
    this.changeDetectorRef.markForCheck()
  }
  private _disabled = false

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.disabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }

    return classes
  }

  formControlHours: FormControl<string>
  formControlMinutes: FormControl<string>
  formControlIsPM: FormControl<boolean>

  constructor() {
    const { hours, minutes, isPM } = this._getTimeValues()

    this.formControlHours = new FormControl(hours)
    this.formControlMinutes = new FormControl(minutes)
    this.formControlIsPM = new FormControl(isPM)
  }

  ngOnInit(): void {
    merge(
      this.formControlHours.valueChanges,
      this.formControlMinutes.valueChanges,
      this.formControlIsPM.valueChanges
    )
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        const hours = this.formControlHours.value
        const minutes = this.formControlMinutes.value
        const apm = this.formControlIsPM.value ? 'PM' : 'AM'
        this.internalService.onTimeChange(`${hours}:${minutes} ${apm}`)
      })
  }

  onHoursBlur(): void {
    const hours = parseInt(this.formControlHours.value)

    if (Number.isNaN(hours)) {
      this.formControlHours.setValue('12')
    }
  }

  onMinutesBlur(): void {
    const minutes = parseInt(this.formControlMinutes.value)

    if (Number.isNaN(minutes)) {
      this.formControlMinutes.setValue('00')
    } else if (minutes < 10) {
      this.formControlMinutes.setValue(`0${minutes}`, { emitEvent: false })
    }
  }

  onToggleKeydown(event: KeyboardEvent): void {
    this._blockNavKeyPropagation(event)
  }

  onInputKeydown(event: KeyboardEvent, field: 'hours' | 'minutes'): void {
    this._blockNavKeyPropagation(event)

    if (event.key === 'ArrowUp') {
      field === 'hours' ? this._stepUpHours() : this._stepUpMinutes()
    } else if (event.key === 'ArrowDown') {
      field === 'hours' ? this._stepDownHours() : this._stepDownMinutes()
    }
  }

  private _getTimeValues(): {
    hours: string
    minutes: string
    isPM: boolean
  } {
    const activeDate = this.internalService.getActiveDateSelection()
    return activeDate
      ? {
          hours: format(activeDate, 'h'),
          minutes: format(activeDate, 'mm'),
          isPM: activeDate.getHours() >= 12,
        }
      : { hours: '12', minutes: '00', isPM: false }
  }

  private _blockNavKeyPropagation(event: KeyboardEvent): void {
    if (this._isNavKey(event.key)) {
      event.stopPropagation()
    }
  }

  private _isNavKey(key: string): boolean {
    return key === 'Tab' || key === ' '
  }

  private _stepUpHours(): void {
    const hours = parseInt(this.formControlHours.value) + 1
    this.formControlHours.setValue(hours === 13 ? '1' : `${hours}`)
  }

  private _stepDownHours(): void {
    const hours = parseInt(this.formControlHours.value) - 1
    this.formControlHours.setValue(hours === 0 ? '12' : `${hours}`)
  }

  private _stepUpMinutes(): void {
    let minutes = parseInt(this.formControlMinutes.value) + 1

    if (minutes === 60) {
      minutes = 0
    }

    this.formControlMinutes.setValue(
      minutes < 10 ? `0${minutes}` : `${minutes}`
    )
  }

  private _stepDownMinutes(): void {
    let minutes = parseInt(this.formControlMinutes.value) - 1

    if (minutes === -1) {
      minutes = 59
    }

    this.formControlMinutes.setValue(
      minutes < 10 ? `0${minutes}` : `${minutes}`
    )
  }

  private _disableForm(): void {
    this.formControlHours.disable()
    this.formControlMinutes.disable()
    this.formControlIsPM.disable()
  }

  private _enableForm(): void {
    this.formControlHours.enable()
    this.formControlMinutes.enable()
    this.formControlIsPM.enable()
  }
}
