import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiSwitchComponent } from './bui-switch.component'

@NgModule({
  declarations: [_BuiSwitchComponent],
  imports: [CommonModule],
  exports: [_BuiSwitchComponent],
})
export class BuiSwitchModule {}
