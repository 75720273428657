import { CdkScrollable } from '@angular/cdk/scrolling'
import { Directive, HostBinding, Input } from '@angular/core'

// Use this <bui-aside-layout /> to wrap aside's header/content/footer
// and ensure relevant layout css gets applied
@Directive({
  selector: 'bui-aside-layout',
})
export class _BuiAsideLayoutDirective {
  @Input() hasPaddingBottom = true
  @HostBinding('class.bui-aside-layout') private layoutClass = true
}

@Directive({
  selector: 'bui-aside-header',
})
export class _BuiAsideHeaderDirective {
  @Input() variant: 'white' | 'grey' = 'grey'
  @Input() hasPaddingBottom = true
  @Input() paddingSize: 'small' | 'large' = 'small'

  @HostBinding('class') private get classes(): string[] {
    const BASE_CSS_CLASS = 'bui-aside-header'
    const classes = [
      BASE_CSS_CLASS,
      `${BASE_CSS_CLASS}--variant-${this.variant}`,
      `${BASE_CSS_CLASS}--${this.paddingSize}-padding`,
    ]

    if (!this.hasPaddingBottom) {
      classes.push(`${BASE_CSS_CLASS}--no-padding-bottom`)
    }

    return classes
  }
}

@Directive({
  selector: 'bui-aside-content',
  hostDirectives: [CdkScrollable],
})
export class _BuiAsideContentDirective {
  @HostBinding('class.bui-aside-content') private contentClass = true
}

@Directive({
  selector: 'bui-aside-footer',
})
export class _BuiAsideFooterDirective {
  @HostBinding('class.bui-aside-footer') private footerClass = true
}
