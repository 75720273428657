import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { _BuiNavlistComponent } from './bui-navlist.component'
import { _BuiNavlistItemComponent } from './bui-navlist-item/bui-navlist-item.component'
import { BuiIconModule } from '../bui-icon'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [_BuiNavlistComponent, _BuiNavlistItemComponent],
  imports: [CommonModule, RouterModule, BuiIconModule],
  exports: [_BuiNavlistComponent, _BuiNavlistItemComponent],
})
export class BuiNavlistModule {}
