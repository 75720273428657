import {
  Directive,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
} from '@angular/core'
import { Subject } from 'rxjs'
import {
  BuiTableSortDirection,
  BuiTableSortable,
  BuiTableSortableInternal,
} from './bui-table-sort.models'

@Directive({
  selector: 'bui-table-sort, [buiTableSort]',
})
export class _BuiTableSortDirective implements OnDestroy, OnChanges {
  // accepts plain objects
  // with one key / value pair e.g.
  // { completedFuelingAt: 1 } -> sort by completedFuelingAt ascending
  // { completedFuelingAt: 'asc' } -> same as example before
  // active and direction properties are set implictly in this case
  @Input() public set buiTableSortActive(value: BuiTableSortable) {
    const sortable = this._serializeSortable(value)

    this.activeId = sortable.id
    this.direction = sortable.direction
  }

  @Input('buiTableSortDisabled') disabled = false

  public activeId: string
  public direction: BuiTableSortDirection

  @Output() readonly sortChange = new EventEmitter<BuiTableSortable>()

  // notifies child sortables that change happened
  public stateChanges$ = new Subject<void>()

  ngOnChanges(): void {
    this.stateChanges$.next()
  }

  ngOnDestroy(): void {
    this.stateChanges$.complete()
  }

  public sort(sortable: BuiTableSortableInternal) {
    this.activeId = sortable.id
    this.direction = sortable.direction

    this.sortChange.emit(this._deserializeSortable(sortable))
  }

  private _serializeSortable(
    sortable: BuiTableSortable
  ): BuiTableSortableInternal {
    const [id] = Object.keys(sortable)
    let direction = sortable[id]

    if (direction === 1 || direction === -1) {
      direction = direction === 1 ? 'asc' : 'desc'
    }

    return { id, direction }
  }

  private _deserializeSortable({
    id,
    direction,
  }: BuiTableSortableInternal): BuiTableSortable {
    return { [id]: direction === 'asc' ? 1 : -1 }
  }
}
