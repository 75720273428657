import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { _BuiLoaderComponent } from './bui-loader.component'
import { _BuiContentLoaderComponent } from './bui-content-loader/bui-content-loader.component'

@NgModule({
  declarations: [_BuiLoaderComponent, _BuiContentLoaderComponent],
  imports: [CommonModule],
  exports: [_BuiLoaderComponent, _BuiContentLoaderComponent],
})
export class BuiLoaderModule {}
