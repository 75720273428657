<div
  *ngIf="animationState$ | async as animationState"
  class="bui-toast bui-toast--{{ variant || 'info' }}"
  [@buiToastAnimation]="animationState"
  (@buiToastAnimation.done)="onAnimationEnd($event)"
>
  <bui-button
    class="bui-toast__close"
    icon="close"
    (click)="onCloseButtonClick()"
  ></bui-button>
  <div *ngIf="title" class="bui-toast__title">{{ title }}</div>
  <div *ngIf="message" class="bui-toast__message">{{ message }}</div>
  <div *ngIf="links.length" class="bui-toast__links">
    <a *ngFor="let link of links" (click)="onLinkClick(link)">{{ link }}</a>
  </div>
</div>
