import { Overlay, ScrollStrategy } from '@angular/cdk/overlay'
import { InjectionToken } from '@angular/core'
import { _BuiSelectComponent } from './bui-select.component'

export const BUI_SELECT_SCROLL_STRATEGY = new InjectionToken<
  () => ScrollStrategy
>('bui-select-scroll-strategy')

export function BUI_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition()
}

export const BUI_SELECT_SCROLL_STRATEGY_PROVIDER = {
  provide: BUI_SELECT_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: BUI_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY,
}

export const BUI_PARENT_OPTION_COMPONENT =
  new InjectionToken<_BuiSelectComponent>('BUI_PARENT_OPTION_COMPONENT')
