<a
  *ngIf="route"
  cdkMonitorSubtreeFocus
  class="bui-link__element"
  [routerLink]="[route]"
  [queryParams]="queryParams"
>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<a
  *ngIf="link"
  cdkMonitorSubtreeFocus
  class="bui-link__element"
  [href]="link"
  target="_blank"
>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<button
  class="bui-link__element"
  cdkMonitorSubtreeFocus
  type="button"
  *ngIf="!route && !link"
>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</button>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
