<a
  *ngIf="route; else buttonTpl"
  cdkMonitorSubtreeFocus
  class="bui-button-element"
  [routerLink]="[route]"
  [queryParams]="queryParams"
  (click)="onButtonElementClick($event)"
>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<ng-template #buttonTpl>
  <button
    cdkMonitorSubtreeFocus
    class="bui-button-element"
    [type]="type"
    [disabled]="disabled"
    [tabindex]="disabled ? '-1' : null"
    (click)="onButtonElementClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-template>

<ng-template #contentTpl>
  <span class="bui-button-inner" [class.bui-button-loader]="loading">
    <span [ngSwitch]="variant">
      <ng-container *ngSwitchCase="'icon'">
        <bui-icon [name]="icon"></bui-icon>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <bui-icon
          *ngIf="prefixIcon"
          [name]="prefixIcon"
          class="bui-button-prefix"
        ></bui-icon>
        <ng-content></ng-content>
        <bui-icon
          *ngIf="suffixIcon"
          [name]="suffixIcon"
          class="bui-button-suffix"
        ></bui-icon>
      </ng-container>
    </span>
  </span>
</ng-template>
