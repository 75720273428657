<ng-container *ngFor="let toastItem of toasts$ | async">
  <bui-toast
    *ngIf="toastItem"
    [duration]="toastItem.config.duration"
    [ref]="toastItem.ref"
    [variant]="toastItem.data.variant"
    [title]="toastItem.data.title"
    [message]="toastItem.data.message"
    [links]="toastItem.data.links"
  ></bui-toast>
</ng-container>
