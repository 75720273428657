import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { _BuiPaginationComponent } from './bui-pagination.component'
import { BuiIconModule } from '../bui-icon/bui-icon.module'
import { BuiButtonModule } from '../bui-button/bui-button.module'
import { BuiInputModule } from '../bui-input/bui-input.module'

@NgModule({
  declarations: [_BuiPaginationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BuiIconModule,
    BuiButtonModule,
    BuiInputModule,
  ],
  exports: [_BuiPaginationComponent],
})
export class BuiPaginationModule {}
