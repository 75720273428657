import { Directive, HostBinding } from '@angular/core'

@Directive({
  selector: 'bui-message-title',
})
export class _BuiMessageTitleDirective {
  @HostBinding('class.bui-message-title') private titleClass = true
}

@Directive({
  selector: 'bui-message-links',
})
export class _BuiMessageLinksDirective {
  @HostBinding('class.bui-message-links') private linksClass = true
}
