import {
  Injectable,
  Injector,
  InjectionToken,
  TemplateRef,
  Optional,
  Inject,
} from '@angular/core'
import {
  Overlay,
  ComponentType,
  OverlayRef,
  OverlayConfig,
} from '@angular/cdk/overlay'
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { _BuiAsideContainerComponent } from './bui-aside-container/bui-aside-container.component'
import { BuiAsideRef } from './bui-aside-ref'
import {
  BuiAsideConfig,
  _BuiAsideConfigInternal,
  BuiAsideLevel,
} from './bui-aside-config'
import { Observable, combineLatest, map, take } from 'rxjs'
import { BuiEventsService } from '../bui-events'

export const BUI_ASIDE_DATA = new InjectionToken<any>('BuiAsideData')
export const BUI_ASIDE_DEFAULT_OPTIONS = new InjectionToken<BuiAsideConfig>(
  'bui-aside-default-options'
)

@Injectable({ providedIn: 'root' })
export class BuiAsideService {
  private asideLayers: {
    L1: BuiAsideRef<any> | null
    L2: BuiAsideRef<any> | null
    L3: BuiAsideRef<any> | null
  } = {
    L1: null,
    L2: null,
    L3: null,
  }

  private get isAddLayerAllowed(): boolean {
    if (this.asideLayers.L3) {
      console.warn(
        'Unable to open a new aside. Maximum number of aside levels reached.'
      )
      return false
    }
    return true
  }

  private get nextLayer(): _BuiAsideConfigInternal['level'] {
    if (!this.asideLayers.L1) {
      return BuiAsideLevel.L1
    }
    if (!this.asideLayers.L2) {
      return BuiAsideLevel.L2
    }
    if (!this.asideLayers.L3) {
      return BuiAsideLevel.L3
    }
    return BuiAsideLevel.L1
  }

  constructor(
    private buiEventsService: BuiEventsService,
    private overlay: Overlay,
    private injector: Injector,
    @Optional()
    @Inject(BUI_ASIDE_DEFAULT_OPTIONS)
    private defaultOptions: BuiAsideConfig
  ) {}

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: BuiAsideConfig<D>
  ): BuiAsideRef<T, R> {
    if (!this.isAddLayerAllowed) {
      return this.asideLayers.L3
    }

    const configInternal = this.applyConfigDefaults(config)
    const overlayRef = this.createOverlay(configInternal)
    const asideContainer = this.attachAsideContainer(overlayRef, configInternal)
    const asideRef = this.attachAsideContent<T, R>(
      componentOrTemplateRef,
      asideContainer,
      overlayRef,
      configInternal
    )

    this.addLayer(asideRef, configInternal)

    return asideRef
  }

  isAsideComponentAttached<T>(componentRef: ComponentType<T>) {
    return Object.values(this.asideLayers).some(
      (asideRef) => asideRef?.componentInstance instanceof componentRef
    )
  }

  closeAll(): Observable<boolean> {
    const refs = Object.values(this.asideLayers).filter((ref) => ref)
    const afterClosedAll$ = combineLatest(
      refs.map((ref) => ref.afterClosed$)
    ).pipe(
      take(1),
      map(() => true)
    )
    refs.forEach((asideRef) => asideRef.close())

    return afterClosedAll$
  }

  private getRefOrWarn(level: BuiAsideLevel): BuiAsideRef<any> | null {
    if (!this.asideLayers[level]) {
      console.error(
        'Trying to open a BUI Aside before previous aside finished closing'
      )
    }
    return this.asideLayers[level]
  }

  private addLayer(
    asideRef: BuiAsideRef<any>,
    config: _BuiAsideConfigInternal
  ) {
    const { level, size } = config
    this.asideLayers[level] = asideRef

    if (level === BuiAsideLevel.L2) {
      this.getRefOrWarn(BuiAsideLevel.L1)?.sendToBack(size)
    } else if (level === BuiAsideLevel.L3) {
      this.getRefOrWarn(BuiAsideLevel.L2)?.sendToBack(size)
      this.getRefOrWarn(BuiAsideLevel.L1)?.sendToBack(size)
    }

    asideRef.startClosing$.pipe(take(1)).subscribe(() => {
      if (level === BuiAsideLevel.L2) {
        this.getRefOrWarn(BuiAsideLevel.L1)?.bringToFront()
      } else if (level === BuiAsideLevel.L3) {
        const sizeL2 = this.getRefOrWarn(BuiAsideLevel.L2)?.containerInstance
          .config.size
        this.getRefOrWarn(BuiAsideLevel.L2)?.bringToFront()
        this.getRefOrWarn(BuiAsideLevel.L1)?.sendToBack(sizeL2)
      }
    })

    asideRef.afterClosed$.pipe(take(1)).subscribe(() => {
      this.asideLayers[level] = null
    })
  }

  private createOverlay(config: _BuiAsideConfigInternal): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config)
    return this.overlay.create(overlayConfig)
  }

  private getOverlayConfig(config: _BuiAsideConfigInternal): OverlayConfig {
    const _config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: config.hasBackdrop,
    })

    if (config.backdropClass) {
      _config.backdropClass = config.backdropClass
    }
    return _config
  }

  private attachAsideContainer(
    overlay: OverlayRef,
    config: _BuiAsideConfigInternal
  ): _BuiAsideContainerComponent {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: _BuiAsideConfigInternal, useValue: config }],
    })
    const containerPortal = new ComponentPortal(
      _BuiAsideContainerComponent,
      null,
      injector
    )
    const containerRef =
      overlay.attach<_BuiAsideContainerComponent>(containerPortal)

    return containerRef.instance
  }

  private attachAsideContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    asideContainer: _BuiAsideContainerComponent,
    overlayRef: OverlayRef,
    config: _BuiAsideConfigInternal
  ): BuiAsideRef<T, R> {
    const asideRef = new BuiAsideRef<T, R>(
      overlayRef,
      asideContainer,
      this.buiEventsService
    )

    if (config.hasBackdrop && !config.disableBackdropClose) {
      overlayRef.backdropClick().subscribe(() => asideRef.close())
    }

    if (componentOrTemplateRef instanceof TemplateRef) {
      asideContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, {
          $implicit: config.data,
          asideRef,
        } as any)
      )
    } else {
      const injector = this.createInjector(config, asideRef, asideContainer)
      const contentRef = asideContainer.attachComponentPortal<T>(
        new ComponentPortal(componentOrTemplateRef, null, injector)
      )
      asideRef.componentInstance = contentRef.instance
    }

    asideRef.updatePosition()

    return asideRef
  }

  private createInjector<T>(
    config: _BuiAsideConfigInternal,
    asideRef: BuiAsideRef<T>,
    asideContainer: _BuiAsideContainerComponent
  ): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: _BuiAsideContainerComponent, useValue: asideContainer },
        { provide: BUI_ASIDE_DATA, useValue: config.data },
        { provide: BuiAsideRef, useValue: asideRef },
      ],
    })
  }

  private applyConfigDefaults(config: BuiAsideConfig): _BuiAsideConfigInternal {
    const defaultOptions = { ...new BuiAsideConfig(), ...this.defaultOptions }
    const level = this.nextLayer
    return {
      ...defaultOptions,
      ...(config || {}),
      size: config?.size || defaultOptions?.size,
      level,
      backdropClass:
        level === BuiAsideLevel.L1 ? '' : 'bui-aside-transparent-backdrop',
    }
  }
}
