// Based on Angular Material Button Toggle v15

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { SelectionModel } from '@angular/cdk/collections'
import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  InjectionToken,
  isDevMode,
  HostBinding,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { _BuiButtonToggleComponent } from './bui-button-toggle.component'
import { BuiSize } from '../bui-size'
import { BuiButtonToggleChange } from './bui-button-toggle.models'

let uniqueIdCounter = 0

const BASE_CSS_CLASS = 'bui-button-toggle-group'

// Injection token that can be used to reference instances of `BuiButtonToggleGroup`.
// It serves as alternative token to the actual `BuiButtonToggleGroup` class which
// could cause unnecessary retention of the class and its component metadata.
export const BUI_BUTTON_TOGGLE_GROUP =
  new InjectionToken<BuiButtonToggleGroupDirective>('BuiButtonToggleGroup')

@Directive({
  selector: 'bui-button-toggle-group',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuiButtonToggleGroupDirective),
      multi: true,
    },
    {
      provide: BUI_BUTTON_TOGGLE_GROUP,
      useExisting: BuiButtonToggleGroupDirective,
    },
  ],
})
export class BuiButtonToggleGroupDirective
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @HostBinding('class') private get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    if (this.vertical) {
      classes.push(`${BASE_CSS_CLASS}--vertical`)
    }

    return classes
  }

  private _selectionModel: SelectionModel<_BuiButtonToggleComponent>

  // Reference to the raw value that the consumer tried to assign. The real
  // value will exclude any values from this one that don't correspond to a
  // toggle. Useful for the cases where the value is assigned before the toggles
  // have been initialized or at the same that they're being swapped out.
  private _rawValue: any

  // Child button toggle buttons
  @ContentChildren(forwardRef(() => _BuiButtonToggleComponent), {
    // Note that this would technically pick up toggles
    // from nested groups, but that's not a case that we support.
    descendants: true,
  })
  _buttonToggles: QueryList<_BuiButtonToggleComponent>

  @Input() size: BuiSize = 'regular'

  // `name` attribute for the underlying `input` element
  @Input()
  get name(): string {
    return this._name
  }
  set name(value: string) {
    this._name = value
    this._markButtonsForCheck()
  }
  private _name = `bui-button-toggle-group-${uniqueIdCounter++}`

  // Value of the toggle group
  @Input()
  get value(): any {
    const selected = this._selectionModel ? this._selectionModel.selected : []

    if (this.multiple) {
      return selected.map((toggle) => toggle.value)
    }

    return selected[0] ? selected[0].value : undefined
  }
  set value(newValue: any) {
    this._setSelectionByValue(newValue)
    this.valueChange.emit(this.value)
  }

  // Event that emits whenever the value of the group changes.
  // Used to facilitate two-way data binding.
  @Output() readonly valueChange = new EventEmitter()

  // Selected button toggles in the group
  get selected(): _BuiButtonToggleComponent | _BuiButtonToggleComponent[] {
    const selected = this._selectionModel ? this._selectionModel.selected : []
    return this.multiple ? selected : selected[0] || null
  }

  // Whether multiple button toggles can be selected
  @Input()
  get multiple(): boolean {
    return this._multiple
  }
  set multiple(value: BooleanInput) {
    this._multiple = coerceBooleanProperty(value)
    this._markButtonsForCheck()
  }
  private _multiple = false

  // Whether multiple button toggle group is disabled
  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
    this._markButtonsForCheck()
  }
  private _disabled = false

  // Whether multiple button toggle group is disabled
  @Input()
  get vertical(): boolean {
    return this._vertical
  }
  set vertical(value: BooleanInput) {
    this._vertical = coerceBooleanProperty(value)
    this._markButtonsForCheck()
  }
  private _vertical = false

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change = new EventEmitter<BuiButtonToggleChange>()

  constructor(private _changeDetector: ChangeDetectorRef) {}

  // The method to be called in order to update ngModel.
  // `ngModel` binding is not supported in multiple selection mode.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _controlValueAccessorChangeFn: (value: any) => void = () => {}

  // onTouch function registered via registerOnTouch (ControlValueAccessor)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched: () => any = () => {}

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<_BuiButtonToggleComponent>(
      this.multiple,
      undefined,
      false
    )
  }

  ngAfterContentInit(): void {
    this._selectionModel.select(
      ...this._buttonToggles.filter((toggle) => toggle.checked)
    )
  }

  writeValue(value: any): void {
    this.value = value
    this._changeDetector.markForCheck()
  }

  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  // Dispatch change event with current selection and group value
  _emitChangeEvent(toggle: _BuiButtonToggleComponent): void {
    const event = new BuiButtonToggleChange(toggle, this.value)
    this._controlValueAccessorChangeFn(event.value)
    this.change.emit(event)
  }

  /**
   * Syncs a button toggle's selected state with the model value.
   * @param toggle Toggle to be synced.
   * @param select Whether the toggle should be selected.
   * @param isUserInput Whether the change was a result of a user interaction.
   * @param deferEvents Whether to defer emitting the change events.
   */
  _syncButtonToggle(
    toggle: _BuiButtonToggleComponent,
    select: boolean,
    isUserInput = false,
    deferEvents = false
  ): void {
    // Deselect the currently-selected toggle, if we're in single-selection
    // mode and the button being toggled isn't selected at the moment.
    if (!this.multiple && this.selected && !toggle.checked) {
      ;(this.selected as _BuiButtonToggleComponent).checked = false
    }

    if (this._selectionModel) {
      if (select) {
        this._selectionModel.select(toggle)
      } else {
        this._selectionModel.deselect(toggle)
      }
    } else {
      deferEvents = true
    }

    // We need to defer in some cases in order to avoid "changed after checked errors", however
    // the side-effect is that we may end up updating the model value out of sequence in others
    // The `deferEvents` flag allows us to decide whether to do it on a case-by-case basis.
    if (deferEvents) {
      Promise.resolve().then(() => this._updateModelValue(toggle, isUserInput))
    } else {
      this._updateModelValue(toggle, isUserInput)
    }
  }

  _isSelected(toggle: _BuiButtonToggleComponent): boolean {
    return this._selectionModel && this._selectionModel.isSelected(toggle)
  }

  // Determines whether a button toggle should be checked on init
  _isPrechecked(toggle: _BuiButtonToggleComponent): boolean {
    if (typeof this._rawValue === 'undefined') {
      return false
    }

    if (this.multiple && Array.isArray(this._rawValue)) {
      return this._rawValue.some(
        (value) => toggle.value != null && value === toggle.value
      )
    }

    return toggle.value === this._rawValue
  }

  // Updates the selection state of the toggles in the group based on a value
  private _setSelectionByValue(value: any | any[]): void {
    this._rawValue = value

    if (!this._buttonToggles) {
      return
    }

    if (this.multiple && value) {
      if (!Array.isArray(value) && isDevMode()) {
        throw Error('Value must be an array in multiple-selection mode.')
      }

      this._clearSelection()
      value.forEach((currentValue: any) => this._selectValue(currentValue))
    } else {
      this._clearSelection()
      this._selectValue(value)
    }
  }

  // Clears the selected toggles
  private _clearSelection(): void {
    this._selectionModel.clear()
    this._buttonToggles.forEach((toggle) => (toggle.checked = false))
  }

  // Selects a value if there's a toggle that corresponds to it
  private _selectValue(value: any): void {
    const correspondingOption = this._buttonToggles.find((toggle) => {
      return toggle.value != null && toggle.value === value
    })

    if (correspondingOption) {
      correspondingOption.checked = true
      this._selectionModel.select(correspondingOption)
    }
  }

  // Syncs up the group's value with the model and emits the change event
  private _updateModelValue(
    toggle: _BuiButtonToggleComponent,
    isUserInput: boolean
  ): void {
    // Only emit the change event for user input.
    if (isUserInput) {
      this._emitChangeEvent(toggle)
    }

    // Note: we emit this one no matter whether it was a user interaction, because
    // it is used by Angular to sync up the two-way data binding.
    this.valueChange.emit(this.value)
  }

  // Marks all of the child button toggles to be checked
  private _markButtonsForCheck(): void {
    this._buttonToggles?.forEach((toggle) => toggle._markForCheck())
  }
}
