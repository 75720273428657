import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { BuiIconModule } from '../bui-icon'
import { _BuiInputNumberComponent } from './bui-input-number.component'
import { _BuiInputTextComponent } from './bui-input-text.component'
import { _BuiInputTextareaComponent } from './bui-input-textarea.component'
import { _BuiInputAutofocusDirective } from './bui-input-autofocus.directive'
import { NgxMaskDirective } from 'ngx-mask'

@NgModule({
  declarations: [
    _BuiInputNumberComponent,
    _BuiInputTextComponent,
    _BuiInputTextareaComponent,
    _BuiInputAutofocusDirective,
  ],
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective, BuiIconModule],
  exports: [
    _BuiInputNumberComponent,
    _BuiInputTextComponent,
    _BuiInputTextareaComponent,
    _BuiInputAutofocusDirective,
  ],
})
export class BuiInputModule {}
