<div
  class="bui-sticky-table__inner"
  [class.bui-sticky-table__inner--shadow-first-col]="
    isFirstColSticky ? (showFirstColShadow$ | async) : null
  "
  [class.bui-sticky-table__inner--shadow-last-col]="
    isLastColSticky || areLastTwoColsSticky
      ? (showLastColShadow$ | async)
      : null
  "
  (cdkObserveContent)="onContentChanged()"
>
  <ng-content></ng-content>
</div>
